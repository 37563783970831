import { constructNow, differenceInDays, differenceInHours, parseISO } from 'date-fns';

import { selectPromoFreeItemCount } from '../cart/selectors';
import ooeConstants from '../constants';
import { selectLeadTimeWarning } from '../eventDetails/selectors';
import {
  selectDeliveryLeadTimeStoreInfo,
  selectInStateDeliveryOnly,
  selectMaxDeliveryAmount,
  selectMaxPickupAmount,
  selectMaxSameDayOrderTotalAmountCents,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
  selectPickupLeadTimeStoreInfo,
} from '../floatingMenu/selectors';
import { selectSubtotalSubmitWarning } from '../payment/selectors';
import {
  selectDateIsToday,
  selectDeliveryMinNotMet,
  selectExceedsMaxOrderTotal,
  selectExceedsSameDayMaxOrderTotal,
  selectOrderForAPI,
  selectPickupMinNotMet,
  selectPromoFreeItemsValue,
} from '../reducers';
import { State as FormState, selectNewPaymentMethod, selectPaymentForm } from '../reducers/form';
import {
  State as GuestState,
  selectCfaOneGuestId,
  selectFavoriteOrders,
  selectGuest,
  selectIsCfaOneGuestSelected,
  selectPastOrdersLength,
  selectVaultedCards,
} from '../reducers/guest';
import { State as MenuState } from '../reducers/menu';
import {
  State as OrderState,
  selectDeliveryAddressValid,
  selectDeliveryRangeWarning,
  selectDeliveryTipAmount,
  selectDestination,
  selectDestinationValid,
  selectEditMode,
  selectEditOrderId,
  selectReorder,
  selectSelectedPercentageTipValue,
  selectSubTotalAmount,
  selectTaxAmount,
} from '../reducers/order';
import {
  doesLocationNumberExist,
  isAdminUser,
  isDeveloperAudience,
  isVcaUser,
  selectBypassBusinessRules,
  selectCmtPerm,
  selectCmtPerms,
  selectDeliveryRange,
  selectIsUserLoggedIn,
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationNumber,
  selectLocationTimezone,
  selectOrderMaxFutureDayCountDelivery,
  selectOrderMaxFutureDayCountPickup,
  selectUser,
  selectUserCfaGuid,
  selectUserEmail,
  selectUserHasAgreedToEula,
  selectUserName,
  State as UserState,
} from '../reducers/user';
import ReduxStore from '../util/reduxStore';
import { roundNumber } from '../util/utils';
import { logAmplitudeEvent, setAmplitudeUser } from './amplitude';
import { notifyBugsnag } from './bugsnag';

export const createSubmitOrderAnalytics = (state: {
  cart: CartState;
  form: FormState;
  guest: GuestState;
  menu: MenuState;
  order: OrderState;
  user: UserState;
}) => {
  let analytics: Partial<{
    deliveryMinNotMet: ReturnType<typeof selectDeliveryMinNotMet>;
    pickupMinNotMet: ReturnType<typeof selectPickupMinNotMet>;
    exceedsMaxOrderTotal: ReturnType<typeof selectExceedsMaxOrderTotal>;
    exceedsSameDayMaxOrderTotal: ReturnType<typeof selectExceedsSameDayMaxOrderTotal>;
    subtotalSubmitWarning: ReturnType<typeof selectSubtotalSubmitWarning>;
    leadTimeWarning: ReturnType<typeof selectLeadTimeWarning>;
    requestingNewPaymentMethod: ReturnType<typeof selectNewPaymentMethod>;
    isCfaOneGuestSelected: ReturnType<typeof selectIsCfaOneGuestSelected>;
    isEditingOrder: ReturnType<typeof selectEditMode>;
    editOrderId: ReturnType<typeof selectEditOrderId>;
    orderId: ReturnType<typeof selectOrderForAPI>['id'];
    companyName: ReturnType<typeof selectOrderForAPI>['companyName'];
    destination: ReturnType<typeof selectDestination>;
    deliveryAddressValid: ReturnType<typeof selectDeliveryAddressValid>;
    destinationValid: ReturnType<typeof selectDestinationValid>;
    taxAmount: ReturnType<typeof selectTaxAmount>;
    subTotalAmount: ReturnType<typeof selectSubTotalAmount>;
    totalAmount: ReturnType<typeof selectOrderForAPI>['totalAmount'];
    deliveryRangeWarning: ReturnType<typeof selectDeliveryRangeWarning>;
    reorder: ReturnType<typeof selectReorder>;
    promiseDateTime: ReturnType<typeof selectOrderForAPI>['promiseDateTime'];
    hoursUntilPromisedDateTime: number;
    daysUntilPromisedDateTime: number;
    paperGoods: ReturnType<typeof selectOrderForAPI>['paperGoods'];
    specialInstructions: ReturnType<typeof selectOrderForAPI>['specialInstructions'];
    guestCount: ReturnType<typeof selectOrderForAPI>['guestCount'];
    businessDate: ReturnType<typeof selectOrderForAPI>['businessDate'];
    orderLocationNumber: ReturnType<typeof selectOrderForAPI>['locationNumber'];
    bypassBusinessRulesList: ReturnType<typeof selectOrderForAPI>['bypassBusinessRulesList'];
    orderType: ReturnType<typeof selectOrderForAPI>['orderType'];
    deliveryTipAmount: ReturnType<typeof selectDeliveryTipAmount>;
    deliveryTipPercentOfSale: ReturnType<typeof selectSelectedPercentageTipValue>;
    promoFreeItemCount: ReturnType<typeof selectPromoFreeItemCount>;
    promoFreeItemsValue: ReturnType<typeof selectPromoFreeItemsValue>;
    locationNumber: ReturnType<typeof selectLocationNumber>;
    bypassBusinessRules: ReturnType<typeof selectBypassBusinessRules>;
    cmtPerm: ReturnType<typeof selectCmtPerm>;
    perms: ReturnType<typeof selectCmtPerms>;
    userCfaGuid: ReturnType<typeof selectUserCfaGuid>;
    audience: ReturnType<typeof selectUser>['cfa_aud'];
    isAdmin: ReturnType<typeof isAdminUser>;
    isVca: ReturnType<typeof isVcaUser>;
    isDeveloper: ReturnType<typeof isDeveloperAudience>;
    isUserLoggedIn: ReturnType<typeof selectIsUserLoggedIn>;
    locationTimezone: ReturnType<typeof selectLocationTimezone>;
    userHasAgreedToEula: ReturnType<typeof selectUserHasAgreedToEula>;
    locationNumberExists: ReturnType<typeof doesLocationNumberExist>;
    locationAcceptsDelivery: ReturnType<typeof selectLocationAcceptsDelivery>;
    locationAcceptsPickup: ReturnType<typeof selectLocationAcceptsPickup>;
    minDeliveryAmount: ReturnType<typeof selectMinDeliveryAmount>;
    minPickupAmount: ReturnType<typeof selectMinPickupAmount>;
    maxDeliveryAmount: ReturnType<typeof selectMaxDeliveryAmount>;
    maxPickupAmount: ReturnType<typeof selectMaxPickupAmount>;
    maxSameDayOrderTotalAmountCents: ReturnType<typeof selectMaxSameDayOrderTotalAmountCents>;
    orderMaxFutureDayCountDelivery: ReturnType<typeof selectOrderMaxFutureDayCountDelivery>;
    orderMaxFutureDayCountPickup: ReturnType<typeof selectOrderMaxFutureDayCountPickup>;
    deliveryRange: ReturnType<typeof selectDeliveryRange>;
    deliveryLeadTimeMinutes: ReturnType<typeof selectDeliveryLeadTimeStoreInfo>;
    pickupLeadTimeMinutes: ReturnType<typeof selectPickupLeadTimeStoreInfo>;
    inStateDeliveryOnly: ReturnType<typeof selectInStateDeliveryOnly>;
    orderedSameDay: ReturnType<typeof selectDateIsToday>;
    paymentMethod: string;
    favoriteOrdersCount: number;
    pastOrdersCount: ReturnType<typeof selectPastOrdersLength>;
    cfaOneGuestId: ReturnType<typeof selectCfaOneGuestId>;
    vaultedCardsCount: number;
    guestSessionActive: boolean;
    masqueradeSessionActive: boolean;
    error: string;
  }> = {};

  try {
    const order = selectOrderForAPI(state);
    const user = selectUser(state);
    const paymentForm = selectPaymentForm(state);
    const guest = selectGuest(state);

    const now = constructNow(new Date());
    const orderDate = parseISO(order.promiseDateTime || '');

    analytics = {
      // top level selectors
      deliveryMinNotMet: selectDeliveryMinNotMet(state),
      pickupMinNotMet: selectPickupMinNotMet(state),
      exceedsMaxOrderTotal: selectExceedsMaxOrderTotal(state),
      exceedsSameDayMaxOrderTotal: selectExceedsSameDayMaxOrderTotal(state),
      subtotalSubmitWarning: selectSubtotalSubmitWarning(state),
      leadTimeWarning: selectLeadTimeWarning(state),

      // order
      requestingNewPaymentMethod: selectNewPaymentMethod(state),
      isCfaOneGuestSelected: selectIsCfaOneGuestSelected(state),
      isEditingOrder: selectEditMode(state),
      editOrderId: selectEditOrderId(state),
      orderId: order.id,
      companyName: order.companyName,
      destination: selectDestination(state),
      deliveryAddressValid: selectDeliveryAddressValid(state),
      destinationValid: selectDestinationValid(state),
      taxAmount: selectTaxAmount(state),
      subTotalAmount: selectSubTotalAmount(state),
      totalAmount: roundNumber(
        selectTaxAmount(state) + selectSubTotalAmount(state) + selectDeliveryTipAmount(state),
      ),
      deliveryRangeWarning: selectDeliveryRangeWarning(state),
      reorder: selectReorder(state),
      promiseDateTime: order.promiseDateTime,
      hoursUntilPromisedDateTime: roundNumber(differenceInHours(orderDate, now)),
      daysUntilPromisedDateTime: roundNumber(differenceInDays(orderDate, now)),
      paperGoods: order.paperGoods,
      specialInstructions: order.specialInstructions,
      guestCount: order.guestCount,
      businessDate: order.businessDate,
      orderLocationNumber: order.locationNumber,
      bypassBusinessRulesList: order.bypassBusinessRulesList,
      orderType: order.orderType,
      deliveryTipAmount: selectDeliveryTipAmount(state),
      deliveryTipPercentOfSale: selectSelectedPercentageTipValue(state),

      //cart
      promoFreeItemCount: selectPromoFreeItemCount(state),
      promoFreeItemsValue: selectPromoFreeItemsValue(state),

      // user
      locationNumber: selectLocationNumber(state),
      bypassBusinessRules: selectBypassBusinessRules(state),
      cmtPerm: selectCmtPerm(state),
      perms: selectCmtPerms(state),
      userCfaGuid: selectUserCfaGuid(state),
      audience: user.cfa_aud,
      isAdmin: isAdminUser(state),
      isVca: isVcaUser(state),
      isDeveloper: isDeveloperAudience(user.cfa_aud),
      isUserLoggedIn: selectIsUserLoggedIn(state),
      locationTimezone: selectLocationTimezone(state),
      userHasAgreedToEula: selectUserHasAgreedToEula(state),
      locationNumberExists: doesLocationNumberExist(state, order.locationNumber),
      locationAcceptsDelivery: selectLocationAcceptsDelivery(state),
      locationAcceptsPickup: selectLocationAcceptsPickup(state),
      minDeliveryAmount: selectMinDeliveryAmount(state) || 0 / 100,
      minPickupAmount: selectMinPickupAmount(state) || 0 / 100,
      maxDeliveryAmount: selectMaxDeliveryAmount(state) || 0 / 100,
      maxPickupAmount: selectMaxPickupAmount(state) || 0 / 100,
      maxSameDayOrderTotalAmountCents: selectMaxSameDayOrderTotalAmountCents(state) || 0 / 100,
      orderMaxFutureDayCountDelivery: selectOrderMaxFutureDayCountDelivery(state),
      orderMaxFutureDayCountPickup: selectOrderMaxFutureDayCountPickup(state),
      deliveryRange: selectDeliveryRange(state),
      deliveryLeadTimeMinutes: selectDeliveryLeadTimeStoreInfo(state),
      pickupLeadTimeMinutes: selectPickupLeadTimeStoreInfo(state),
      inStateDeliveryOnly: selectInStateDeliveryOnly(state),

      //form
      orderedSameDay: selectDateIsToday(state),
      paymentMethod: paymentForm?.values?.selectedMethod,

      //guest
      favoriteOrdersCount: selectFavoriteOrders(state)?.length,
      pastOrdersCount: selectPastOrdersLength(state),
      cfaOneGuestId: selectCfaOneGuestId(state),
      vaultedCardsCount: selectVaultedCards(state)?.length || 0,
      guestSessionActive: !!guest.guestSession?.accessToken,
      masqueradeSessionActive: !!guest.masqueradeSession?.accessToken,
      error: undefined,
    };

    return analytics;
  } catch (error) {
    notifyBugsnag('Create Submit Order Analytics Failed', {
      context: 'Order Analytics',
      info: error,
    });
    analytics = {
      error: `Unable to create order analytics -  ${error}`,
    };
  }

  return analytics;
};

const getUserInfo = () => {
  const { state } = ReduxStore;
  return {
    isVca: isVcaUser(state) ?? false,
    isAdmin: isAdminUser(state) ?? false,
    userCfaGuid: selectUserCfaGuid(state) ?? '',
    cmtPerms: selectCmtPerm(state) ?? '',
    name: selectUserName(state) ?? '',
    email: selectUserEmail(state) ?? '',
    bypassBusinessRules: selectBypassBusinessRules(state) ?? '',
    locationNumber: selectLocationNumber(state) ?? '',
    appVersion: ooeConstants.APP_VERSION,
  };
};

export function logEvent(event: string, payload?: object) {
  const userInfo = getUserInfo();
  logAmplitudeEvent(event, { ...payload, ...userInfo });
}

export function simplifyPage(page = '') {
  if (page?.startsWith('more/')) {
    return page.substring(4);
  }
  return page;
}
export function logPageView(page: string) {
  const userInfo = getUserInfo();
  const simplifiedPage = simplifyPage(page);
  logAmplitudeEvent(`[View Page] ${simplifiedPage}`, {
    page: simplifiedPage,
    ...userInfo,
  });
}

export function setUserLocation(locationNumber: string) {
  const userInfo = getUserInfo();
  logAmplitudeEvent('[Set User Location]', { ...userInfo, locationNumber });
}

export function setUser(cfaGuid: string) {
  const userInfo = getUserInfo();
  setAmplitudeUser(cfaGuid, userInfo);
}
