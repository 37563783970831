import { ToggleButton, ToggleButtonGroup } from '@cfa/react-components';
import { Flex } from '@cfacorp/cowponents';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import {
  actions as orderActions,
  selectEditMode,
  selectIsEditCMTOrder,
  selectPaperGoodsBool,
} from '../../../reducers/order';
import PaperGoodsBadges from '../PaperGoodsBadges';
import PaperGoodsOptions from '../PaperGoodsOptions';

const PaperGoods: React.FC = () => {
  const dispatch = useDispatch();
  const paperGoodsForOrder = useSelector(selectPaperGoodsBool);
  const isEditMode = useSelector(selectEditMode);
  const isCMTOrder = useSelector(selectIsEditCMTOrder);

  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const toggleHandler: (event: React.MouseEvent<HTMLButtonElement>, value: 'yes' | 'no') => void = (
    _,
    value,
  ) => {
    if (value === 'no') {
      dispatch(orderActions.resetPaperGoodsOptions());
      setOptionsExpanded(false);
    } else {
      setOptionsExpanded(true);
    }
  };
  const collapseHandler = () => !isEditMode && setOptionsExpanded((prev) => !prev);
  const toggleButtons =
    isEditMode && isCMTOrder ? (
      <Tooltip
        className="tooltip-box"
        tooltipContent={
          <div>
            <span className="title">Please Edit Paper Goods in Special Instructions Field</span>
          </div>
        }
      >
        <ToggleButtonGroup size="sm">
          <StyledToggleButton disabled key="no" selected={!paperGoodsForOrder} value="no">
            No
          </StyledToggleButton>
          <StyledToggleButton disabled key="yes" selected={paperGoodsForOrder} value="yes">
            Yes
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    ) : (
      <ToggleButtonGroup onChange={toggleHandler} size="sm">
        <StyledToggleButton data-cy="no-paper-goods" key="no" selected={!paperGoodsForOrder} value="no">
          No
        </StyledToggleButton>
        <StyledToggleButton data-cy="yes-paper-goods" key="yes" selected={paperGoodsForOrder} value="yes">
          Yes
        </StyledToggleButton>
      </ToggleButtonGroup>
    );

  return (
    <StyledPaperGoods>
      <Flex alignItems="center" className="paper-goods-navigation">
        <div className="button-box">
          {toggleButtons}
          <Arrow onClick={collapseHandler} rotateArrow={optionsExpanded}>
            <Icon className="arrow">down</Icon>
          </Arrow>
        </div>
        {!optionsExpanded && <PaperGoodsBadges />}
      </Flex>
      {optionsExpanded && <PaperGoodsOptions />}
    </StyledPaperGoods>
  );
};

const StyledToggleButton = styled(ToggleButton)`
  width: 80px;
`;

const StyledPaperGoods = styled.div`
  & .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 40px;

    @media (width <= 600px) {
      margin-right: 0;
    }
  }

  @media (width <= 600px) {
    & .paper-goods-navigation {
      flex-direction: column;
    }
  }
`;

const Arrow = styled.span<{ rotateArrow: boolean }>`
  max-width: 20px;
  max-height: 20px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  cursor: pointer;

  & svg {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    fill: ${(props) => props.theme.colors.secondary};
    transform: ${(props) => props.rotateArrow && 'rotateX(180deg)'};
  }
`;

export default PaperGoods;
