import { Epic, ofType } from 'redux-observable';
import { fromEvent } from 'rxjs';
import { debounceTime, mapTo } from 'rxjs/operators';

import ooeConstants from '../constants';
import { actions as deviceActions, types as deviceTypes } from '../reducers/device';

export const ListenForOnlineStatus = () =>
  fromEvent(window, 'online').pipe(mapTo(deviceActions.deviceOnline()));

export const TurnOffOnlineMessage: Epic<
  | ReturnType<(typeof deviceActions)['deviceOnline']>
  | ReturnType<(typeof deviceActions)['deviceOffline']>
  | ReturnType<(typeof deviceActions)['hideMessage']>,
  | ReturnType<(typeof deviceActions)['deviceOnline']>
  | ReturnType<(typeof deviceActions)['deviceOffline']>
  | ReturnType<(typeof deviceActions)['hideMessage']>
> = ($actions) =>
  $actions.pipe(
    ofType(deviceTypes.DEVICE_ONLINE, deviceTypes.DEVICE_OFFLINE),
    debounceTime(ooeConstants.ONLINE_MESSAGE_TIMEOUT),
    ofType(deviceTypes.DEVICE_ONLINE),
    mapTo(deviceActions.hideMessage()),
  );

export const ListenForOfflineStatus = () =>
  fromEvent(window, 'offline').pipe(mapTo(deviceActions.deviceOffline()));

export default [ListenForOnlineStatus, TurnOffOnlineMessage, ListenForOfflineStatus];
