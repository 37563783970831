import { Box, Flex } from '@cfacorp/cowponents';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { isEmpty } from 'ramda';
import ooeConstants from 'src/constants';
import { selectEventSummaryValid, selectSpecialInstructionMessage } from 'src/payment/selectors';
import {
  formatPhoneNumber,
  selectCateringOccasion,
  selectDetailsFormValues,
  selectGuestValues,
  selectSecondaryContactValues,
} from 'src/reducers/form';
import {
  selectDeliveryAddressForOrder,
  selectDeliveryAddressValid,
  selectDestination,
  selectPaperGoodsRequired,
  selectShowDefaultPaperGoodsMessage,
} from 'src/reducers/order';
import { selectLocationContactDetails } from 'src/reducers/user';
import {
  formatEventSummaryDate,
  formatEventSummaryTime,
  formatPaper,
  validateEmail,
  validatePhone,
} from 'src/util/format';

interface Props {
  isInvoicePage?: boolean;
}

const EventSummary: React.FC<Props> = ({ isInvoicePage = false }) => {
  const addressValidated = useSelector(selectDeliveryAddressValid);
  const cateringReason = useSelector(selectCateringOccasion);
  const { companyName, addressLine1, addressLine2, city, state, zip } = useSelector(
    selectDeliveryAddressForOrder,
  );
  const destination = useSelector(selectDestination);
  const { time, guestCount, specialInstructions, date } = useSelector(selectDetailsFormValues);
  const eventSummaryValid = useSelector(selectEventSummaryValid);
  const { first = '', last = '', email = '', phone = '' } = useSelector(selectGuestValues);
  const paperGoodsRequired = useSelector(selectPaperGoodsRequired);
  const { restaurantName, restaurantAddress1, restaurantAddress2, restaurantPhone } = useSelector(
    selectLocationContactDetails,
  );
  const secondaryContactDetails = useSelector(selectSecondaryContactValues);
  const showDefaultPaperGoodsMessage = useSelector(selectShowDefaultPaperGoodsMessage);
  const specialInstructionMessage = useSelector(selectSpecialInstructionMessage);

  const { firstName, lastName, phoneNumber = '' } = secondaryContactDetails;
  const { DELIVERY, PICKUP } = ooeConstants;

  const specialInstructionBox = () => {
    if (showDefaultPaperGoodsMessage) {
      return (
        <Box className="specialInstructionsSummary">
          {formatPaper(false)}
          {specialInstructions}
        </Box>
      );
    } else {
      return (
        <Box className="specialInstructionsSummary">
          {specialInstructionMessage}
          {paperGoodsRequired && <div className="required">PaperGoods*</div>}
        </Box>
      );
    }
  };

  return (
    <StyledEventSummary>
      <Flex lineHeight="27px">
        <Flex className="f-d-column" flexDirection="column" width={[1 / 2, 5 / 12]}>
          <Box fontWeight="bold" lineHeight="27px">
            {destination} Time
          </Box>
          <Box data-cy="eventDate">
            {date ? formatEventSummaryDate(date) : <span className="required">Date*</span>}
          </Box>
          <Box data-cy="eventTime">
            {time ? (
              formatEventSummaryTime(time, destination === DELIVERY)
            ) : (
              <span className="required">Time*</span>
            )}
          </Box>
          <br />
          {destination === DELIVERY && (
            <div>
              <Box fontWeight="bold" lineHeight="27px">
                Delivery Address
              </Box>
              <Box data-cy="guestAddressName">{companyName}</Box>
              <Box data-cy="guestAddressStreet">
                {addressLine1 ? addressLine1 : <span className="required">Street Address*</span>}
              </Box>
              <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
              <Box data-cy="guestAddressCityState">
                {city ? `${city},` : <span className="required">City*</span>}{' '}
                {state ? state : <span className="required">State*</span>}{' '}
                {zip ? zip : <span className="required">Zip*</span>}
              </Box>
              {!addressValidated && <Box className="required">Delivery Address Invalid*</Box>}
            </div>
          )}
          {(destination === PICKUP || isInvoicePage) && (
            <div className="retaurant-details">
              <Box fontWeight="bold" lineHeight="27px">
                {!isInvoicePage ? 'Pickup Restaurant' : 'Restaurant Details'}
              </Box>
              <Box data-cy="restaurantName">{restaurantName}</Box>
              <Box data-cy="restaurantAddress">{restaurantAddress1}</Box>
              <Box data-cy="restaurantCityStateZip">{restaurantAddress2}</Box>
              <Box data-cy="restaurantPhone">{restaurantPhone}</Box>
            </div>
          )}
          {!isEmpty(secondaryContactDetails) && (
            <div className="secondary-contact-details">
              <br />
              <Box fontWeight="bold" lineHeight="27px">{`Secondary ${destination} POC:`}</Box>
              <Box className="first-and-last-name" lineHeight="27px">
                {firstName} {lastName}
              </Box>
              <Box className="guestPhone secondary-contact-phone">{formatPhoneNumber(phoneNumber)}</Box>
            </div>
          )}
        </Flex>
        <Flex
          className="f-d-column"
          flexDirection="column"
          justifyContent={['space-between', 'initial']}
          width={[1 / 2, 7 / 12]}
        >
          <Box fontWeight="bold" lineHeight="27px">
            Primary Contact
          </Box>
          <Box data-cy="guestSummaryFullName" lineHeight="27px">
            {first ? first : <span className="required">First Name*</span>}{' '}
            {last ? last : <span className="required">Last Name*</span>}
          </Box>
          <Box className="email" data-cy="guestSummaryEmail">
            {validateEmail(email) ? email : <span className="required">Email*</span>}
          </Box>
          <Box className="guestPhone" data-cy="guestSummaryPhone">
            {validatePhone(phone) ? (
              formatPhoneNumber(phone)
            ) : (
              <span className="required">Phone Number*</span>
            )}
          </Box>
          <br />
          <Box data-cy="guestCountSummary" lineHeight="27px">
            <span className="bold">Guest count: </span>
            {guestCount}
          </Box>
          <Box fontWeight="bold" lineHeight="27px">
            Special Instructions:
          </Box>
          {specialInstructionBox()}
          <br />
          {cateringReason && cateringReason.length > 0 && (
            <div className="catering-reason">
              <Box fontWeight="bold" lineHeight="27px">
                Catering Occasion / Reason:
              </Box>
              <Box>{cateringReason}</Box>
            </div>
          )}
        </Flex>
      </Flex>
      {!eventSummaryValid && <Box className="required required-field">*Required Field</Box>}
    </StyledEventSummary>
  );
};

const StyledEventSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .bold {
    font: ${(props) => props.theme.regularBoldFont};
  }

  & .required {
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.colors.error};
    line-height: 27px;
  }

  & .required-field {
    text-align: left;
    margin-top: 25px;
  }
`;

export default EventSummary;
