import { LoadingIndicator } from '@cfa/react-components';
import { contains } from 'ramda';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AreYouSure from 'src/components/AreYouSure';
import OrderExportExcel from 'src/components/ExportExcel/OrderExportExcel';
import Icon from 'src/components/Icon';
import { selectTooLateToCancelOrEdit } from 'src/reducers';
import { selectLocationContactDetails } from 'src/reducers/user';
import Order from 'src/types/order';
import { featureFlagVariation } from '../../../apis';
import constants from '../../../constants';
import { actions as dashboardActions } from '../../../reducers/dashboard';
import { actions as orderActions } from '../../../reducers/order';
import { selectIsErrorStatus, selectOrderToView, selectPaymentEmailResent } from '../../selectors';
import DashboardActionButton from './DashboardActionButton';

const showEditOrderButton = () => featureFlagVariation('edit-order');
const showResendEmailButton = () => featureFlagVariation('resend-payment-email');

type ConfirmType = {
  message: string;
  confirm: () => void;
  confirmText: string;
  decline: () => void;
  declineText: string;
};

interface DashboardActionsComponentProps {
  guestDetails: GuestDetails;
  orderDetails: ReturnType<typeof selectOrderToView>;
  restaurantContactDetails: ReturnType<typeof selectLocationContactDetails>;
  isPOSOrder: boolean;
  dashboardActionLoading: boolean;
}

export const DashboardActionsComponent: React.FC<DashboardActionsComponentProps> = ({
  guestDetails,
  orderDetails,
  restaurantContactDetails,
  isPOSOrder,
  dashboardActionLoading,
}) => {
  const dispatch = useDispatch();

  const tooLateToCancelOrEdit = useSelector(selectTooLateToCancelOrEdit);
  const isErrorStatus = useSelector(selectIsErrorStatus);
  const paymentEmailResent = useSelector(selectPaymentEmailResent);

  const { id, cfaId, email, status } = orderDetails;

  const [confirmToShow, setConfirmToShow] = useState<ConfirmType | null>(null);

  const cancelled = contains(status, constants.CANCELLED);
  const showResendEmail = status === constants.PAYMENT_PENDING;
  const disable = tooLateToCancelOrEdit || isPOSOrder || isErrorStatus;

  const handleCancelOrder = () => {
    dispatch(dashboardActions.cancelOrder(id, cfaId));
    setConfirmToShow(null);
  };

  const handleResendPaymentEmail = () => {
    dispatch(dashboardActions.resendPaymentEmail(id, cfaId));
    setConfirmToShow(null);
  };

  const handleEditOrder = () => {
    dispatch(orderActions.initiateEditOrder(orderDetails as Order, guestDetails));
    setConfirmToShow(null);
  };

  const declineConfirm = () => {
    setConfirmToShow(null);
  };

  const confirmTypes: Record<string, ConfirmType> = {
    cancelConfirm: {
      message: constants.GET_DASHBOARD_CANCEL_MESSAGES.message,
      confirm: handleCancelOrder,
      confirmText: constants.GET_DASHBOARD_CANCEL_MESSAGES.confirmText,
      decline: declineConfirm,
      declineText: constants.GET_DASHBOARD_CANCEL_MESSAGES.declineText,
    },
    resendEmailConfirm: {
      message: `${constants.GET_DASHBOARD_RESEND_MESSAGES.message} ${email}?`,
      confirm: handleResendPaymentEmail,
      confirmText: constants.GET_DASHBOARD_RESEND_MESSAGES.confirmText,
      decline: declineConfirm,
      declineText: constants.GET_DASHBOARD_RESEND_MESSAGES.declineText,
    },
  };

  const toggleConfirmMessage = (type: keyof typeof confirmTypes) => {
    setConfirmToShow(confirmTypes[type]);
  };

  if (cancelled) {
    return (
      <DashboardActions>
        <div className="action-message" data-testid="action-message">
          <Icon>error</Icon>
          {constants.GET_DASHBOARD_CANCEL_MESSAGES.cancelled}
        </div>
      </DashboardActions>
    );
  }
  if (paymentEmailResent) {
    return (
      <DashboardActions>
        <div className="action-message email" data-testid="action-message">
          <Icon>success</Icon>
          {`${constants.GET_DASHBOARD_RESEND_MESSAGES.emailSent} ${email}`}
        </div>
      </DashboardActions>
    );
  }
  if (dashboardActionLoading) {
    return (
      <DashboardActions>
        <LoadingIndicator className="loading" data-testid="spinner" size="md" variant="inline" />
      </DashboardActions>
    );
  }
  return (
    <DashboardActions>
      {confirmToShow ? (
        <AreYouSure
          confirm={confirmToShow.confirm}
          confirmText={confirmToShow.confirmText}
          data-testid="are-you-sure"
          decline={confirmToShow.decline}
          declineText={confirmToShow.declineText}
          message={confirmToShow.message}
        />
      ) : (
        <>
          {showEditOrderButton() && (
            <DashboardActionButton
              buttonText="Edit Order"
              disabled={disable}
              icon="notepad"
              onClick={handleEditOrder}
            />
          )}
          {showResendEmailButton() && showResendEmail && (
            <DashboardActionButton
              buttonText="Resend Payment Email"
              data-cy="resend-button"
              disabled={disable}
              icon="email"
              onClick={() => toggleConfirmMessage('resendEmailConfirm')}
            />
          )}
          <DashboardActionButton
            buttonText="Cancel Order"
            disabled={disable}
            icon="delete"
            onClick={() => toggleConfirmMessage('cancelConfirm')}
          />
          <OrderExportExcel orderDetails={orderDetails} restaurantContactDetails={restaurantContactDetails} />
        </>
      )}
    </DashboardActions>
  );
};

const DashboardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background};
  padding-bottom: 20px;

  & .action-message {
    height: 98px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  & .action-message svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }

  & .loading {
    width: 134px;
    height: 98px;
    align-self: center;
  }

  & .loading img {
    margin: 28px;
  }

  @media (width <= 600px) {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
`;

export default memo(DashboardActionsComponent);
