import { Box, Text, Toggle as CowponentsToggle } from '@cfacorp/cowponents';
import React from 'react';

interface Props {
  id: string;
  isToggledPromoFree?: boolean;
  togglePromoFreeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PromoFreeToggle: React.FC<Props> = ({ id, isToggledPromoFree = false, togglePromoFreeHandler }) => (
  <Box className="promo-free-box">
    <Text mr="8px">Promo Free</Text>
    <CowponentsToggle
      checked={isToggledPromoFree}
      data-testid="toggle"
      id={id}
      mr="10px"
      onChange={togglePromoFreeHandler}
    />
  </Box>
);

export default PromoFreeToggle;
