import { createSelector } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';
import ooeConstants from '../constants';
import {
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  selectLocationDetails,
  selectLocationNumber,
} from '../reducers/user';
import { pullOutHours } from './utils';

export const selectStoreInfoSpecialMessage = createSelector(selectLocationNumber, (locationNumber) => {
  const messages = ooeConstants.SPECIAL_LOCATION_STORE_INFO_MESSAGES;
  return messages[locationNumber as keyof typeof messages] || '';
});

export const selectAllDayChickenMinis = createSelector(selectLocationNumber, (locationNumber) => {
  let message = '';
  if (ooeConstants.ALL_DAY_CHICKEN_MINI_LOCATIONS.includes(locationNumber)) {
    message = 'All day Chicken Minis';
  } else {
    switch (locationNumber) {
      case '02322':
        message = 'Only the Tray Minis available all day';
        break;
      case '02013':
        message = 'All day Chicken Minis for 20ct and 40ct';
        break;
      case '03489':
        message = 'All day Chicken Minis for catering trays only';
        break;
      case '03003':
        message = 'All day Chicken Minis in tray sizes only (no 4 count all day)';
        break;
      case '00316':
        message = 'Can call the store ahead of time to check for Chicken Minis.';
        break;
      case '02926':
        message = 'Can we do only Large Mini Trays after 10:00AM.';
        break;
      case '03022':
        message = 'All day Chicken Minis if ordered in advance (by 10:30 am).';
        break;
      case '01546':
        message = 'All day Chicken Minis only for Small and Large Mini Trays';
        break;
      case '01157':
        message = 'Mini Trays are allowed with minimum of 1 hour advanced notice';
        break;
      case '03606':
        message = 'Chicken Mini Trays are available all day';
        break;
      case '03491':
        message =
          'All day Chicken Minis, but needs a phone call to the store informing the staff and needs at least 2 hours notice before ordering';
        break;
      case '00618':
        message = 'All day Chicken Minis, but call restaurant first to provide a heads up';
        break;
      default:
        message = '';
    }
  }
  return message;
});

export const selectCateringDeliveryHoursOfOperation = createSelector(selectLocationDetails, (details) => {
  const cateringDelivery = pathOr(
    {},
    ['mobileOrderingSettings', 'fulfillmentMethods', 'cateringDelivery'],
    details,
  );
  if (cateringDelivery['hoursOfOperation']) {
    const { hoursOfOperation } = cateringDelivery;
    return pullOutHours(hoursOfOperation);
  }
  return [];
});

export const selectDeliveryLeadTimeStoreInfo = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.leadTimeMinutes,
);

export const selectInStateDeliveryOnly = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.inStateCateringDeliveryOnly,
);

export const selectMaxDeliveryAmount = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.orderMaxAmountCents,
);

export const selectMaxPickupAmount = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.orderMaxAmountCents,
);

export const selectMaxSameDayOrderTotalAmountCents = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.maxSameDayOrderTotalAmountCents,
);

export const selectMinDeliveryAmount = createSelector(
  selectCateringDeliveryMOSettings,
  (settings) => settings?.orderMinAmountCents,
);

export const selectMinPickupAmount = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.orderMinAmountCents,
);

export const selectPickupLeadTimeStoreInfo = createSelector(
  selectCateringPickupMOSettings,
  (settings) => settings?.leadTimeMinutes,
);

export const selectRestaurantHoursOfOperation = createSelector(selectLocationDetails, (details) => {
  const restaurantHours = pathOr({}, ['restaurantHours'], details);
  if (restaurantHours['hoursOfOperation']) {
    const { hoursOfOperation } = restaurantHours;
    return pullOutHours(hoursOfOperation);
  }
  return [];
});
