import styled from 'styled-components';

import Icon from '../../../components/Icon';
import { MenuItem } from '../../../types/menu';
import { formatItemName, formatPrice } from '../../../util/format';

interface Props {
  item: MenuItem;
}

const MenuItemPrice: React.FC<Props> = ({ item }) => {
  const { variation, itemPrice } = item;
  const servingText = variation?.match(/[Ss]erves (\d+)/);
  const servingTextToDisplay = Array.isArray(servingText) && servingText[1];
  const numItemsText = variation?.match(/^\d+[^,<]+/g);
  const numItemsTextToDisplay = Array.isArray(numItemsText) && numItemsText[0];

  return (
    <StyledMenuItemPrice>
      <div className="price">{formatPrice(itemPrice)}</div>
      {variation && (
        <div className="serving-info">
          {servingTextToDisplay && (
            <div className="serving-details">
              <Icon className="people-icon">people</Icon>
              <div>{`  ${servingTextToDisplay}`}</div>
            </div>
          )}
          {servingTextToDisplay && numItemsTextToDisplay && <span className="divider" />}
          {numItemsTextToDisplay && (
            <div className="quantity-details">
              <div>{formatItemName(numItemsTextToDisplay)}</div>
            </div>
          )}
        </div>
      )}
    </StyledMenuItemPrice>
  );
};

const StyledMenuItemPrice = styled.div`
  font: ${(props) => props.theme.smallTextFont};

  & .price {
    line-height: 18px;
  }

  & .serving-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .serving-details,
  .quantity-details {
    display: flex;
  }

  & .divider::before {
    content: '\\22EE';
    margin: 0 5px;
  }

  & .people-icon {
    width: 20px;
    height: 15px;
    margin: 0;
  }

  @media (max-width: ${(props) => props.theme.tablet}) {
    & .divider {
      display: none;
    }

    & .serving-info {
      flex-direction: column;
    }
  }
`;

export default MenuItemPrice;
