import { Button, LoadingIndicator } from '@cfa/react-components';
import { Flex, Input } from '@cfacorp/cowponents';
import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { pathOr, toLower } from 'ramda';
import { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'src/components/Icon';
import Loyalty from 'src/components/Loyalty/Loyalty';
import {
  selectDashboardActionLoading,
  selectDashboardPastOrderLoading,
  selectLookupOrderIsLoading,
  selectOrderToView,
} from 'src/dashboard/selectors';
import { DashboardOrder, DashboardOrderDisplay } from 'src/types/dashboard';
import down from '../../../assets/down.svg';
import up from '../../../assets/up.svg';
import constants from '../../../constants';
import { actions as dashboardActions } from '../../../reducers/dashboard';
import DetailView from './DetailView';

type CombinedDashboardOrder = DashboardOrder & DashboardOrderDisplay;
type SortProp = keyof Pick<
  CombinedDashboardOrder,
  'customerName' | 'promiseDateTime' | 'total' | 'destination' | 'status' | 'displayClient'
>;

function rowClassName(order: CombinedDashboardOrder) {
  const status = pathOr('', ['displayStatus', 'statusName'], order);
  let className = 'table-row wrapper';
  if (status === constants.ERROR) {
    className += ' error';
  }
  return className;
}

interface DashboardTableProps {
  type: string;
  orders: CombinedDashboardOrder[];
}

export const DashboardTable: React.FC<DashboardTableProps> = ({ type, orders = [] }) => {
  const location = useLocation<{ orderDetailsId: string }>();
  const dispatch = useDispatch();

  const orderToView = useSelector(selectOrderToView);
  const dashboardPastOrdersLoading = useSelector(selectDashboardPastOrderLoading);
  const lookupOrderDetailsIsLoading = useSelector(selectLookupOrderIsLoading);
  const dashboardActionLoading = useSelector(selectDashboardActionLoading);

  const [filterBy, setFilterBy] = useState<string>();
  const [sortAscending, setSortAscending] = useState<Record<SortProp, boolean>>({
    customerName: true,
    promiseDateTime: type === 'upcoming',
    total: true,
    destination: true,
    status: type === 'past',
    displayClient: true,
  });
  const [activeSortProperty, setActiveSortProperty] = useState<SortProp>('promiseDateTime');

  const mapSortProperty = (property: SortProp) => {
    setSortAscending({
      ...sortAscending,
      [property]: !sortAscending[property],
    });
    setActiveSortProperty(property);
  };

  const sortOrders = (order: CombinedDashboardOrder, nextOrder: CombinedDashboardOrder) => {
    if (activeSortProperty === 'total') {
      if (sortAscending[activeSortProperty]) {
        return order[activeSortProperty] - nextOrder[activeSortProperty];
      }
      return nextOrder[activeSortProperty] - order[activeSortProperty];
    }
    if (activeSortProperty === 'promiseDateTime') {
      if (sortAscending[activeSortProperty]) {
        return compareAsc(parseISO(order[activeSortProperty]), parseISO(nextOrder[activeSortProperty]));
      }
      return compareDesc(parseISO(order[activeSortProperty]), parseISO(nextOrder[activeSortProperty]));
    }
    const itemA = toLower(order[activeSortProperty]);
    const itemB = toLower(nextOrder[activeSortProperty]);
    if (itemA < itemB) {
      return sortAscending[activeSortProperty] ? 1 : -1;
    }
    if (itemA > itemB) {
      return sortAscending[activeSortProperty] ? -1 : 1;
    }
    return 0;
  };

  const filterOrders = (order: DashboardOrder & { customerName?: string }) => {
    if (!filterBy) {
      return order;
    }
    return order.customerName && order.customerName.toLowerCase().indexOf(filterBy.toLowerCase()) !== -1;
  };

  const renderArrows = (property: SortProp) => {
    if (sortAscending[property]) {
      return <img alt="Down arrow" className="arrow" src={down} />;
    }
    return <img alt="Up arrow" className="arrow" src={up} />;
  };

  const filteredOrders = orders.filter(filterOrders).sort(sortOrders);

  const showOrderDetails = !!(
    location.state &&
    location.state.orderDetailsId &&
    filteredOrders.find((order) => order.id === location.state.orderDetailsId)
  );

  const loadMorePastOrdersHandler = () => {
    dispatch(dashboardActions.loadMorePastOrders());
  };

  const renderLoadPastOrdersButton = () => {
    let buttonLoad = (
      <Button
        className="load-more-button"
        color="secondary"
        fullWidth
        onClick={loadMorePastOrdersHandler}
        variant="text"
      >
        Load One More Past Day
      </Button>
    );
    if (dashboardPastOrdersLoading) {
      buttonLoad = <LoadingIndicator className="spinner" size="sm" variant="inline" />;
    }
    return buttonLoad;
  };

  return (
    <StyledDashboardTable>
      <div className="table-row header">
        <div className="cell" onClick={() => mapSortProperty('customerName')} role="presentation">
          <div>Guest Name</div>
          {renderArrows('customerName')}
        </div>
        <div className="cell large" onClick={() => mapSortProperty('promiseDateTime')} role="presentation">
          <div>Date</div>
          {renderArrows('promiseDateTime')}
        </div>
        <div className="cell small" onClick={() => mapSortProperty('destination')} role="presentation">
          <div>Method</div>
          {renderArrows('destination')}
        </div>
        <div className="cell small" onClick={() => mapSortProperty('total')} role="presentation">
          <div>Total</div>
          {renderArrows('total')}
        </div>
        <div className="cell" onClick={() => mapSortProperty('status')} role="presentation">
          <div>Payment Status</div>
          {renderArrows('status')}
        </div>
        <div className="cell" onClick={() => mapSortProperty('displayClient')} role="presentation">
          <div>Source</div>
          {renderArrows('displayClient')}
        </div>
      </div>
      <Input
        autoFocus
        data-cy="order-filter"
        mx="0"
        onChange={(e: { target: { value: SetStateAction<string | undefined> } }) =>
          setFilterBy(e.target.value)
        }
        p="15px"
        placeholder="Search guest name"
        type="search"
        width={1}
      />
      {filteredOrders.map((order) => (
        <NavLink
          className={rowClassName(order)}
          data-cy={`order-${order.id}`}
          key={order.id}
          role="presentation"
          style={{ textDecoration: 'none', color: 'inherit' }}
          to={{ state: { orderDetailsId: order.id } }}
        >
          <div className="data cell name">
            {order.cfaLoyalty && <Loyalty cfaLoyalty={order.cfaLoyalty} />}
            {order.customerName}
          </div>
          <div className="data cell large date">{order.displayLongDate}</div>
          <div className="data cell small method">{order.destination}</div>
          <div className="data cell small total">{order.displayPrice}</div>
          <div className="data cell status" data-cy={`${order.id}-status`}>
            <Icon>{order.displayStatus?.statusIcon}</Icon>
            {order.displayStatus?.statusName}
          </div>
          <div className="data cell source" data-cy={`${order.id}-source`}>
            {order.displayClient}
          </div>
        </NavLink>
      ))}
      {type === 'past' && (
        <Flex className="more-past-orders-box" justifyContent="center">
          {renderLoadPastOrdersButton()}
        </Flex>
      )}
      {showOrderDetails && (
        <DetailView
          baseOrder={orders.find((order) => order.id === location.state.orderDetailsId)}
          dashboardActionLoading={dashboardActionLoading}
          key={`${location.state.orderDetailsId}-details`}
          lookupDetailsLoading={lookupOrderDetailsIsLoading}
          orderDetails={orderToView}
        />
      )}
    </StyledDashboardTable>
  );
};

const StyledDashboardTable = styled.div`
  & .table-row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    min-height: 58px;
  }

  & .header {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid #eaeaea;
    text-align: center;
    align-items: center;
  }

  & .data {
    border: 1px solid #eaeaea;
  }

  & .cell {
    display: flex;
    align-items: center;
    width: 20%;
    padding: 0 10px;
  }

  & .header > .cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .large {
    width: 40%;
  }

  & .small {
    width: 10%;
  }

  & .table-row:hover {
    cursor: pointer;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }

  & .table-row:nth-child(even) {
    background-color: ${(props) => props.theme.colors.background};
  }

  & .error {
    background-color: #d4000026 !important;
    font-weight: bold;
    color: #e60909;
  }

  & .arrow {
    width: 8px;
    height: 20px;
    margin-left: 4px;
  }

  & .status {
    display: flex;
    align-items: center;
  }

  & .status svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }

  & .no-addresses {
    text-align: center;
  }

  & .spinner {
    margin-top: 1rem;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .wrapper,
    .header {
      flex-direction: column;
      border: 1px solid #eaeaea;
      padding: 10px;
      text-align: left;
    }

    & .cell {
      border: none;
      width: 100%;
      line-height: 24px;
    }

    & .table-row {
      width: inherit;
    }

    & .header {
      display: none;
    }
  }
`;

export default DashboardTable;
