import React from 'react';
import styled from 'styled-components';

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: string;
}

const Section: React.FC<SectionProps> = ({ className, children, padding = '2rem', ...props }) => (
  <StyledSection className={className} padding={padding} {...props}>
    {children}
  </StyledSection>
);

const StyledSection = styled.section<SectionProps>`
  padding: ${({ padding }) => padding};
  max-width: 950px;
  margin: 0 auto;
`;

export default Section;
