import { Box, Flex } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import Disclaimer from '../../../components/Disclaimer';
import ValidatedField from '../../../components/ValidatedField/ValidatedField';
import constants from '../../../constants';
import { actions as guestActions, selectSearchIsLoading, selectSearchResults } from '../../../reducers/guest';
import { selectEditMode } from '../../../reducers/order';
import normalizePhone from '../../../util/normalizePhone';
import { stripNonPOSSpecialCharacters } from '../../../util/utils';
import { selectDisableGuestFields, selectDisableGuestPhone } from '../../selectors';
import { validateGuest } from '../../utils';

interface Values {
  first: string;
  last: string;
  email: string;
  phone: string;
}

const GuestForm: React.FC = () => {
  const dispatch = useDispatch();
  const searchResultsLength = useSelector(selectSearchResults).length;
  const searchIsLoading = useSelector(selectSearchIsLoading);
  const disableGuestFields = useSelector(selectDisableGuestFields);
  const disableGuestPhone = useSelector(selectDisableGuestPhone);
  const editMode = useSelector(selectEditMode);

  const renderDisclaimer = () => {
    if (searchIsLoading) {
      return '...Loading search results';
    }
    if (editMode) {
      return 'Guest details cannot be changed while editing an order';
    }
    if (disableGuestFields) {
      return 'Chick-fil-A One customer selected';
    }
    if (searchResultsLength > 0) {
      return `${searchResultsLength} Chick-fil-A One customer(s) found`;
    }
    return 'Search for an existing guest or fill out all fields and checkout as guest';
  };

  return (
    <StyledGuestForm autoComplete="nope">
      <Disclaimer>{renderDisclaimer()}</Disclaimer>
      <Flex display="flex" flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Field
            autoComplete="nope"
            component={ValidatedField}
            disabled={disableGuestFields}
            name="email"
            onBlur={() => dispatch(guestActions.guestSearchEmail())}
            placeholder="Email (search on full email)"
            type="email"
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestPhone}
            name="phone"
            normalize={normalizePhone}
            onBlur={() => dispatch(guestActions.guestSearchPhone())}
            placeholder="Phone number"
            type="text"
            validate={(value: string) => (value && value.length < 12 ? 'Invalid Phone Number' : undefined)}
          />
        </Box>
      </Flex>
      <Flex display="flex" flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestFields}
            name="first"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={() => dispatch(guestActions.guestSearch())}
            placeholder="First Name"
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Field
            component={ValidatedField}
            disabled={disableGuestFields}
            name="last"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={() => dispatch(guestActions.guestSearch())}
            placeholder="Last Name"
            type="text"
          />
        </Box>
      </Flex>
    </StyledGuestForm>
  );
};

const StyledGuestForm = styled(Box)`
  & .disclaimer {
    color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    height: 15px;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .disclaimer {
      height: 30px;
    }
  }
`;

export default reduxForm<Values>({
  form: constants.GET_FORM_TYPES.GUEST,
  destroyOnUnmount: false,
  validate: validateGuest,
})(GuestForm);
