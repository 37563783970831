import { Box, Text, Toggle } from '@cfacorp/cowponents';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { actions as orderActions, selectPaperGoodsOptions } from '../../../reducers/order';
import { PaperGoodsOptions } from '../../../types/order';
import { upperCaseFirstLetter } from '../../../util/utils';

interface Props {
  id: keyof PaperGoodsOptions;
}

const PaperGoodsToggle: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const toggled = useSelector(selectPaperGoodsOptions)[id];

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(orderActions.setPaperGoodsOptions(id, e.target.checked));
  };

  return (
    <StyledPaperGoodsToggle>
      <StyledToggleText>{upperCaseFirstLetter(id)}</StyledToggleText>
      <Toggle checked={toggled} id={id} onChange={onChangeHandler} />
    </StyledPaperGoodsToggle>
  );
};

const StyledPaperGoodsToggle = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledToggleText = styled(Text)`
  font-size: 20px;
`;

export default PaperGoodsToggle;
