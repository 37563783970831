import 'react-app-polyfill/ie11';
import './index.css';

import Bugsnag from '@bugsnag/js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { initializeFeatureFlags } from './apis';
import ConnectedApp from './App';
import UnrecoverableError from './components/UnrecoverableError';
import initializeStore from './initializeStore';
import { setupAmplitude } from './services/amplitude';
import { initializeGetBugSnagStateData, leaveBreadcrumb, setupBugsnag } from './services/bugsnag';
import getBugSnagStateData from './util/bugSnagStateData';
import { BrowserRouter } from './util/history';
import redirectToHttpsIfNecessary from './util/redirectToHttpsIfNecessary';
import ReduxStore from './util/reduxStore';

redirectToHttpsIfNecessary({
  location: window.location,
  redirectToUrl: (url) => {
    window.location.href = url;
  },
});

const store = initializeStore();
ReduxStore.store = store;

try {
  initializeFeatureFlags().subscribe();
} catch (e) {
  console.warn('initializeFeatureFlags error', e);
}

// set up bugsnag
initializeGetBugSnagStateData(getBugSnagStateData);
setupBugsnag(store);

leaveBreadcrumb('Application Startup', {
  message: 'Application initializing',
});

setupAmplitude();

const root = createRoot(document.getElementById('root') as HTMLElement);
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;
root.render(
  <ErrorBoundary FallbackComponent={UnrecoverableError}>
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedApp />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
);
