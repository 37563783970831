import { Lock } from '@cfa/system-icons';
import { Badge } from '@cfacorp/cowponents';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { actions as cartActions } from '../../../cart/reducer';
import { selectCart } from '../../../cart/selectors';
import Icon from '../../../components/Icon';
import { MenuItem as MenuItemType } from '../../../types/menu';
import ItemWarningModal from '../ItemWarningModal';
import MenuItemPrice from '../MenuItemPrice';

interface Props {
  className?: string;
  item: MenuItemType;
}

const MenuItem: React.FC<Props> = ({ className = 'menu-item', item }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCart);

  const { name, desktopImage, mobileImage, pdpImages, leadTime: itemLeadTime, disabled, tag } = item;
  const quantity = cartItems
    .filter((cartItem) => cartItem.tag === tag)
    .reduce((count, cartItem) => count + cartItem.quantity, 0);

  const imgUrl = desktopImage || pdpImages || mobileImage;

  const [showWarningModal, setShowWarningModal] = useState(false);

  const onAddToCart = () => {
    if (disabled) {
      return;
    }

    if (item.emergencyMessage) {
      setShowWarningModal(true);
    } else {
      dispatch(cartActions.addToCart(item));
    }
  };

  const onConfirm = () => {
    dispatch(cartActions.addToCart(item));
    setShowWarningModal(false);
  };

  const onCloseHandler = () => setShowWarningModal(false);

  return (
    <StyledMenuItem className={className}>
      {item.emergencyMessage && showWarningModal && (
        <ItemWarningModal
          isOpen={showWarningModal}
          message={item.emergencyMessage}
          onClose={onCloseHandler}
          onConfirm={onConfirm}
        />
      )}
      <div onClick={onAddToCart} onKeyDown={() => {}} role="presentation">
        <div className="image-wrapper">
          <Badge
            badgeContent={quantity}
            className="badge"
            data-cy={`${item.itemGroupType}-${item.tag}-badge`}
          >
            <img
              alt={name}
              className={disabled ? 'image disabled' : 'image'}
              data-cy={`${item.itemGroupType}-${item.tag}`}
              src={imgUrl}
            />
          </Badge>
          {disabled && <Lock className="lock-icon" color="#DD0031" size="24px" />}
        </div>
        <div className={disabled ? 'name-wrapper disabled' : 'name-wrapper'}>
          <div className="name">{name}</div>
          <MenuItemPrice item={item} />
        </div>
        {itemLeadTime && (
          <div className="leadtime-details">
            <Icon height="17" margin="0 2px 2px 0" width="17">
              warning
            </Icon>
            <div className="leadTime">{`Lead time: ${itemLeadTime}h`}</div>
          </div>
        )}
      </div>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled.div`
  flex: 0 1 33.33%;
  height: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & .image-wrapper,
  .name-wrapper {
    margin: 0 auto;
    text-align: center;
  }

  & .name-wrapper {
    min-height: 30px;
    display: block;
    justify-content: center;

    &.disabled {
      color: #a7acaf;
    }
  }

  & .image-wrapper {
    position: relative;
    height: 100px;
    width: 100px;
  }

  & .lock-icon {
    position: absolute;
    float: right;
  }

  & .image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;

    &.disabled {
      opacity: 0.5;
    }
  }

  & .name {
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
  }

  & .leadtime-details {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    background: red;
  }

  & .cfa-badge {
    height: 22px;
    width: 22px;
    border-radius: 50%;
  }

  @media (max-width: ${(props) => props.theme.small}) {
    flex: 0 1 50%;
  }

  @media (min-width: ${(props) => props.theme.phone}) {
    flex: 0 1 25%;
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
    flex: 0 1 20%;
  }

  @media (min-width: ${(props) => props.theme.desktop}) {
    flex: 0 1 20%;
  }
`;

export default memo(MenuItem);
