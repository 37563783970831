import { Button } from '@cfa/react-components';
import { useState } from 'react';
import styled from 'styled-components';

interface WorkingHoursProps {
  restaurantWorkingHours: string[];
  cateringDeliveryWorkingHours: string[];
}

const WorkingHours: React.FC<WorkingHoursProps> = ({
  restaurantWorkingHours,
  cateringDeliveryWorkingHours,
}) => {
  const [isRestaurantHours, setIsRestaurantHours] = useState(true);

  const renderRestaurantHours = restaurantWorkingHours.map((h) => <p key={h}>{h}</p>);

  const renderDeliveryHours = cateringDeliveryWorkingHours.map((h) => <p key={h}>{h}</p>);

  const onClickRestaurantHandler = () => {
    setIsRestaurantHours(true);
  };

  const onClickDeliveryHandler = () => {
    setIsRestaurantHours(false);
  };
  return (
    <StyledWorkingHours>
      <div className="buttons-box">
        <Button
          className={isRestaurantHours ? 'selected' : ''}
          onClick={onClickRestaurantHandler}
          size="sm"
          variant="text"
        >
          Restaurant Hours
        </Button>
        <Button
          className={isRestaurantHours ? '' : 'selected'}
          disabled={cateringDeliveryWorkingHours.length === 0}
          onClick={onClickDeliveryHandler}
          size="sm"
          variant="text"
        >
          Delivery Hours
        </Button>
      </div>
      <div className="hours-box">
        <div style={isRestaurantHours ? {} : { display: 'none' }}>{renderRestaurantHours}</div>
        <div style={isRestaurantHours ? { display: 'none' } : {}}>{renderDeliveryHours}</div>
      </div>
    </StyledWorkingHours>
  );
};

const StyledWorkingHours = styled('div')`
  margin-bottom: 10px;

  & button {
    width: 50%;
    min-width: unset;
    padding: 4px;
    border: 1px solid ${(prop) => prop.theme.colors.primary};
    color: #000 !important;
    border-bottom: 0;
    border-radius: 0;

    &.selected {
      background-color: ${(prop) => prop.theme.colors.primary};
      color: ${(prop) => prop.theme.colors.secondary} !important;
    }

    :disabled {
      border-bottom: 0;
      cursor: no-drop;
    }
  }

  & .hours-box {
    padding: 5px;
    border: solid 1px ${(prop) => prop.theme.colors.text};
  }
`;

export default WorkingHours;
