import 'react-datepicker/dist/react-datepicker.css';

import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { isValidOrderDate } from 'src/eventDetails/utils';
import { selectAvailableDates, selectDatesLoading } from 'src/reducers/order';
import { selectBypassBusinessRules } from 'src/reducers/user';

const DatePicker: React.FC<WrappedFieldProps> = ({ input }) => {
  const bypassBusinessRules = useSelector(selectBypassBusinessRules);
  const availableDates = useSelector(selectAvailableDates);
  const availableDatesLoading = useSelector(selectDatesLoading);

  const loading = bypassBusinessRules ? false : availableDatesLoading;

  return (
    <ReactDatePicker
      autoComplete="off"
      disabled={loading}
      dropdownMode="select"
      filterDate={(date) => isValidOrderDate(date, availableDates, bypassBusinessRules)}
      monthsShown={1}
      name="Select Date"
      onChange={(date) => input.onChange(date)}
      placeholderText={loading ? 'Retrieving Available Dates...' : 'Select Date'}
      selected={input.value}
    />
  );
};

export default DatePicker;
