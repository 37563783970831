import { type Reducer, type UnknownAction } from '@reduxjs/toolkit';
import { curry } from 'ramda';

import type { Menu, Method } from '../types/menu';

export type State = {
  isLoading: boolean;
  error: {
    menu: Nullable<string>;
  };
  Delivery: Menu;
  Pickup: Menu;
};

export const types = {
  MENU_REQUEST: '[Menu] Request',
  MENU_SUCCESS: '[Menu] Success',
  MENU_FAILURE: '[Menu] Failure',
};

export const actions = {
  getMenu: () => ({ type: types.MENU_REQUEST }),
  menuSuccess: curry((method: Method, menu: Menu) => ({ type: types.MENU_SUCCESS, method, menu })),
  menuFailure: curry((method: Method, error: ReducerError) => ({ type: types.MENU_FAILURE, method, error })),
};

const initialState: State = {
  isLoading: false,
  error: {
    menu: null,
  },
  Delivery: { categories: [], itemGroups: {} },
  Pickup: { categories: [], itemGroups: {} },
};

const reducer: Reducer<State, UnknownAction> = (state = initialState, action) => {
  switch (action.type) {
    case types.MENU_REQUEST:
      return { ...state, isLoading: true, error: { menu: null } };

    case types.MENU_SUCCESS: {
      const { method, menu } = action as ReturnType<(typeof actions)['menuSuccess']>;
      return { ...state, isLoading: false, [method]: menu };
    }

    case types.MENU_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          ...state.error,
          menu: (action as ReturnType<(typeof actions)['menuFailure']>).error.toString(),
        },
      };

    default:
      return state;
  }
};

export default reducer;

export const selectMenu = (state: { menu: State }) => state.menu;
