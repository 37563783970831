import { Box, Card } from '@cfacorp/cowponents';
import React from 'react';
import styled from 'styled-components';

import DashboardActionButton from 'src/dashboard/components/DashboardActions/DashboardActionButton';
import Icon from '../../../components/Icon';
import OrderSummary from '../../../components/OrderSummary/OrderSummary';
import Order, { FavoriteOrder, FavoriteOrderDetail } from '../../../types/order';
import { formatReadableDate } from '../../utils';

interface PastOrdersCardProps {
  order: Order | (FavoriteOrder & FavoriteOrderDetail);
  showReorderButton: () => boolean;
  handleReorderClick: () => void;
  openFavoriteModal: (id: string) => void;
}

const PastOrdersCard: React.FC<PastOrdersCardProps> = ({
  order,
  showReorderButton,
  handleReorderClick,
  openFavoriteModal,
}) => {
  const { deliveryAddress, lineItems, destination, promiseDateTime, id, companyName } = order as Order;
  const { favoriteOrderId, name } = order as FavoriteOrder & FavoriteOrderDetail;
  const { addressLine1, addressLine2, city, state } = deliveryAddress || {};
  return (
    <StyledPastOrdersCard>
      <div className="card-header">
        <Icon
          className={favoriteOrderId ? 'favorite selected' : 'favorite'}
          onClick={() => openFavoriteModal(id || favoriteOrderId)}
        >
          favorite
        </Icon>
        {favoriteOrderId ? (
          name
        ) : (
          <>
            <div>{formatReadableDate(promiseDateTime || '')}</div>
            <div>{destination}</div>
          </>
        )}
      </div>
      {deliveryAddress && (
        <div className="card-delivery-address">
          <Box className="title">Delivery Address:</Box>
          <Box data-cy="guestAddressName">{companyName}</Box>
          <Box data-cy="guestAddressStreet">{addressLine1}</Box>
          <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
          <Box data-cy="guestAddressCityState">{`${city}, ${state}`}</Box>
        </div>
      )}
      <OrderSummary
        cartItems={lineItems}
        className="card-order-summary"
        showModifiers={false}
        showPrices={false}
      />
      {showReorderButton() && (
        <div className="card-footer">
          <DashboardActionButton
            buttonText="Re-Order"
            data-testId="reorder-action"
            icon="refresh"
            onClick={handleReorderClick}
          />
          <DashboardActionButton
            buttonText={favoriteOrderId ? 'Edit / Remove Favorite' : 'Add to Favorites'}
            data-testId="favorite-action"
            icon="favorite-outline"
            onClick={() => openFavoriteModal(id || favoriteOrderId)}
          />
        </div>
      )}
    </StyledPastOrdersCard>
  );
};

const StyledPastOrdersCard = styled(Card)`
  flex: 0 0 31.9%;

  @media (max-width: ${(props) => props.theme.medium}) {
    flex: 0 0 47.5%;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    flex: 0 0 97%;
  }

  & .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.disabled};
    padding: 3px 0 10px;
    font: ${(props) => props.theme.smallBoldFont};
  }

  & .card-order-summary,
  .card-delivery-address {
    padding: 10px;
  }

  & .card-delivery-address,
  .card-order-summary div {
    font: ${(props) => props.theme.smallTextFont};
    line-height: 20px;
  }

  & .card-order-summary .title,
  .card-delivery-address .title {
    font: ${(props) => props.theme.smallBoldFont};
  }

  & .card-footer {
    display: flex;
    margin-top: auto;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background};
  }

  & .card-footer button {
    margin: 10px;
  }

  & .favorite {
    width: 25px;
    height: 20px;
    margin: 0;
    fill: ${(props) => props.theme.colors.disabled};
  }

  & .favorite:hover {
    cursor: pointer;
  }

  & .selected {
    fill: ${(props) => props.theme.colors.error};
  }
`;

export default PastOrdersCard;
