import { Button, LoadingIndicator } from '@cfa/react-components';
import { Flex, RadioGroup } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  actions as guestActions,
  selectNoPastDeliveryAddresses,
  selectPastDeliveryAddresses,
  selectPastDeliveryAddressesLoading,
} from '../../../reducers/guest';
import PastDeliveryAddressesItem from '../PastDeliveryAddressesItem';

const PastDeliveryAddresses: React.FC = () => {
  const dispatch = useDispatch();
  const pastDeliveryAddresses = useSelector(selectPastDeliveryAddresses);
  const pastDeliveryAddressesLoading = useSelector(selectPastDeliveryAddressesLoading);
  const noPastDeliveryAddresses = useSelector(selectNoPastDeliveryAddresses);
  const renderLoadDeliveryAddresses = () => {
    let buttonLoad = (
      <Button
        className="get-past-addresses"
        color="primary"
        loading={pastDeliveryAddressesLoading}
        onClick={() => dispatch(guestActions.getPastDeliveryAddresses())}
        variant="text"
      >
        Get Past Delivery Addresses
      </Button>
    );
    if (pastDeliveryAddressesLoading) {
      buttonLoad = <LoadingIndicator className="loading" data-testid="spinner" size="md" variant="inline" />;
    } else if (noPastDeliveryAddresses) {
      buttonLoad = (
        <div className="no-addresses">
          There are no previously used delivery addresses for the selected guest
        </div>
      );
    } else if (pastDeliveryAddresses.length >= 1) {
      buttonLoad = (
        <RadioGroup>
          {pastDeliveryAddresses.map((address) => {
            const id = `${address?.addressLine1?.split(' ').join('')}-${address.city}-${address.zip}`;
            return <PastDeliveryAddressesItem address={address} key={id} />;
          })}
        </RadioGroup>
      );
    }

    return buttonLoad;
  };

  return (
    <StyledPastDeliveryAddresses
      data-testid="past-delivery-addresses"
      flexDirection="column"
      justifyContent="center"
    >
      {renderLoadDeliveryAddresses()}
    </StyledPastDeliveryAddresses>
  );
};

const StyledPastDeliveryAddresses = styled(Flex)`
  margin-top: 1rem;
  padding: 0 1rem;

  & .no-addresses {
    text-align: center;
  }

  & .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

export default PastDeliveryAddresses;
