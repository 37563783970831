import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import { actions as orderActions } from '../../../reducers/order';
import { PaperGoodsOptions } from '../../../types/order';
import { camelToTitleCase } from '../../../util/utils';

interface Props {
  name: keyof PaperGoodsOptions;
}

const PaperGoodsBadge: React.FC<Props> = ({ name }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(orderActions.setPaperGoodsOptions(name, false));
  };

  return (
    <StyledPaperGoodsBadge>
      {camelToTitleCase(name)}
      <Icon className="icon-close" onClick={onClickHandler}>
        close
      </Icon>
    </StyledPaperGoodsBadge>
  );
};

const StyledPaperGoodsBadge = styled.span`
  color: ${(props) => props.theme.colors.primary};
  border: solid 1px ${(props) => props.theme.colors.primary};
  padding: 5px;
  margin-left: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 73px;

  & .icon-close {
    max-width: 15px;
    max-height: 15px;
    margin: 0 0 0 5px;
    fill: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    cursor: pointer;
  }
`;

export default PaperGoodsBadge;
