import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import rootEpic from './epics';
import rootReducer from './reducers';

const epicMiddleware = createEpicMiddleware();

const initializeStore = () => {
  const newStore = configureStore({
    reducer: rootReducer,
    //@ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(epicMiddleware),
  });

  epicMiddleware.run(rootEpic);

  return newStore;
};

export default initializeStore;
