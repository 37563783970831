import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ooeConstants from 'src/constants';
import { selectIsDeviceOnline } from 'src/reducers/device';

const DeviceStatusMessage: React.FC = () => {
  const isDeviceOnline = useSelector(selectIsDeviceOnline);

  const statusClass = isDeviceOnline ? 'online' : 'offline';
  const statusMessage = isDeviceOnline ? ooeConstants.ONLINE_MESSAGE : ooeConstants.OFFLINE_MESSAGE;

  return <StyledDeviceStatusMessage className={statusClass}>{statusMessage}</StyledDeviceStatusMessage>;
};

const StyledDeviceStatusMessage = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font: ${(props) => props.theme.regularBoldFont};
  min-height: 30px;
  text-align: center;
  padding-top: 10px;
  display: none;

  &.online {
    background-color: ${(props) => props.theme.colors.success};
    display: block;
  }

  &.offline {
    background-color: ${(props) => props.theme.colors.error};
    display: block;
  }
`;

export default DeviceStatusMessage;
