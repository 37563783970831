import { createSelector } from '@reduxjs/toolkit';
import { pluck } from 'ramda';

import ooeConstants from '../constants';
import { selectDateIsToday } from '../reducers';
import { selectTime } from '../reducers/form';
import { selectDestination } from '../reducers/order';
import {
  selectBypassBusinessRules,
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  selectDeliveryLeadTime,
  selectLocationTimezone,
  selectPickupLeadTime,
} from '../reducers/user';
import { generateTimeSlots } from '../util/format';

export const selectLeadTimeWarning = createSelector(
  selectDestination,
  selectPickupLeadTime,
  selectDeliveryLeadTime,
  (destination, pickupLeadTime, deliveryLeadTime) => {
    if (destination === ooeConstants.DELIVERY) {
      return {
        type: 'warning',
        message: deliveryLeadTime,
      };
    }
    return {
      type: 'warning',
      message: pickupLeadTime,
    };
  },
);

export const selectTimeShouldClear = createSelector(
  selectTime,
  selectDateIsToday,
  selectBypassBusinessRules,
  selectLocationTimezone,
  (time, dateIsToday, bypassBusinessRules, timeZone) => {
    const timeSlots = pluck(
      'time',
      generateTimeSlots(dateIsToday, timeZone).filter((timeSlot) => timeSlot.available),
    );
    const selectedTimeNotAvailable = timeSlots.indexOf(time) === -1;
    return bypassBusinessRules && selectedTimeNotAvailable;
  },
);

export const selectSpecialEvents = createSelector(
  selectDestination,
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  (destination, cateringDelivery, cateringPickup) => {
    if (destination === 'Delivery') {
      return cateringDelivery?.specialEvents ?? [];
    }

    if (destination === 'Pickup') {
      return cateringPickup?.specialEvents ?? [];
    }
    return [];
  },
);

export const selectHoursOfOperation = createSelector(
  selectDestination,
  selectCateringDeliveryMOSettings,
  selectCateringPickupMOSettings,
  (destination, cateringDelivery, cateringPickup) => {
    let serviceHours;
    if (destination === 'Delivery') {
      serviceHours = cateringDelivery?.hoursOfOperation;
    } else if (destination === 'Pickup') {
      serviceHours = cateringPickup?.hoursOfOperation;
    }
    return serviceHours;
  },
);
