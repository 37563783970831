import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';

import PaymentOptions from './components/PaymentOptions';
import SubmitOrderButton from './components/SubmitOrderButton';
import CreateInvoiceButton from './components/CreateInvoiceButton';
import CreateInvoicePage from './components/CreateInvoicePage';
import DeliveryTips from './components/DeliveryTips';
import OrderInformation from './components/OrderInformation';
import { selectDeliveryTipIsDisplayed } from './selectors';

export const Payment: React.FC = () => {
  const deliveryTipIsDisplayed = useSelector(selectDeliveryTipIsDisplayed);
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <OrderInformation />
      <Section>
        <SectionHeader>Payment</SectionHeader>
        <PaymentOptions />
      </Section>
      {deliveryTipIsDisplayed && (
        <Section>
          <SectionHeader>Add Tip</SectionHeader>
          <DeliveryTips />
        </Section>
      )}
      <Section>
        <>
          <div style={{ display: 'none' }}>
            <CreateInvoicePage ref={componentRef} />
          </div>
          <CreateInvoiceButton onPrint={handlePrint}>Print Order Summary</CreateInvoiceButton>
        </>
      </Section>
      <Section>
        <SubmitOrderButton />
      </Section>
    </>
  );
};

export default Payment;
