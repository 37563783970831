import { createSelector } from '@reduxjs/toolkit';
import { contains, keys, pick, pickBy } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Icon from 'src/components/Icon';
import ooeConstants from 'src/constants';
import { selectExportEntireDaysExcelError } from 'src/dashboard/selectors';
import { selectGuestErrors } from 'src/reducers/guest';
import {
  actions as orderActions,
  keys as orderKeys,
  selectDeliveryError,
  selectOrderError,
} from 'src/reducers/order';
import { selectLookupLocationError } from 'src/reducers/user';
import { capitalizeFirstLetter } from 'src/util/format';

const ErrorMessage: React.FC = () => {
  const dispatch = useDispatch();
  const errors = useSelector(errorSelector);

  const errorKeys = keys(errors);

  const dismissErrorHandler = (errorKey: string) => {
    if (errorKey === orderKeys.EDIT_ORDER) {
      if (window.confirm('Are you sure you want to stop editing this order?')) {
        return dispatch(orderActions.dismissError(errorKey));
      }
      return false;
    }
    return dispatch(orderActions.dismissError(errorKey));
  };

  return (
    errorKeys.length > 0 && (
      <StyledErrorMessage>
        {errorKeys.map((errorKey) => (
          <div className={getErrorClassName(errors[errorKey])} key={errorKey}>
            <div className="icon-error">
              <Icon>{getErrorClassName(errors[errorKey])}</Icon>
              {capitalizeFirstLetter(errors[errorKey].replace(ooeConstants.DISMISSIBLE, ''))}
            </div>
            {contains(ooeConstants.DISMISSIBLE, errors[errorKey]) && (
              <button className="close-button" onClick={() => dismissErrorHandler(errorKey)}>
                <Icon className="close">close</Icon>
              </button>
            )}
          </div>
        ))}
      </StyledErrorMessage>
    )
  );
};

const getErrorClassName = (error: string) => {
  const className = error.split(':')[0].toLowerCase();
  return ['error', 'warning'].includes(className) ? className : 'error';
};

const errorSelector = createSelector(
  selectOrderError,
  selectDeliveryError,
  selectGuestErrors,
  selectExportEntireDaysExcelError,
  selectLookupLocationError,
  (orderError, deliveryError, guestErrors, excelError, lookupLocationError) => {
    let errors = pick([orderKeys.UPDATE_ORDER, orderKeys.SUBMIT_ORDER, orderKeys.EDIT_ORDER], orderError);
    const deliveryErrorMessage =
      deliveryError && typeof deliveryError === 'object'
        ? `${deliveryError.type}: ${deliveryError.message}`
        : null;
    errors = {
      ...errors,
      deliveryErrorMessage,
      ...guestErrors,
      excelError: excelError || null,
      lookupLocationError,
    };

    return pickBy<typeof errors, Record<string, string>>(
      (val) => typeof val === 'string' && val !== 'SyntaxError: Unexpected end of JSON input',
      errors,
    );
  },
);

const StyledErrorMessage = styled.div`
  position: sticky;
  top: 82px;
  width: 100%;
  z-index: 1000;

  & > div {
    font: ${(props) => props.theme.smallBoldFont};
    margin: 7px;
    padding: 7px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .error {
    color: ${(props) => props.theme.colors.error};
    border: 1px solid ${(props) => props.theme.colors.error};
    background-color: #ffe8e6;
  }

  & .warning {
    color: ${(props) => props.theme.colors.warning};
    border: 1px solid ${(props) => props.theme.colors.warning};
    background-color: #fffcee;
  }

  & .icon-error {
    flex: 40;
  }

  & .icon-error svg {
    width: 19px;
    float: left;
    max-height: 20px;
    margin: 0 5px;
  }

  & .close-button {
    flex: 1;
    background: transparent;
    border: transparent;
    width: 25px;
  }

  & .close-button svg {
    fill: ${(props) => props.theme.colors.outline};
    margin: 0;
    width: 20px;
    height: 20px;
  }

  & .close-button:hover {
    cursor: pointer;
  }
`;

export default ErrorMessage;
