import Modal from 'react-modal';
import theme from 'src/theme';
import { isSmallScreen } from 'src/util/utils';

interface OverlayModalProps {
  className?: string;
  isOpen?: boolean;
  close: () => void;
  minWidth?: string;
  children: React.ReactNode;
}

const OverlayModal: React.FC<OverlayModalProps> = ({
  className,
  isOpen = false,
  close,
  children,
  minWidth,
}) => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: !isSmallScreen()
      ? ({
          position: 'absolute',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          outline: 'none',
          fontSize: '14px',
          fontFamily: theme.phone,
          maxHeight: '90vh',
          overflowY: 'scroll',
          minWidth: minWidth,
        } as React.CSSProperties)
      : ({
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          marginRight: 0,
          maxWidth: '100%',
          transform: 'none',
          position: 'fixed',
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: '100vh',
        } as React.CSSProperties),
    overlay: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      backgroundColor: 'inherit',
    } as React.CSSProperties,
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close} portalClassName={className} style={customStyles}>
      {children}
    </Modal>
  );
};

export default OverlayModal;
