import { Box, Flex } from '@cfacorp/cowponents';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  actions as orderActions,
  selectSelectedPercentageTipValue,
  selectSubTotalAmount,
} from '../../../reducers/order';
import DeliveryTipsCustomAmount from '../DeliveryTipsCustomAmount';
import DeliveryTipsPercentageButton from '../DeliveryTipsPercentageButton';

const DeliveryTips: React.FC = () => {
  const dispatch = useDispatch();

  const subTotal = useSelector(selectSubTotalAmount);
  const selectedPercentage = useSelector(selectSelectedPercentageTipValue);

  const [isSelect, setIsSelect] = useState(false);
  const checkbox1 = useRef<HTMLInputElement>(null);
  const checkbox2 = useRef<HTMLInputElement>(null);
  const checkbox3 = useRef<HTMLInputElement>(null);
  const checkbox4 = useRef<HTMLInputElement>(null);

  const lessThanMinForPecentage = subTotal < 25;
  const onClickHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(orderActions.addDeliveryTip('tipPercentOfSale', e.target.value));
    setIsSelect(false);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    if (e.target.value !== '') {
      dispatch(orderActions.addDeliveryTip('tipAmount', e.target.value));
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
    if (checkbox1.current) {
      checkbox1.current.checked = false;
    }
    if (checkbox2.current) {
      checkbox2.current.checked = false;
    }
    if (checkbox3.current) {
      checkbox3.current.checked = false;
    }
    if (checkbox4.current) {
      checkbox4.current.checked = false;
    }
  };

  return (
    <StyledDeliveryTips display="flex" flexWrap="wrap">
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          checked={selectedPercentage === 10}
          disabled={lessThanMinForPecentage}
          onClickHandler={onClickHandler}
          ref={checkbox1}
          tipPercentageButtonId="p10"
          value="10"
        >
          10%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          checked={selectedPercentage === 15}
          disabled={lessThanMinForPecentage}
          onClickHandler={onClickHandler}
          ref={checkbox2}
          tipPercentageButtonId="p15"
          value="15"
        >
          15%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          checked={selectedPercentage === 20}
          disabled={lessThanMinForPecentage}
          onClickHandler={onClickHandler}
          ref={checkbox3}
          tipPercentageButtonId="p20"
          value="20"
        >
          20%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1 / 2, 1 / 6]}>
        <DeliveryTipsPercentageButton
          checked={selectedPercentage === 25}
          disabled={lessThanMinForPecentage}
          onClickHandler={onClickHandler}
          ref={checkbox4}
          tipPercentageButtonId="p25"
          value="25"
        >
          25%
        </DeliveryTipsPercentageButton>
      </Box>
      <Box width={[1, 2 / 6]}>
        <DeliveryTipsCustomAmount isSelect={isSelect} onBlurHandler={onBlurHandler} />
      </Box>
    </StyledDeliveryTips>
  );
};

const StyledDeliveryTips = styled(Flex)`
  max-width: 680px;
  margin: 0 auto;
`;

export default DeliveryTips;
