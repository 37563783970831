import { parse, format, addMinutes } from 'date-fns';
import ooeConstants from '../constants';
import { HoursOfOperation } from '../types/location';

export const pullOutHours = (hoursOfOperation: HoursOfOperation) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday } = hoursOfOperation;
  const arrOfDays = [
    { day: 'Monday', hours: monday },
    { day: 'Tuesday', hours: tuesday },
    { day: 'Wednesday', hours: wednesday },
    { day: 'Thursday', hours: thursday },
    { day: 'Friday', hours: friday },
    { day: 'Saturday', hours: saturday },
  ];
  return arrOfDays.map((dayOfWeek) => {
    let blackoutHours = null;
    if (dayOfWeek.hours?.blackoutHours) {
      const { openTime } = dayOfWeek?.hours?.blackoutHours;
      const { durationInMinutes } = dayOfWeek?.hours.blackoutHours;
      const parsedOpenTime = parse(openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date());
      const formatedOpenTime = format(parsedOpenTime, ooeConstants.DATE_TIME_FORMAT.timeAmPm);
      const endTime = format(
        addMinutes(parsedOpenTime, durationInMinutes),
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      blackoutHours = `- ${formatedOpenTime} ${endTime} -`;
    }
    if (dayOfWeek.hours?.operationType === 'closed') {
      return `${dayOfWeek.day}: Closed`;
    }
    if (dayOfWeek.hours?.operationType === 'open24Hours') {
      return `${dayOfWeek.day}: ${blackoutHours ? `12:00 AM ${blackoutHours} 12:00 PM` : 'Open 24h'}`;
    }
    if (dayOfWeek.hours?.operationType === 'standardHours') {
      const { openTime, durationInMinutes } = dayOfWeek?.hours?.operatingInterval || {
        openTime: '',
        durationInMinutes: 0,
      };
      const parsedOpenTime = parse(openTime, ooeConstants.DATE_TIME_FORMAT.time, new Date());
      const formatedOpenTime = format(parsedOpenTime, ooeConstants.DATE_TIME_FORMAT.timeAmPm);
      const endTime = format(
        addMinutes(parsedOpenTime, durationInMinutes),
        ooeConstants.DATE_TIME_FORMAT.timeAmPm,
      );
      return `${dayOfWeek.day}: ${formatedOpenTime} ${blackoutHours || '-'} ${endTime}`;
    }
    return `${dayOfWeek.day}: Closed`;
  });
};
