import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';

import ooeConstants from '../constants';
import { selectLocationNumber, selectUserName, actions as userActions } from '../reducers/user';
import { logPageView } from '../services/analytics';

const More: React.FC = () => {
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);
  const locationNumber = useSelector(selectLocationNumber);

  const emailDetails = ooeConstants.MAILTO_STRING(locationNumber);

  return (
    <StyledMore>
      <a
        className="link"
        href={ooeConstants.HELPFUL_LINKS.NEED_HELP}
        onClick={() => logPageView('more/Need Help')}
        rel="noopener noreferrer"
        target="_blank"
      >
        Need Help?
      </a>
      <NavLink
        to={{
          pathname: '/more/dashboard',
        }}
      >
        <div className="link">Customer Dashboard</div>
      </NavLink>
      <SectionHeader />
      <div className="user">{userName}</div>
      <button className="link sign-out" onClick={() => dispatch(userActions.logoutUser())}>
        Sign Out
      </button>
      <SectionHeader />
      <div className="user">Helpful Links:</div>
      <div className="helpful-links">
        <a
          className="link"
          href={ooeConstants.HELPFUL_LINKS.ABOUT_CMT}
          onClick={() => logPageView('more/About CMT')}
          rel="noopener noreferrer"
          target="_blank"
        >
          About CMT
        </a>
      </div>
      <div className="helpful-links">
        <a
          className="link"
          href={ooeConstants.HELPFUL_LINKS.USER_GUIDE}
          onClick={() => logPageView('more/User Guide')}
          rel="noopener noreferrer"
          target="_blank"
        >
          User Guide
        </a>
      </div>
      <div className="helpful-links">
        <a
          className="link"
          href={ooeConstants.HELPFUL_LINKS.FAQ}
          onClick={() => logPageView('more/Frequently Asked Questions')}
          rel="noopener noreferrer"
          target="_blank"
        >
          Frequently Asked Questions
        </a>
      </div>
      <div className="helpful-links">
        <a
          className="link"
          href={emailDetails}
          onClick={() => logPageView('more/Give Feedback')}
          rel="noopener noreferrer"
        >
          Give Feedback
        </a>
      </div>
      <SectionHeader />
      <div className="version-number">{`Version ${ooeConstants.APP_VERSION}`}</div>
    </StyledMore>
  );
};

const StyledMore = styled(Section)`
  & .user,
  .version-number {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0 10px;
  }

  & .link {
    margin: 10px;
    padding: 0 0 10px;
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.colors.primary};
  }

  & .helpful-links {
    margin: 10px 0;
  }

  & a {
    text-decoration: none;
  }

  & .sign-out {
    background-color: ${(props) => props.theme.colors.secondary};
    border: none;
  }

  & .link:hover {
    cursor: pointer;
  }
`;

export default More;
