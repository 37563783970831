import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'ramda';

import {
  selectCartItemsLength,
  selectMaxPromoFreeQtySubmitWarning,
  selectPromoFreeActive,
  selectShowMaxPromoFreeItemCountWarning,
} from '../cart/selectors';
import ooeConstants from '../constants';
import { selectLeadTimeWarning } from '../eventDetails/selectors';
import {
  selectMaxSameDayOrderTotalAmountCents,
  selectMinDeliveryAmount,
  selectMinPickupAmount,
} from '../floatingMenu/selectors';
import {
  selectDeliveryMinNotMet,
  selectExceedsMaxOrderTotal,
  selectExceedsSameDayMaxOrderTotal,
  selectMaxOrderAmount,
  selectPaymentValid,
  selectPickupMinNotMet,
} from '../reducers';
import {
  selectDetailsForm,
  selectDetailsFormSyncErrors,
  selectDetailsFormValues,
  selectGuestDetailsValid,
  selectGuestFormSyncErrors,
  selectPaymentForm,
  selectSecondaryContactSubmitWarning,
  selectSecondaryContactValid,
} from '../reducers/form';
import { selectCfaGuestSubmitWarning } from '../reducers/guest';
import {
  selectDeliveryAddressChanged,
  selectDeliveryAddressSubmitWarning,
  selectDeliveryRangeWarning,
  selectDestination,
  selectDestinationValid,
  selectHasOrderUpdateErrors,
  selectOrderIsLoading,
  selectPaperGoodsError,
  selectPaperGoodsOptions,
  selectTotalAmount,
  selectValidAddressError,
} from '../reducers/order';
import { isVcaUser, selectBypassBusinessRules, selectLocationNumber } from '../reducers/user';
import { submitWarningErrorMessages } from '../util/customerErrorMessages';
import { capitalizeFirstLetter, formatPaperGoodsOptions, formatPrice } from '../util/format';

export const selectDeliveryTipIsDisplayed = createSelector(
  selectPaymentForm,
  selectDestination,
  selectLocationNumber,
  (payment, destination, locationNumber) =>
    payment?.values?.selectedMethod === ooeConstants.CREDIT &&
    destination === ooeConstants.DELIVERY &&
    ooeConstants.AVAILABLE_DELIVERY_TIP_LOCATIONS.includes(locationNumber),
);

export const selectEventDetailsValid = createSelector(
  selectDetailsForm,
  selectPaperGoodsError,
  (details, paperGoodsError) => !isEmpty(details) && !details.syncErrors && paperGoodsError.length === 0,
);

export const selectEventSummaryValid = createSelector(
  selectEventDetailsValid,
  selectGuestDetailsValid,
  selectDestinationValid,
  (detailsValid, guestValid, destinationValid) => detailsValid && guestValid && destinationValid,
);

export const selectAllFormsValid = createSelector(
  selectEventSummaryValid,
  selectCartItemsLength,
  selectPaymentValid,
  (detailsAndGuest, cartLength, paymentValid) => paymentValid && detailsAndGuest && cartLength > 0,
);

export const selectFormSyncErrors = createSelector(
  selectGuestFormSyncErrors,
  selectDetailsFormSyncErrors,
  selectPaperGoodsError,
  (guestFormSyncErrors, detailsFormSyncErrors, paperGoodsError) => {
    const errorsArray = [...guestFormSyncErrors, ...detailsFormSyncErrors, ...paperGoodsError].map(
      capitalizeFirstLetter,
    );
    const { length: errorsArrayLen } = errorsArray;
    return errorsArray.map((fieldName, idx) =>
      errorsArrayLen > 2 && idx === errorsArrayLen - 1 ? `and ${fieldName}` : fieldName,
    );
  },
);

export const selectFormSubmitWarning = createSelector(selectFormSyncErrors, (errors) => {
  if (errors.length === 0) {
    return {};
  }
  const prepositionToUse = errors.length === 1 ? 'is a' : 'are';
  const pluralizingLetter = errors.length === 1 ? '' : 's';
  return {
    type: 'error',
    message: `${errors.join(', ')} ${prepositionToUse} required field${pluralizingLetter}.`,
  };
});

export const selectPaymentSubmitWarning = createSelector(selectPaymentValid, (paymentValid) => {
  if (!paymentValid) {
    return {
      type: 'error',
      message: 'Payment method must be validated by entering the zip code associated with the selected card.',
    };
  }
  return {};
});

export const selectAllItemsPromoFree = createSelector(
  selectPromoFreeActive,
  selectTotalAmount,
  (promoFreeActive, totalAmount) => promoFreeActive && totalAmount === 0,
);

export const selectPayLaterNotAllowed = createSelector(
  selectLocationNumber,
  isVcaUser,
  (locationNumber, isVcaBypass) =>
    isVcaBypass && ooeConstants.PAY_LATER_NOT_ALLOWED_LOCATIONS.includes(locationNumber),
);

export const selectSpecialInstructionMessage = createSelector(
  selectPaperGoodsOptions,
  selectDetailsFormValues,
  (paperGoods, details) => `${formatPaperGoodsOptions(paperGoods)} ${details?.specialInstructions || ''}`,
);

export const selectSubmitButtonIsDisabled = createSelector(
  selectDeliveryMinNotMet,
  selectPickupMinNotMet,
  selectExceedsMaxOrderTotal,
  selectAllFormsValid,
  selectBypassBusinessRules,
  selectOrderIsLoading,
  selectDestinationValid,
  selectHasOrderUpdateErrors,
  selectValidAddressError,
  selectDeliveryRangeWarning,
  selectDeliveryAddressChanged,
  selectExceedsSameDayMaxOrderTotal,
  selectSecondaryContactValid,
  selectShowMaxPromoFreeItemCountWarning,
  (
    deliveryMinNotMet,
    pickupMinNotMet,
    exceedsMaxOrderTotal,
    allFormsValid,
    bypassBusinessRules,
    orderIsLoading,
    addressValid,
    hasOrderUpdateErrors,
    validAddressError,
    deliveryRangeWarning,
    deliveryAddressChanged,
    exceedsMaxSameDay,
    secondaryContactValid,
    showMaxPromoFreeItemCountWarning,
  ) => {
    const disabledByBusinessRules =
      deliveryMinNotMet ||
      pickupMinNotMet ||
      exceedsMaxOrderTotal ||
      exceedsMaxSameDay ||
      !!deliveryRangeWarning;
    const disabledRegardlessOfBusinessRules =
      hasOrderUpdateErrors ||
      !allFormsValid ||
      orderIsLoading ||
      (!!validAddressError && deliveryAddressChanged) ||
      !secondaryContactValid ||
      showMaxPromoFreeItemCountWarning;

    if (bypassBusinessRules) {
      // CMT-941 - the fact that !addressValid is only checked
      // when bypassing business rules is probably a bug
      return disabledRegardlessOfBusinessRules || !addressValid;
    }
    return disabledRegardlessOfBusinessRules || disabledByBusinessRules;
  },
);

export const selectSubtotalSubmitWarning = createSelector(
  selectDeliveryMinNotMet,
  selectMinDeliveryAmount,
  selectPickupMinNotMet,
  selectMinPickupAmount,
  selectExceedsMaxOrderTotal,
  selectMaxOrderAmount,
  selectExceedsSameDayMaxOrderTotal,
  selectMaxSameDayOrderTotalAmountCents,
  (
    deliveryMinNotMet,
    minDeliveryAmount,
    pickupMinNotMet,
    minPickupAmount,
    exceedsMax,
    maxAmount,
    exceedsMaxSameDay,
    maxSameDayAmount,
  ) => {
    if (exceedsMax) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.ORDER_MAX}${maxAmount && formatPrice(maxAmount / 100)}.`,
      };
    }
    if (exceedsMaxSameDay) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.SAME_DAY_ORDER_MAX}${maxSameDayAmount && formatPrice(maxSameDayAmount / 100)}.`,
      };
    }
    if (deliveryMinNotMet) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.DELIVERY_MIN}${minDeliveryAmount && formatPrice(minDeliveryAmount / 100)}.`,
      };
    }
    if (pickupMinNotMet) {
      return {
        type: 'warning',
        message: `${submitWarningErrorMessages.PICKUP_MIN}${minPickupAmount && formatPrice(minPickupAmount / 100)}.`,
      };
    }
    return {};
  },
);

export const selectAllSubmitWarnings = createSelector(
  selectSubtotalSubmitWarning,
  selectFormSubmitWarning,
  selectDeliveryAddressSubmitWarning,
  selectPaymentSubmitWarning,
  selectCfaGuestSubmitWarning,
  selectLeadTimeWarning,
  selectSecondaryContactSubmitWarning,
  selectMaxPromoFreeQtySubmitWarning,
  (
    subtotalWarnings,
    formWarnings,
    destinationWarning,
    paymentWarning,
    cfaGuestWarning,
    leadTime,
    secondaryContact,
    promoFreeMaxQty,
  ) =>
    [
      subtotalWarnings,
      formWarnings,
      destinationWarning,
      paymentWarning,
      cfaGuestWarning,
      leadTime,
      secondaryContact,
      promoFreeMaxQty,
    ].filter((warning) => !isEmpty(warning)),
);
