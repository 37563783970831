import { addDays, constructNow, isBefore, lastDayOfMonth, parse } from 'date-fns';

export const isExpired = (card: Card) => {
  let expMonth = card.expirationMonth;
  if (card.expirationMonth.length < 2) {
    expMonth = `0${card.expirationMonth}`;
  }
  const lastDayofMonth = lastDayOfMonth(parse(`${expMonth}${card.expirationYear}`, 'MMyy', new Date()));
  const expirationDate = addDays(lastDayofMonth, 1);
  const now = constructNow(new Date());
  return isBefore(expirationDate, now);
};
