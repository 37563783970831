/* eslint-disable react/no-children-prop */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, NavLink, Route } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import ooeConstants from '../../constants';
import { logPageView } from '../../services/analytics';
import Icon from '../Icon';
import Tab from './Tab';

export const MoreTab = (props) => {
  const { icon, to, activeOnlyWhenExact, title, location } = props;

  const [showDropDown, setShowDropDown] = useState(false);

  const emailDetails = ooeConstants.MAILTO_STRING(location);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const onCloseHandler = () => {
    setShowDropDown(false);
  };

  const renderDropDown = () => (
    <div className={showDropDown ? 'drop-down expand' : 'drop-down'}>
      <ul className="options">
        <li>
          <NavLink to={{ pathname: '/more/dashboard', title: 'Customer Dashboard' }}>
            <div className="link">Customer Dashboard</div>
          </NavLink>
        </li>
        <div className="separator" />
        <li onClick={onCloseHandler} role="presentation">
          <a
            href={ooeConstants.HELPFUL_LINKS.NEED_HELP}
            id="linkNeedHelp"
            onClick={() => logPageView('Need Help')}
            rel="noopener noreferrer"
            target="_blank"
          >
            Need Help?
          </a>
        </li>
        <div className="separator" />
        <div>
          <div className="links">Helpful Links:</div>
          <li onClick={onCloseHandler} role="presentation">
            <a
              href={ooeConstants.HELPFUL_LINKS.ABOUT_CMT}
              id="linkAboutCmt"
              onClick={() => logPageView('About CMT')}
              rel="noopener noreferrer"
              target="_blank"
            >
              About CMT
            </a>
          </li>
          <li onClick={onCloseHandler} role="presentation">
            <a
              href={ooeConstants.HELPFUL_LINKS.USER_GUIDE}
              id="linkUserGuide"
              onClick={() => logPageView('User Guide')}
              rel="noopener noreferrer"
              target="_blank"
            >
              User Guide
            </a>
          </li>
          <li onClick={onCloseHandler} role="presentation">
            <a
              href={ooeConstants.HELPFUL_LINKS.FAQ}
              id="linkFAQ"
              onClick={() => logPageView('Frequently Asked Questions')}
              rel="noopener noreferrer"
              target="_blank"
            >
              Frequently Asked Questions
            </a>
          </li>
          <li onClick={onCloseHandler} role="presentation">
            <a
              href={emailDetails}
              id="linkFeedback"
              onClick={() => logPageView('Give Feedback')}
              rel="noopener noreferrer"
            >
              Give Feedback
            </a>
          </li>
        </div>
      </ul>
      <div className="under-mask" onClick={onCloseHandler} role="presentation" />
    </div>
  );

  return (
    <StyledMoreTab>
      <div className="desktop-tab" onClick={toggleDropDown} role="presentation">
        <Tab>
          <div className="more">
            More
            <Icon className="down">down</Icon>
          </div>
        </Tab>
        {showDropDown && renderDropDown()}
      </div>
      <Route
        children={({ match }) => (
          <Link className="mobile-tab" to={to}>
            <Tab className="more-tab" selected={match}>
              <div className="icon">{icon}</div>
              {title}
            </Tab>
          </Link>
        )}
        exact={activeOnlyWhenExact}
        path={to}
      />
    </StyledMoreTab>
  );
};

const slideDown = keyframes`
  0% { transform: translateY(-100%); visibility: visible; }
  100% { transform: none; }
`;

const StyledMoreTab = styled.div`
  flex: 1;

  & .desktop-tab {
    height: 100%;
    width: 118px;

    @media (max-width: ${(props) => props.theme.desktop}) {
      width: 80px;
    }

    @media (max-width: ${(props) => props.theme.phone}) {
      display: none;
    }
  }

  & .link {
    font-weight: bold;
  }

  & .icon svg {
    margin: 8px 0 0;
    max-width: 35px;
    max-height: 35px;
  }

  & .desktop-tab:hover svg,
  .desktop-tab:hover button {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    fill: ${(props) => props.theme.colors.primary};
  }

  & .drop-down {
    will-change: transform;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 200ms;
  }

  .drop-down.expand {
    top: 70px;
    width: 200px;
    animation-name: ${slideDown};
  }

  & .drop-down ul {
    list-style-type: none;
    background-color: ${(props) => props.theme.colors.background};
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
    text-align: left;
  }

  & .drop-down li {
    padding: 0;
    margin: 14px;
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
  }

  & .drop-down a {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
  }

  & .drop-down li:hover,
  .drop-down a:hover {
    cursor: pointer;
    font: ${(props) => props.theme.regularBoldFont};
    color: ${(props) => props.theme.colors.primary};
  }

  & .more {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .down {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};

    @media (max-width: ${(props) => props.theme.phone}) {
      display: none;
    }
  }

  & .separator {
    border-bottom: 1px solid ${(props) => props.theme.colors.outline} !important;
    width: calc(100% - 25px);
    margin: 3px auto;
  }

  & .links {
    font-size: 12px;
    margin: 14px;
  }

  & .under-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -32px;
    top: -75px;
    z-index: -1;
    transform: translateX(-50%);
  }

  & .mobile-tab {
    display: initial;

    @media (min-width: ${(props) => props.theme.phone}) {
      display: none;
    }
  }
`;

MoreTab.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
  location: PropTypes.string,
};

MoreTab.defaultProps = {
  title: '',
  to: '',
  activeOnlyWhenExact: false,
  icon: null,
  location: '',
};

export default MoreTab;
