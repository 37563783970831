import { Lock } from '@cfa/system-icons';
import { Badge, Input } from '@cfacorp/cowponents';
import { contains } from 'ramda';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import ooeConstants from '../../../constants';
import { selectCartWithPrices } from '../../../reducers';
import { MenuItem } from '../../../types/menu';
import { ensureValueIsWholeNumber } from '../../../util/validate';
import { actions as cartActions } from '../../reducer';

interface Props {
  className?: string;
  cartItemId: string;
  item: ReturnType<typeof selectCartWithPrices>[number]['items'][number];
}

const Modifier: React.FC<Props> = ({ className = 'modifier', cartItemId, item }) => {
  const dispatch = useDispatch();

  const {
    name,
    desktopImage,
    mobileImage,
    pdpImages,
    leadTime,
    disabled,
    quantity,
    modifierType,
    tag,
    comboTag,
    itemGroupType,
  } = item;

  const maxQuantity = 999;
  const toggleable = contains(tag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
  const recipe = modifierType === 'RECIPE';

  let modifierClassName = '';
  if (toggleable || (recipe && typeof quantity === 'number' && quantity > 0)) {
    modifierClassName += 'toggleable';
  }
  if ((toggleable && typeof quantity === 'number' && quantity > 0) || (recipe && quantity === 0)) {
    modifierClassName += ' selected';
  }

  const imgUrl = desktopImage || pdpImages || mobileImage;

  const handleQuantityChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = ensureValueIsWholeNumber(e.target.value);
    const qty = value > maxQuantity ? maxQuantity : value;
    dispatch(
      cartActions.updateModifierQuantity(cartItemId, item as CartItemModifier, qty, {
        comboTag,
      }),
    );
  };

  const onAddToCart = () => {
    if (disabled) {
      return;
    }
    dispatch(cartActions.addModifier(cartItemId, item as MenuItem, { comboTag }));
  };

  return (
    <StyledModifier className={className}>
      <div className={modifierClassName} onClick={onAddToCart} onKeyDown={() => {}} role="presentation">
        <div className="image-wrapper">
          {toggleable ? (
            <img
              alt={name}
              className={disabled ? 'image disabled' : 'image'}
              data-cy={`${itemGroupType}-${tag}`}
              src={imgUrl}
            />
          ) : (
            <Badge badgeContent={quantity} className="badge" data-cy={`${itemGroupType}-${tag}-badge`}>
              <img
                alt={name}
                className={disabled ? 'image disabled' : 'image'}
                data-cy={`${itemGroupType}-${tag}`}
                src={imgUrl}
              />
            </Badge>
          )}
          {disabled && <Lock className="lock-icon" color="#DD0031" size="24px" />}
        </div>
        <div className={disabled ? 'name-wrapper disabled' : 'name-wrapper'}>
          <div className="name">{name}</div>
        </div>
        {leadTime && (
          <div className="leadtime-details">
            <Icon height="17" margin="0 2px 2px 0" width="17">
              warning
            </Icon>
            <div className="leadTime">{`Lead time: ${leadTime}h`}</div>
          </div>
        )}
      </div>
      <Input
        data-cy={`quantity-${tag}`}
        display="block"
        height="39px"
        m="5px auto 0"
        max={maxQuantity}
        maxWidth="55px"
        min={0}
        onChange={handleQuantityChange}
        pl="10px"
        placeholder="0"
        textAlign="center"
        type="number"
        value={quantity}
        width="calc(100% - 35px)"
      />
    </StyledModifier>
  );
};

const StyledModifier = styled.div`
  flex: 0 1 24.5%;
  height: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & .image-wrapper,
  .name-wrapper {
    margin: 0 auto;
    text-align: center;
  }

  & .name-wrapper {
    min-height: 30px;
    display: flex;
    justify-content: center;

    &.disabled {
      color: #a7acaf;
    }
  }

  & .image-wrapper {
    position: relative;
    height: 75px;
    width: 75px;
  }

  & .lock-icon {
    position: absolute;
    float: right;
  }

  & .image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;

    &.disabled {
      opacity: 0.5;
    }
  }

  & .name {
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
  }

  & .toggleable {
    border: 1px solid gainsboro;
    margin: 0 5px 0 10px;
    padding: 5px;
  }

  & .selected {
    border: 1px solid ${(props) => props.theme.colors.accent} !important;
    box-shadow: 0 0 5px 1px gainsboro;
  }

  & .leadtime-details {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .cfa-badge {
    height: 22px;
    width: 22px;
    border-radius: 50%;
  }

  @media (max-width: ${(props) => props.theme.small}) {
    flex: 0 1 50%;
  }

  @media (min-width: ${(props) => props.theme.phone}) {
    flex: 0 1 25%;

    & .toggleable:hover {
      border: 1px solid ${(props) => props.theme.colors.accent} !important;
      box-shadow: 0 0 5px 1px gainsboro;
    }
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
    flex: 0 1 20%;
  }

  @media (min-width: ${(props) => props.theme.desktop}) {
    flex: 0 1 20%;
  }
`;

export default memo(Modifier);
