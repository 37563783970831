import { Box, Flex } from '@cfacorp/cowponents';
import { parseISO } from 'date-fns';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Tooltip from 'src/components/Tooltip';
import ooeConstants from 'src/constants';
import { selectOrderToView } from 'src/dashboard/selectors';
import { formatPhoneNumber } from 'src/reducers/form';
import { selectLocationContactDetails } from 'src/reducers/user';
import { OrderDeliveryAddress } from 'src/types/order';
import { formatEventSummaryDate, formatEventSummaryTime, formatPaper } from 'src/util/format';
import { specialInstrHasPaperGoods } from 'src/util/utils';

interface EventSummaryProps {
  orderDetails: ReturnType<typeof selectOrderToView>;
}

const EventSummary: React.FC<EventSummaryProps> = ({ orderDetails }) => {
  const { restaurantName, restaurantAddress1, restaurantAddress2, restaurantPhone } = useSelector(
    selectLocationContactDetails,
  );

  const {
    cateringReason,
    deliveryAddress,
    companyName,
    destination,
    guestCount,
    time,
    paperGoods,
    specialInstructions,
    promiseDateTime,
    email,
    phone,
    givenName,
    familyName,
    secondaryContact,
    transId,
    clientId,
  } = orderDetails;
  const isPOSOrder = clientId === 'POS';
  const { firstName, lastName, phoneNumber } = secondaryContact ?? {};
  const { DELIVERY, PICKUP } = ooeConstants;
  const specialInstructionFromAPI = specialInstrHasPaperGoods(specialInstructions)
    ? specialInstructions
    : `${formatPaper(paperGoods ?? false)}${specialInstructions ?? ''}`;

  const specialInstructionBox = () => {
    if (isPOSOrder) {
      return (
        <Box className="specialInstructionsSummary">
          {specialInstructions || formatPaper(paperGoods ?? false)}
        </Box>
      );
    } else {
      return <Box className="specialInstructionsSummary">{specialInstructionFromAPI}</Box>;
    }
  };

  const deliveryAddressBox = () => {
    if (isPOSOrder) {
      return (
        <div>
          <Box fontWeight="bold" lineHeight="27px">
            Delivery Address
          </Box>
          <div className="pos-del-address">{deliveryAddress as string}</div>
        </div>
      );
    } else {
      const { addressLine1, addressLine2, city, state, zip } = deliveryAddress as OrderDeliveryAddress;
      return (
        <div>
          <Box fontWeight="bold" lineHeight="27px">
            Delivery Address
          </Box>
          <Box data-cy="guestAddressName">{companyName}</Box>
          <Box data-cy="guestAddressStreet">{addressLine1}</Box>
          <Box data-cy="guestAddressLineTwo">{addressLine2}</Box>
          <Box data-cy="guestAddressCityState">
            {city && `${city}, `}
            {state && zip && `${state} ${zip}`}
          </Box>
        </div>
      );
    }
  };

  return (
    <StyledEventSummary className="event-summary">
      <Flex lineHeight="27px">
        <Flex className="f-d-column" flexDirection="column" width={[1 / 2, 5 / 12]}>
          <Box fontWeight="bold" lineHeight="27px">
            {destination} Time
          </Box>
          <Box data-cy="eventDate">{formatEventSummaryDate(parseISO(promiseDateTime))}</Box>
          {time && <Box data-cy="eventTime">{formatEventSummaryTime(time, destination === DELIVERY)}</Box>}
          <br />
          {destination === DELIVERY && deliveryAddressBox()}
          {destination === PICKUP && (
            <div className="retaurant-details">
              <Box fontWeight="bold" lineHeight="27px">
                Pickup Restaurant
              </Box>
              <Box data-cy="restaurantName">{restaurantName}</Box>
              <Box data-cy="restaurantAddress">{restaurantAddress1}</Box>
              <Box data-cy="restaurantCityStateZip">{restaurantAddress2}</Box>
              <Box data-cy="restaurantPhone">{restaurantPhone}</Box>
            </div>
          )}
          {transId && (
            <div>
              <Box fontWeight="bold" lineHeight="27px">
                TransactionID
              </Box>
              <Tooltip
                className="transId-tooltip"
                tooltipContent={
                  <div>
                    <span className="title">Transaction ID:</span>
                    <span> Year + Month + Day + Store Num + Transaction Number</span>
                  </div>
                }
              >
                <Box className="trans-id" data-cy="trans-id">
                  {transId}
                </Box>
              </Tooltip>
            </div>
          )}
          {typeof secondaryContact === 'object' && !isEmpty(secondaryContact) && (
            <div className="secondary-contact-details">
              <br />
              <Box fontWeight="bold" lineHeight="27px">{`Secondary ${destination} POC:`}</Box>
              <Box className="first-and-last-name" lineHeight="27px">
                {firstName} {lastName}
              </Box>
              <Box className="guestPhone secondary-contact-phone">
                {phoneNumber && formatPhoneNumber(phoneNumber)}
              </Box>
            </div>
          )}
        </Flex>
        <Flex
          className="f-d-column"
          flexDirection="column"
          justifyContent={['space-between', 'initial']}
          width={[1 / 2, 7 / 12]}
        >
          <Box fontWeight="bold" lineHeight="27px">
            Primary Contact
          </Box>
          <Box data-cy="guestSummaryFullName" lineHeight="27px">
            {givenName} {familyName}
          </Box>
          {!isPOSOrder && (
            <Box className="email" data-cy="guestSummaryEmail">
              {email}
            </Box>
          )}
          <Box className="guestPhone" data-cy="guestSummaryPhone">
            {isPOSOrder ? phone : phone && formatPhoneNumber(phone)}
          </Box>
          <br />
          <Box data-cy="guestCountSummary" lineHeight="27px">
            <span className="bold">Guest count: </span>
            {guestCount}
          </Box>
          <Box fontWeight="bold" lineHeight="27px">
            Special Instructions:
          </Box>
          {specialInstructionBox()}
          <br />
          {cateringReason && (
            <div className="catering-reason">
              <Box fontWeight="bold" lineHeight="27px">
                Catering Occasion / Reason:
              </Box>
              <Box>{cateringReason}</Box>
            </div>
          )}
        </Flex>
      </Flex>
    </StyledEventSummary>
  );
};

const StyledEventSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .bold {
    font: ${(props) => props.theme.regularBoldFont};
  }

  & .event-summary {
    margin: 20px 0 40px;
  }

  & .tooltip-wrapper {
    justify-content: flex-start;

    & .tooltip {
      top: 28px;
      width: max-content;
      max-width: 80vw;

      .title {
        font-weight: bold;
      }
    }
  }
`;

export default EventSummary;
