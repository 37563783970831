import type { Action, ActionCreator } from '@reduxjs/toolkit';
import { startOfDay, subDays } from 'date-fns';
import { Epic, ofType } from 'redux-observable';
import { debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { selectIsPOSOrder, selectLastEndDate, selectSpecificDays } from 'src/dashboard/selectors';
import {
  cancelOrderAdminApi,
  getAdditionalPastOrdersFromApi,
  getRecentAndUpcomingOrdersFromApi,
  lookupCustomerDetailsFromCustomerID,
  lookupOrderDetailsFromApi,
  lookupPOSOrderDetails,
  resendPaymentEmail,
} from '../apis/dashboardApi';
import ooeConstants from '../constants';
import type { State } from '../reducers';
import { actions as dashboardActions, types as dashboardTypes } from '../reducers/dashboard';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import {
  selectAccessToken,
  selectLocationNumber,
  actions as userActions,
  types as userTypes,
} from '../reducers/user';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import type { ApiCall } from '../util/requestFactory';

export const GetOrders: Epic<
  | ReturnType<(typeof dashboardActions)['getOrders']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrders']>
  | ReturnType<(typeof dashboardActions)['ordersSuccess']>
  | ReturnType<(typeof dashboardActions)['ordersFailure']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrdersSuccess']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrdersFailure']>,
  | ReturnType<(typeof dashboardActions)['getOrders']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrders']>
  | ReturnType<(typeof dashboardActions)['ordersSuccess']>
  | ReturnType<(typeof dashboardActions)['ordersFailure']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrdersSuccess']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrdersFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(
      dashboardTypes.GET_ORDERS,
      userTypes.UPDATE_USER_LOCATION,
      userTypes.GET_USER_LOCATIONS_SUCCESS,
      orderTypes.SUBMIT_ORDER_SUCCESS,
      dashboardTypes.LOAD_MORE_PAST_ORDERS,
    ),
    debounceTime(ooeConstants.DXE_DASHBOARD_DELAY_TIME),
    switchMap((action) => {
      const { type } = action;
      const state = store.value;
      const locationId = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      const numberOfPreviousDays = ooeConstants.NUM_ADDTL_DASHBOARD_PAST_ORDERS_TO_LOAD;

      let successAction: ActionCreator<Action> = dashboardActions.ordersSuccess;
      let failureAction: ActionCreator<Action> = dashboardActions.ordersFailure;
      let apiCall = getRecentAndUpcomingOrdersFromApi(locationId, accessToken, numberOfPreviousDays);

      if (type === dashboardTypes.LOAD_MORE_PAST_ORDERS) {
        const specificDays = selectSpecificDays(state);
        const lastEndDate = specificDays.startDate ?? selectLastEndDate(state);
        const endDateStartOfDay = startOfDay(lastEndDate ?? new Date());
        const startDate = subDays(endDateStartOfDay, numberOfPreviousDays);
        apiCall = getAdditionalPastOrdersFromApi(locationId, accessToken, startDate, endDateStartOfDay);
        successAction = dashboardActions.loadMorePastOrdersSuccess(startDate);
        failureAction = dashboardActions.loadMorePastOrdersFailure;
      }

      return epicHelper(apiCall, successAction, failureAction, epicOptions(store, action$));
    }),
  );

export const GetOrdersForSpecificDays: Epic<
  | ReturnType<(typeof dashboardActions)['getOrdersForSpecificDays']>
  | ReturnType<(typeof dashboardActions)['ordersForSpecificDaysSuccess']>
  | ReturnType<(typeof dashboardActions)['ordersForSpecificDaysFailure']>,
  | ReturnType<(typeof dashboardActions)['getOrdersForSpecificDays']>
  | ReturnType<(typeof dashboardActions)['ordersForSpecificDaysSuccess']>
  | ReturnType<(typeof dashboardActions)['ordersForSpecificDaysFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.GET_ORDERS_FOR_SPECIFIC_DAYS),
    switchMap((action) => {
      const { startDate, endDate } = action as ReturnType<
        (typeof dashboardActions)['getOrdersForSpecificDays']
      >;
      const state = store.value;
      const locationId = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getAdditionalPastOrdersFromApi(locationId, accessToken, startDate, endDate),
        dashboardActions.ordersForSpecificDaysSuccess,
        dashboardActions.ordersForSpecificDaysFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetDataForExcelExtraction: Epic<
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtraction']>
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtractionSuccess']>
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtractionFailure']>,
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtraction']>
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtractionSuccess']>
  | ReturnType<(typeof dashboardActions)['getDataForExcelExtractionFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.GET_DATA_FOR_EXCEL_EXTRACTION),
    mergeMap((action) => {
      const { order } = action as ReturnType<(typeof dashboardActions)['getDataForExcelExtraction']>;
      const state = store.value;
      const locationNumber = selectLocationNumber(state);
      const accessToken = selectAccessToken(state);
      const getOrderDetails$: ApiCall<JSONObject> =
        order.clientId === 'POS'
          ? lookupPOSOrderDetails(order.transId, locationNumber, accessToken)
          : lookupOrderDetailsFromApi(order.id, locationNumber, order.cfaId || '', accessToken);
      return epicHelper(
        getOrderDetails$,
        dashboardActions.getDataForExcelExtractionSuccess(order),
        dashboardActions.getDataForExcelExtractionFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const CancelOrder: Epic<
  | ReturnType<(typeof dashboardActions)['cancelOrder']>
  | ReturnType<(typeof dashboardActions)['cancelOrderSuccess']>
  | ReturnType<(typeof dashboardActions)['cancelOrderFailure']>,
  | ReturnType<(typeof dashboardActions)['cancelOrder']>
  | ReturnType<(typeof dashboardActions)['cancelOrderSuccess']>
  | ReturnType<(typeof dashboardActions)['cancelOrderFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.CANCEL_ORDER),
    switchMap((action) => {
      const { orderId, cfaId } = action as ReturnType<(typeof dashboardActions)['cancelOrder']>;
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        cancelOrderAdminApi(orderId, locationNumber, cfaId, accessToken),
        dashboardActions.cancelOrderSuccess.bind(null, orderId),
        dashboardActions.cancelOrderFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupOrderDetails: Epic<
  | ReturnType<(typeof dashboardActions)['lookupOrderDetails']>
  | ReturnType<(typeof dashboardActions)['lookupCustomerDetailsSuccess']>
  | ReturnType<(typeof dashboardActions)['lookupOrderDetailsFailure']>,
  | ReturnType<(typeof dashboardActions)['lookupOrderDetails']>
  | ReturnType<(typeof dashboardActions)['lookupCustomerDetailsSuccess']>
  | ReturnType<(typeof dashboardActions)['lookupOrderDetailsFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.LOOKUP_ORDER_DETAILS),
    switchMap((action) => {
      const { orderId, cfaId } = action as ReturnType<(typeof dashboardActions)['lookupOrderDetails']>;
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      const isPOSOrder = selectIsPOSOrder(state);
      if (isPOSOrder) {
        return epicHelper(
          lookupPOSOrderDetails(orderId, locationNumber, accessToken),
          dashboardActions.lookupOrderDetailsSuccess,
          dashboardActions.lookupOrderDetailsFailure,
          epicOptions(store, action$),
        );
      }
      return epicHelper(
        lookupOrderDetailsFromApi(orderId, locationNumber, cfaId, accessToken),
        dashboardActions.lookupOrderDetailsSuccess,
        dashboardActions.lookupOrderDetailsFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupCustomerDetails: Epic<
  | ReturnType<(typeof dashboardActions)['lookupOrderDetails']>
  | ReturnType<(typeof dashboardActions)['lookupCustomerDetailsSuccess']>
  | ReturnType<(typeof dashboardActions)['lookupOrderDetailsFailure']>,
  | ReturnType<(typeof dashboardActions)['lookupOrderDetails']>
  | ReturnType<(typeof dashboardActions)['lookupCustomerDetailsSuccess']>
  | ReturnType<(typeof dashboardActions)['lookupOrderDetailsFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.LOOKUP_CUSTOMER_DETAILS),
    switchMap((action) => {
      const { orderId, cfaId } = action as ReturnType<(typeof dashboardActions)['lookupOrderDetails']>;
      const state = store.value;
      const accessToken = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        lookupCustomerDetailsFromCustomerID(orderId, locationNumber, cfaId, accessToken),
        dashboardActions.lookupCustomerDetailsSuccess,
        dashboardActions.lookupCustomerDetailsFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ResendPaymentEmail: Epic<
  | ReturnType<(typeof dashboardActions)['resendPaymentEmail']>
  | ReturnType<(typeof dashboardActions)['resendPaymentEmailSuccess']>
  | ReturnType<(typeof dashboardActions)['resendPaymentEmailFailure']>,
  | ReturnType<(typeof dashboardActions)['resendPaymentEmail']>
  | ReturnType<(typeof dashboardActions)['resendPaymentEmailSuccess']>
  | ReturnType<(typeof dashboardActions)['resendPaymentEmailFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(dashboardTypes.RESEND_PAYMENT_EMAIL),
    switchMap((action) => {
      const { orderId, cfaId } = action as ReturnType<(typeof dashboardActions)['resendPaymentEmail']>;
      const state = store.value;
      const apiKey = selectAccessToken(state);
      const locationNumber = selectLocationNumber(state);
      return epicHelper(
        resendPaymentEmail(apiKey, orderId, locationNumber, cfaId),
        dashboardActions.resendPaymentEmailSuccess,
        dashboardActions.resendPaymentEmailFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  GetOrders,
  CancelOrder,
  LookupOrderDetails,
  LookupCustomerDetails,
  ResendPaymentEmail,
  GetOrdersForSpecificDays,
  GetDataForExcelExtraction,
];
