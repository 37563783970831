import { Box, Flex } from '@cfacorp/cowponents';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import ValidatedField from '../../../components/ValidatedField/ValidatedField';
import constants from '../../../constants';
import { actions as formActions, selectSecondaryContactValues } from '../../../reducers/form';
import normalizePhone from '../../../util/normalizePhone';
import { stripNonPOSSpecialCharacters } from '../../../util/utils';
import { validateSecondaryContact } from '../../utils';

interface Values {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const SecondaryContactField: React.FC = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, phoneNumber } = useSelector(selectSecondaryContactValues);
  const handleAdd = () => {
    if (
      firstName !== '' &&
      firstName !== undefined &&
      lastName !== '' &&
      lastName !== undefined &&
      phoneNumber !== '' &&
      phoneNumber !== undefined
    ) {
      dispatch(formActions.addSecondaryContact());
    }
  };
  return (
    <Box>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 3]}>
          <Field
            className="first-name"
            component={ValidatedField}
            name="firstName"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={handleAdd}
            placeholder="First Name"
            requiredWithoutTouch
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            className="last-name"
            component={ValidatedField}
            name="lastName"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={handleAdd}
            placeholder="Last Name"
            requiredWithoutTouch
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            className="phone-number"
            component={ValidatedField}
            name="phoneNumber"
            normalize={normalizePhone}
            onBlur={handleAdd}
            placeholder="Phone Number"
            requiredWithoutTouch
            type="text"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default reduxForm<Values>({
  form: constants.GET_FORM_TYPES.SECONDARY_CONTACT,
  destroyOnUnmount: false,
  validate: validateSecondaryContact,
})(SecondaryContactField);
