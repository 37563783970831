import ReactExport from '@decafdev/react-export-excel';

import { selectOrderToView } from 'src/dashboard/selectors';
import { selectLocationContactDetails } from 'src/reducers/user';
import { OrderDeliveryAddress } from 'src/types/order';
import DashboardActionButton from '../../dashboard/components/DashboardActions/DashboardActionButton';
import { formatPhoneNumber } from '../../reducers/form';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

interface OrderExportExcelProps {
  orderDetails: ReturnType<typeof selectOrderToView>;
  restaurantContactDetails: ReturnType<typeof selectLocationContactDetails>;
}

const OrderExportExcel: React.FC<OrderExportExcelProps> = ({ orderDetails, restaurantContactDetails }) => {
  const { lineItems } = orderDetails;
  const { customerName } = orderDetails;
  let sc = '';
  if (orderDetails.secondaryContact) {
    sc = `${orderDetails.secondaryContact.firstName} ${orderDetails.secondaryContact.lastName} ${formatPhoneNumber(orderDetails.secondaryContact.phoneNumber || '')}`;
  }
  const dataSet1 = [{ ...orderDetails, secondaryContact: sc }];
  const dataSet2 = lineItems?.map((i) => {
    let mod = '';
    let combo = '';
    if (i.modifiers) {
      mod = i.modifiers.map((m) => `${m.name} ${m.quantity} $${m.price}`).join('; ');
    }
    if (i.comboItems) {
      combo = i.comboItems.map((c) => `${c.name} $${c.priceAdjustment}`).join('; ');
    }

    return {
      ...i,
      modifiers: mod,
      comboItems: combo,
    };
  });

  return (
    <ExcelFile
      element={<DashboardActionButton buttonText="Extract to Excel" icon="download-excel" />}
      filename={customerName}
      testingData1={dataSet1}
      testingData2={dataSet2}
    >
      <ExcelSheet data={dataSet1} name="Order Details">
        <ExcelColumn label="Date and Time" value="displayLongDate" />
        <ExcelColumn label="Primary Contact Name" value="customerName" />
        <ExcelColumn label="CFA Loyalty" value="cfaLoyalty" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Primary Contact Phone" value="phone" />
        <ExcelColumn label="Location Number" value="locationNumber" />
        <ExcelColumn label="Destination" value="destination" />
        {orderDetails.destination === 'Delivery' ? (
          <ExcelColumn
            label="Delivery Address"
            value={(col: { deliveryAddress: OrderDeliveryAddress }) =>
              `${col.deliveryAddress.addressLine1} ${col.deliveryAddress.addressLine2 || ''} ${col.deliveryAddress.addressLine3 || ''} ${col.deliveryAddress.city} ${col.deliveryAddress.county} ${col.deliveryAddress.state} ${col.deliveryAddress.zip} ${col.deliveryAddress.zipExtension || ''} `
            }
          />
        ) : (
          <ExcelColumn
            label="Pickup Restaurant"
            value={() =>
              `${restaurantContactDetails.restaurantName} ${restaurantContactDetails.restaurantAddress1} ${restaurantContactDetails.restaurantAddress2} phone:${restaurantContactDetails.restaurantPhone}`
            }
          />
        )}
        <ExcelColumn label="Client" value="displayClient" />
        <ExcelColumn label="Date and Time" value="displayLongDate" />
        <ExcelColumn label="Subtotal Price" value="displaySubtotal" />
        <ExcelColumn label="Total Price" value="displayPrice" />
        <ExcelColumn label="TransactionID" value="transId" />
        <ExcelColumn
          label="Status"
          value={(col: (typeof dataSet1)[number]) => col.displayStatus?.statusName}
        />
        <ExcelColumn label="Special Instructions" value="specialInstructions" />
        <ExcelColumn label="Phone" value="phone" />
        <ExcelColumn label="Catering Reason" value="cateringReason" />
        <ExcelColumn label="Secondary POC" value="secondaryContact" />
      </ExcelSheet>
      <ExcelSheet data={dataSet2} name="Order Summary">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Combo Items" value="comboItems" />
        <ExcelColumn label="Quantity" value="quantity" />
        <ExcelColumn label="Retail Price" value="retailPrice" />
        <ExcelColumn label="Price" value="price" />
        <ExcelColumn label="Modifiers" value="modifiers" />
        <ExcelColumn label="Special Instruction" value="specialInstructions" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default OrderExportExcel;
