import { format, parseISO, startOfDay } from 'date-fns';
import { change, destroy, FormAction, initialize } from 'redux-form';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, ignoreElements, mapTo, switchMap, take, tap } from 'rxjs/operators';

import type { UnknownAction } from '@reduxjs/toolkit';
import constants from '../constants';
import type { State } from '../reducers';
import { actions as formActions, types as formTypes } from '../reducers/form';
import { types as guestTypes } from '../reducers/guest';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import { selectBypassBusinessRules, actions as userActions, types as userTypes } from '../reducers/user';
import type Order from '../types/order';
import normalizePhone from '../util/normalizePhone';
import ReduxStore from '../util/reduxStore';

export const ResetDateFormValues: Epic<ReturnType<(typeof formActions)['resetDateFormValues']>> = (action$) =>
  action$.pipe(
    ofType(formTypes.RESET_DATE_FORM_VALUES),
    tap(() => {
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.DETAILS, 'date', ''));
    }),
    ignoreElements(),
  );

export const ResetTimeFormValues: Epic<ReturnType<(typeof formActions)['resetTimeFormValues']>> = (action$) =>
  action$.pipe(
    ofType(formTypes.RESET_TIME_FORM_VALUES),
    tap(() => {
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.DETAILS, 'time', ''));
    }),
    ignoreElements(),
  );

export const SetCustomerPhoneNumber: Epic<ReturnType<(typeof formActions)['setCustomerPhoneNumber']>> = (
  action$,
) =>
  action$.pipe(
    ofType(formTypes.SET_CUSTOMER_PHONE_NUMBER),
    tap((action) => {
      const { phoneNumber } = action;
      ReduxStore.dispatch(change(constants.GET_FORM_TYPES.GUEST, 'phone', normalizePhone(phoneNumber)));
    }),
    ignoreElements(),
  );

export const ResetDateTimeForLocationChange: Epic<
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | UnknownAction,
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | UnknownAction,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.CHANGE_DESTINATION, userTypes.UPDATE_USER_LOCATION),
    // Do not reset date and time if user has bypassBusinessRules
    filter(() => {
      const state = store.value;
      return !selectBypassBusinessRules(state);
    }),
    switchMap(() => [formActions.resetTimeFormValues(), formActions.resetDateFormValues()]),
  );

export const ClearTime: Epic<
  ReturnType<(typeof orderActions)['dateChanged']> | ReturnType<(typeof formActions)['resetTimeFormValues']>,
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof formActions)['resetTimeFormValues']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.DATE_CHANGED),
    // Do not clear time slots when date changes if user has bypassBusinessRules
    filter(() => {
      const state = store.value;
      return !selectBypassBusinessRules(state);
    }),
    mapTo(formActions.resetTimeFormValues()),
  );

export const EditOrder: Epic<
  ReturnType<(typeof orderActions)['initiateEditOrder']> | FormAction,
  ReturnType<(typeof orderActions)['initiateEditOrder']> | FormAction
> = (action$) =>
  action$.pipe(
    ofType(orderTypes.INITIATE_EDIT_ORDER),
    // Wait for an API key so that subsequent requests don't fail
    switchMap((action) => {
      const { reorder } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      if (reorder !== true) {
        return action$.pipe(ofType(guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS), take(1), mapTo(action));
      }
      return of(action);
    }),
    switchMap((action) => {
      const {
        guest = {},
        order = {},
        reorder = false,
      } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      const { first, last, phone, email } = guest as GuestDetails;
      const { firstName, lastName } = guest as GuestSearchResult;
      const {
        paperGoods,
        guestCount,
        specialInstructions,
        promiseDateTime,
        payment,
        status,
        deliveryAddress,
        destination,
        secondaryContact,
        cateringReason,
      } = order as Order;
      const showSpecialInstructions = reorder ? '' : specialInstructions;
      const { paymentType } = payment || {};
      let selectedMethod;
      if (status === constants.PAYMENT_PENDING) {
        selectedMethod = constants.REQUEST_PAYMENT;
      } else if (paymentType === constants.GET_PAYMENT_TYPES.ACCOUNT) {
        selectedMethod = constants.CREDIT;
      } else if (paymentType === constants.GET_PAYMENT_TYPES.TO_BE_COLLECTED) {
        selectedMethod = constants.DEFER;
      }
      let date = null;
      let time = null;
      if (!reorder && promiseDateTime) {
        const parsedDateTime = parseISO(promiseDateTime);
        date = startOfDay(parsedDateTime);
        time = format(parsedDateTime, constants.DATE_TIME_FORMAT.time);
      }
      const actionsToDispatch = [
        destroy(
          constants.GET_FORM_TYPES.GUEST,
          constants.GET_FORM_TYPES.DETAILS,
          constants.GET_FORM_TYPES.PAYMENT_METHOD,
        ),
        // Update guest form values
        initialize(
          constants.GET_FORM_TYPES.GUEST,
          {
            email,
            phone,
            first: first || firstName,
            last: last || lastName,
          },
          { updateUnregisteredFields: true },
        ),
        // Update details form values
        initialize(
          constants.GET_FORM_TYPES.DETAILS,
          {
            date,
            time,
            paperGoods,
            guestCount,
            specialInstructions: showSpecialInstructions,
            cateringReason,
          },
          { updateUnregisteredFields: true },
        ),
      ];
      if (secondaryContact) {
        actionsToDispatch.push(
          initialize(
            constants.GET_FORM_TYPES.SECONDARY_CONTACT,
            {
              firstName: secondaryContact?.firstName,
              lastName: secondaryContact?.lastName,
              phoneNumber: secondaryContact?.phoneNumber,
            },
            { updateUnregisteredFields: true },
          ),
        );
      }
      if (!reorder) {
        // Select correct payment method
        actionsToDispatch.push(
          initialize(
            constants.GET_FORM_TYPES.PAYMENT_METHOD,
            {
              selectedMethod,
            },
            { updateUnregisteredFields: true },
          ),
        );
      }
      // Only validate address if destination is Delivery and a delivery Address is available
      if (destination === constants.DELIVERY && deliveryAddress) {
        const { addressLine1, addressLine2, city, state } = deliveryAddress;
        let addressToValidate = `${addressLine1} ${addressLine2} ${city} ${state}`;
        if (addressLine2 === null || addressLine2 === '' || addressLine2 === undefined) {
          addressToValidate = `${addressLine1} ${city} ${state}`;
        }
        actionsToDispatch.push(orderActions.autocompleteAddress(addressToValidate));
      }
      return actionsToDispatch;
    }),
  );

export const DestroyForms: Epic<
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | UnknownAction,
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | UnknownAction
> = (action$) =>
  action$.pipe(
    ofType(orderTypes.EXIT_EDIT_ORDER, orderTypes.SUBMIT_ORDER_SUCCESS),
    switchMap(() => [
      destroy('paymentMethod'),
      destroy('details'),
      destroy('guest'),
      destroy('secondaryContact'),
    ]),
  );

export default [
  ResetDateFormValues,
  ResetTimeFormValues,
  SetCustomerPhoneNumber,
  ResetDateTimeForLocationChange,
  ClearTime,
  EditOrder,
  DestroyForms,
];
