import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface Props {
  tooltipContent: React.ReactNode;
  className?: string;
}

const Tooltip: React.FC<PropsWithChildren<Props>> = ({ children, tooltipContent, className = '' }) => (
  <StyledTooltip className={className}>
    <div className="tooltip-wrapper">
      {children}
      <div className="tooltip">{tooltipContent}</div>
    </div>
  </StyledTooltip>
);

const StyledTooltip = styled.div`
  & .tooltip-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .tooltip {
    box-shadow: 0 1px 22px 2px rgb(214 214 214 / 100%);
  }

  & .tooltip-wrapper .tooltip {
    visibility: hidden;
    background-color: white;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1000;
    top: 5px;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    min-width: 200px;
  }

  & .tooltip-wrapper:hover {
    cursor: pointer;
  }

  & .tooltip-wrapper:hover .tooltip {
    visibility: visible;
  }
`;

export default Tooltip;
