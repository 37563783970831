import { Store } from '@reduxjs/toolkit';
import { path, pick } from 'ramda';

import { selectCart } from '../cart/selectors';
import { selectTimeShouldClear } from '../eventDetails/selectors';
import {
  selectEventDetailsValid,
  selectFormSubmitWarning,
  selectFormSyncErrors,
  selectPaymentSubmitWarning,
} from '../payment/selectors';
import { selectDateIsToday, selectPaymentValid, selectPayWithVaultedCard } from '../reducers';
import { selectDashboard } from '../dashboard/selectors';
import {
  selectCfaId,
  selectDate,
  selectDetailsFormSyncErrors,
  selectDetailsFormValues,
  selectGuestDetailsValid,
  selectGuestFormSyncErrors,
  selectNewPaymentMethod,
  selectTime,
  selectValidEmail,
  selectValidPhone,
} from '../reducers/form';
import { selectMenu } from '../reducers/menu';
import {
  selectLocationAcceptsDelivery,
  selectLocationAcceptsPickup,
  selectLocationContactDetails,
  State,
} from '../reducers/user';
import { maskEmail } from './utils';

const UNAVAILABLE = 'n/a';

function getUserData(state: { user: State }) {
  const { user } = state;
  const userData = pick(
    [
      'selectedLocation',
      'error',
      'loading',
      'shouldBypassBusinessRules',
      'cfa_aud',
      'cfa_guid',
      'cfa_perms',
      'email',
      'accessToken',
      'refreshToken',
      'cmtEula',
    ],
    user,
  );

  return {
    ...userData,
    email: maskEmail(userData.email),
    accessToken: userData.accessToken?.slice?.(-6) ?? UNAVAILABLE,
    refreshToken: userData.refreshToken?.slice?.(-6) ?? UNAVAILABLE,
    locationContactDetails: selectLocationContactDetails(state) ?? UNAVAILABLE,
    locationAcceptsDelivery: selectLocationAcceptsDelivery(state) ?? UNAVAILABLE,
    locationAcceptsPickup: selectLocationAcceptsPickup(state) ?? UNAVAILABLE,
  };
}

function getOrderData(state: any) {
  const { order } = state;
  const orderData = pick(
    [
      'id',
      'editOrderId',
      'reorder',
      'error',
      'loading',
      'status',
      'orderType',
      'reorder',
      'destination',
      'taxAmount',
      'subTotalAmount',
      'promiseDateTime',
      'companyName',
    ],
    order,
  );
  return orderData;
}

function getGuestData(state: any) {
  const { guest } = state;

  const guestData = {
    cfaOneId: selectCfaId(state),
    email: maskEmail(path(['selectedCfaOneGuest', 'email'], guest)) ?? UNAVAILABLE,
    error: guest.error,
    guestSession: {
      accessTokenSuffix: state.guest?.guestSession?.accessToken?.slice(-6) ?? UNAVAILABLE,
      refreshTokenSuffix: state.guest?.guestSession?.refreshToken?.slice(-6) ?? UNAVAILABLE,
      expiresIn: state.guest?.guestSession?.expiresIn,
    },
    masqueradeSession: {
      accessTokenSuffix: state.guest?.masqueradeSession?.accessToken?.slice(-6) ?? UNAVAILABLE,
      refreshTokenSuffix: state.guest?.masqueradeSession?.refreshToken?.slice(-6) ?? UNAVAILABLE,
      expiresIn: state.guest?.masqueradeSession?.expiresIn,
    },
    vaultedCardsCount: guest.vaultedCards?.length,
    selectedCard: {
      type: guest.selectedCard?.type,
      cardTtype: guest.selectedCard?.cardType,
      account: guest.selectedCard?.accountDisplay,
      validated: guest.selectedCard?.validated,
      countryCode: guest.selectedCard?.countryCode,
    },
  };
  return guestData;
}

function getCartData(state: any) {
  return selectCart(state);
}

function getDashboardData(state: any) {
  const dashboard = selectDashboard(state);
  return {
    loading: dashboard.loading,
    error: dashboard.error,
  };
}

function getMenuData(state: any) {
  const menu = selectMenu(state);
  return {
    loading: menu.isLoading,
    error: menu.error,
  };
}

const getFormData = (state: any) => {
  try {
    return {
      cfaId: selectCfaId(state),
      general: {
        formSyncErrors: selectFormSyncErrors(state),
        formSubmitWarning: selectFormSubmitWarning(state),
      },
      eventDetails: {
        eventDetailsValid: selectEventDetailsValid(state),
        detailsFormValues: selectDetailsFormValues(state),
        timeShouldClear: selectTimeShouldClear(state),
        dateIsToday: selectDateIsToday(state),
        date: selectDate(state),
        time: selectTime(state),
        detailsFormSyncErrors: selectDetailsFormSyncErrors(state),
      },
      guestDetails: {
        guestDetailsValid: selectGuestDetailsValid(state),
        validEmail: selectValidEmail(state),
        validPhone: selectValidPhone(state),
        guestFormSyncErrors: selectGuestFormSyncErrors(state),
      },
      payment: {
        paymentSubmitWarning: selectPaymentSubmitWarning(state),
        payWithVaultedCard: selectPayWithVaultedCard(state),
        newPaymentMethod: selectNewPaymentMethod(state),
        paymentValid: selectPaymentValid(state),
        paymentMethod: state.form?.paymentMethod?.values?.paymentMethod,
        selectedMethod: state.form?.paymentMethod?.values?.selectedMethod,
        syncErrors: state.form?.syncErrors,
      },
    };
  } catch (error) {
    return {
      message: 'Unable to get form data',
      error,
    };
  }
};

export default function getBugSnagStateData(store: Store) {
  try {
    const state = store.getState();
    return {
      guest: getGuestData(state),
      user: getUserData(state),
      cart: getCartData(state),
      order: getOrderData(state),
      menu: getMenuData(state),
      dashboard: getDashboardData(state),
      formData: getFormData(state),
    };
  } catch (error) {
    return {
      message: 'Unable to get session metadata',
      error,
    };
  }
}
