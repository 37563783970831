import React from 'react';
import { useSelector } from 'react-redux';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';

import { selectSearchResults } from '../reducers/guest';
import EmptyCfaPhoneFieldWarningModal from './components/EmptyCfaPhoneFieldWarningModal';
import GuestForm from './components/GuestForm';
import SuggestedCustomer from './components/SuggestedCustomer';

const Guest: React.FC = () => {
  const customers = useSelector(selectSearchResults);

  return (
    <>
      <Section>
        <SectionHeader>Guest Information</SectionHeader>
        <GuestForm />
      </Section>
      <EmptyCfaPhoneFieldWarningModal />
      {customers.length > 0 && (
        <Section>
          <SectionHeader>Suggested Customers</SectionHeader>
          {customers.map((customer) => (
            <SuggestedCustomer customer={customer} key={customer.cfaId} />
          ))}
        </Section>
      )}
    </>
  );
};

export default Guest;
