import { LoadingIndicator } from '@cfa/react-components';
import styled from 'styled-components';

interface InitialLoadProps {
  className?: string;
}

const InitialLoad: React.FC<InitialLoadProps> = ({ className }) => {
  return (
    <div className={className}>
      <h3>Catering Management Tool</h3>
      <LoadingIndicator data-testid="cfa-spinner" size="md" variant="page" />
    </div>
  );
};

const StyledInitialLoad = styled(InitialLoad)`
  font-family: ${(props) => props.theme.fontFamily};
  margin-top: 20vh;
  margin-bottom: 7vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default StyledInitialLoad;
