import { Button } from '@cfa/react-components';
import { Box, Heading, Text } from '@cfacorp/cowponents';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import OverlayModal from '../../../components/OverlayModal';

interface Props {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ItemWarningModal: React.FC<Props> = ({ isOpen, message, onClose, onConfirm }) => {
  return (
    <StyledItemWarningModal close={onClose} isOpen={isOpen}>
      <Close onClick={onClose}>
        <Icon>close</Icon>
      </Close>
      <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" m="3em">
        <Icon>warning</Icon>
        <Heading as="h1" fontSize="5" m="1em">
          Would you still like to order it?
        </Heading>
        <Text color="textColor">{message}</Text>
        <Box alignItems="center" display="flex" justifyContent="space-around" m="1em" width="100%">
          <Button className="btn-yes" color="secondary" onClick={onConfirm}>
            Yes, add to order
          </Button>
          <Button className="btn-no" color="secondary" onClick={onClose} variant="outlined">
            No, go back
          </Button>
        </Box>
      </Box>
    </StyledItemWarningModal>
  );
};

export const StyledItemWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
`;

export const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;

  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }

  :hover {
    cursor: pointer;
  }
`;

export default ItemWarningModal;
