import { Input } from '@cfacorp/cowponents';
import styled from 'styled-components';

interface ValidatedFieldProps {
  type: string;
  placeholder: string;
  meta: { touched?: boolean; error?: string };
  onKeyUp?: (e: KeyboardEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  input: any;
  maxLength?: string;
  pattern?: string;
  disabled?: boolean;
  requiredWithoutTouch?: boolean;
}

const ValidatedField: React.FC<ValidatedFieldProps> = ({
  type,
  placeholder,
  meta,
  onKeyUp,
  onKeyDown,
  input,
  maxLength,
  pattern,
  disabled,
  requiredWithoutTouch,
}) => {
  let inputClassName = '';
  const { touched, error } = meta;

  if ((touched && error) || (requiredWithoutTouch && error)) {
    inputClassName = 'error-input';
  } else if (disabled) {
    inputClassName = 'disabled-input';
  } else {
    inputClassName = 'valid-input';
  }
  return (
    <StyledValidatedField>
      <Input
        {...input}
        autoComplete="nope"
        className={inputClassName}
        disabled={disabled}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
      />
      {(touched || requiredWithoutTouch) && error && <span className="error-message">{error}</span>}
    </StyledValidatedField>
  );
};

const StyledValidatedField = styled.div`
  & input {
    width: calc(100% - 14px);
    box-shadow: inset 0 0 0 9999px ${(props) => props.theme.colors.secondary};
  }

  & input::placeholder {
    color: ${(props) => props.theme.colors.outline};
    -webkit-text-fill-color: ${(props) => props.theme.colors.outline};
  }

  & input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.colors.text};
  }

  & .disabled-input {
    color: ${(props) => props.theme.colors.outline};
    box-shadow: inset 0 0 0 9999px ${(props) => props.theme.colors.background};
  }

  & .disabled-input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.colors.outline};
  }

  & .error-input {
    border: 2px solid ${(props) => props.theme.colors.error};
  }

  & .error-message {
    color: ${(props) => props.theme.colors.error};
    font-size: 12px;
    font-weight: bold;
    float: left;
    margin-left: 12px;
  }
`;

export default ValidatedField;
