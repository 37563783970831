import { Flex } from '@cfacorp/cowponents';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { featureFlagVariation } from '../../../apis';
import FavoriteOrderComponent from '../../../components/FavoriteOrder';
import { selectPastAndFavoriteOrders } from '../../../reducers/guest';
import { actions as orderActions } from '../../../reducers/order';
import Order from '../../../types/order';
import PastOrdersCard from '../PastOrdersCard';

interface PastOrdersProps {
  customer: GuestSearchResult;
}

export const PastOrders: React.FC<PastOrdersProps> = ({ customer }) => {
  const dispatch = useDispatch();
  const pastAndFavoriteOrders = useSelector(selectPastAndFavoriteOrders);

  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>();

  const openModal = (id: string) => setSelectedOrderId(id);
  const closeModal = () => setSelectedOrderId(undefined);

  if (pastAndFavoriteOrders?.length === 0) {
    return null;
  }

  return (
    <Flex flexWrap="wrap" justifyContent={['center', 'end']} m="20px 0">
      {pastAndFavoriteOrders?.map((order, idx) => (
        <PastOrdersCard
          handleReorderClick={() => dispatch(orderActions.initiateEditOrder(order as Order, customer, true))}
          key={`${(order as Order).id}-${idx}`}
          openFavoriteModal={openModal}
          order={order}
          showReorderButton={() => featureFlagVariation('reorder')}
        />
      ))}
      {selectedOrderId && <FavoriteOrderComponent close={closeModal} isOpen orderId={selectedOrderId} />}
    </Flex>
  );
};

export default PastOrders;
