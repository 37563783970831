import { Button } from '@cfa/react-components';
import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import FavoriteOrder from '../components/FavoriteOrder';
import Icon from '../components/Icon';
import Section from '../components/Section';
import Tooltip from '../components/Tooltip';
import { selectFavoriteOrders } from '../reducers/guest';
import { selectTransactionId } from '../reducers/order';
import { notifyBugsnag } from '../services/bugsnag';
import type Order from '../types/order';
import { formatLongDate, formatPrice } from '../util/format';

const Submit: React.FC = () => {
  const transactionId = useSelector(selectTransactionId);
  const favoriteOrders = useSelector(selectFavoriteOrders);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  let sentBugsnagNotify = false;
  const history = useHistory();
  const { state: locationState = {} } = useLocation<
    | {
        order?: Order;
        guestName?: string;
        isCfaOneGuest?: boolean;
      }
    | undefined
  >();
  const { order, guestName, isCfaOneGuest } = locationState;
  const editOrder = pathOr('', ['error', 'editOrder'], order);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  if (!locationState) {
    history.push('/');
    return null;
  }

  // on rare occasions we have seen order not exist in the incoming state
  // which caused exception/crash (consequences unknown)
  // to better diagnose this, we submit empty data for display and notify bugsnag
  const {
    subTotalAmount = 0,
    taxAmount = 0,
    destination = '',
    promiseDateTime = '1970-01-01T00:00:00',
    id = '',
    totalAmount = 0,
  } = order ?? {};

  const isFavorite = favoriteOrders.findIndex((favOrder) => favOrder.orderId === id) !== -1;

  if (!order && !sentBugsnagNotify) {
    notifyBugsnag('Missing order details on Submit view', {
      context: 'Submit View Missing Order Details',
      info: {
        order,
      },
    });
    sentBugsnagNotify = true;
  }

  return (
    <StyledSubmit>
      <Section>
        <Icon>celebrate</Icon>
        <h3>{`Order ${editOrder ? 'Updated' : 'Placed'} Successfully`}</h3>
      </Section>
      <Section className="summary">
        <p>
          {`${destination} Order #`}
          <span data-cy="order-id">{id}</span>
          {` for ${guestName}`}
        </p>
        <p data-cy="promise-date">{`Promise date: ${formatLongDate(promiseDateTime)}`}</p>
        <p data-cy="final-total">{`Total: ${totalAmount !== 0 ? formatPrice(totalAmount) : formatPrice(subTotalAmount + taxAmount)}`}</p>
        {transactionId && (
          <Tooltip
            className="transId-tooltip"
            tooltipContent={
              <div>
                <span className="title">Transaction ID:</span>
                <span> Year + Month + Day + Store Num + Transaction Number</span>
              </div>
            }
          >
            <p data-cy="trans-id">{`TransactionID: ${transactionId}`}</p>
          </Tooltip>
        )}
      </Section>
      <Section>
        {isFavorite && (
          <>
            <Icon height="20px" margin="5px" width="20px">
              favorite
            </Icon>
            <div className="favorite-message">{`This order has been added to ${guestName}'s Favorites`}</div>
          </>
        )}
        <Button
          onClick={() => {
            history.push('/');
          }}
        >
          Start New Order
        </Button>
        {isCfaOneGuest && !isFavorite && id && (
          <Button className="button-is-favorite" onClick={openModal}>
            Add to Favorites
          </Button>
        )}
      </Section>
      {isCfaOneGuest && id && (
        <FavoriteOrder close={closeModal} guestName={guestName} isOpen={modalIsOpen} orderId={id} />
      )}
    </StyledSubmit>
  );
};

const StyledSubmit = styled.div`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;

  & svg {
    fill: ${(props) => props.theme.colors.primary};
  }

  & .summary p {
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: initial;
  }

  & .favorite-message {
    margin: 0 0 10px;
  }

  & .tooltip {
    top: 22px !important;
    left: unset !important;
    max-width: 80vw;

    .title {
      font-weight: bold;
    }
  }
`;

export default Submit;
