import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

const Defer: React.FC<Props> = ({ className }) => {
  return <div className={className}>Use this for guest paying upon pickup and TBC (To Be Collected)</div>;
};

const StyledDefer = styled(Defer)`
  text-align: left;
  padding: 5px 41px;
  color: ${(props) => props.theme.colors.text};
  line-height: 1.4em;
  clear: both;
`;

export default StyledDefer;
