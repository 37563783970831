import { ToggleButton, ToggleButtonGroup } from '@cfa/react-components';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import ooe from '../constants';
import { actions as orderActions, selectDestination } from '../reducers/order';
import { selectLocationAcceptsDelivery, selectLocationAcceptsPickup } from '../reducers/user';
import DeliveryDestinationForm from './components/DeliveryDestinationForm';
import DetailsForm from './components/DetailsForm';
import SecondaryContactField from './components/SecondaryContactField';

const EventDetails: React.FC = () => {
  const dispatch = useDispatch();
  const destination = useSelector(selectDestination);
  const deliveryAccepted = useSelector(selectLocationAcceptsDelivery);
  const pickupAccepted = useSelector(selectLocationAcceptsPickup);

  const { PICKUP, DELIVERY } = ooe;
  const secondaryContactTitle = `Secondary ${destination === DELIVERY ? 'Delivery' : 'Pickup'} POC Information`;

  const isDisabled = (currentDestination: string) =>
    (currentDestination === DELIVERY && !deliveryAccepted) ||
    (currentDestination === PICKUP && !pickupAccepted);

  return (
    <>
      <Section>
        <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            data-testid="segment-group"
            onChange={(_, value) => dispatch(orderActions.changeDestination(value))}
            size="sm"
            value={destination}
          >
            {ooe.CATERING_METHODS.map((dest) => (
              <ToggleButton
                data-testid="dest-segment-button"
                disabled={isDisabled(dest)}
                key={dest}
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                value={dest}
              >
                {dest}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <SectionHeader>Event Details</SectionHeader>
        <DetailsForm />
      </Section>
      {destination === DELIVERY && (
        <>
          <Section>
            <SectionHeader>Delivery Location</SectionHeader>
            <DeliveryDestinationForm />
          </Section>
        </>
      )}
      <Section>
        <SectionHeader>
          {secondaryContactTitle}
          <small> (Optional)</small>
        </SectionHeader>
        <SecondaryContactField />
      </Section>
    </>
  );
};

export default memo(EventDetails);
