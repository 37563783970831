export default class ooeConstants {
  static get APP_VERSION() {
    const version = import.meta?.env?.VITE_APP_VERSION ?? '0.0.0';
    return `${version}${import.meta.env.DEV ? '-development' : ''}`;
  }

  static get AMPLITUDE_ENABLED() {
    if (this.IS_IN_CYPRESS_TEST) {
      return false;
    }

    if (import.meta.env.PROD) {
      return true;
    }

    const enableAmplitudeInDevelopment =
      import.meta.env?.VITE_APP_ENABLE_AMPLITUDE_IN_DEVELOPMENT?.toLowerCase?.() === 'true';
    return !!enableAmplitudeInDevelopment;
  }

  static get AMPLITUDE_API_KEY() {
    const forProduction = '554e50c35570b7810cca7102965aed76';
    const forDevAndQa = 'cb755829118203626e473680b9436912';

    if (import.meta.env.DEV || window.location?.host?.startsWith('localhost')) {
      return forDevAndQa;
    }

    if (window.location?.hostname?.startsWith('dev.')) {
      return forDevAndQa;
    }

    if (window.location?.hostname?.startsWith('qa.')) {
      return forDevAndQa;
    }

    return forProduction;
  }

  static get BUGSNAG_ENABLED() {
    if (this.IS_IN_CYPRESS_TEST) {
      return false;
    }

    if (import.meta.env.PROD) {
      return true;
    }

    const enableBugsnagInDevelopment =
      import.meta.env?.VITE_APP_ENABLE_BUGSNAG_IN_DEVELOPMENT?.toLowerCase?.() === 'true';
    return !!enableBugsnagInDevelopment;
  }

  static get BUGSNAG_RELEASE_STAGE() {
    if (import.meta.env.DEV || window.location?.host?.startsWith('localhost')) {
      return this.DEVELOPER;
    }

    if (window.location?.hostname?.startsWith('dev.')) {
      return this.BUGSNAG_DEV_DEPLOY;
    }

    if (window.location?.hostname?.startsWith('qa.')) {
      return this.QA;
    }

    return ooeConstants.PRODUCTION;
  }

  // this exists to resolve the conflict with Bugsnag using 'development'
  // as default release stage - we have a development deploy environment
  // which is what this represents
  static get BUGSNAG_DEV_DEPLOY() {
    return 'dev';
  }

  static get BUGSNAG_API_KEY() {
    return 'b0d00dfe57549e290ed0ba83afe6551e';
  }

  static get DELIVERY(): 'Delivery' {
    return 'Delivery';
  }

  static get PICKUP(): 'Pickup' {
    return 'Pickup';
  }

  static get DXE_CATERING_TYPE() {
    return 'catering';
  }

  static get DXE_DELIVERY_METHOD() {
    return 'delivery';
  }

  static get DXE_PICKUP_METHOD() {
    return 'pickup';
  }

  static get DAY_PART_BREAKFAST() {
    return 'Breakfast';
  }

  static get OFFLINE_MESSAGE() {
    return 'Unable to connect to the internet. Please check your network and try again.';
  }

  static get ONLINE_MESSAGE() {
    return 'Your connection has been reestablished';
  }

  static get ONLINE_MESSAGE_TIMEOUT() {
    return 5000;
  }

  static get CATERING_METHODS() {
    return [this.PICKUP, this.DELIVERY];
  }

  static get DISMISSIBLE() {
    return '[DISMISSIBLE]';
  }

  static get TOGGLEABLE_ITEM_TAGS() {
    return [
      'BACON',
      'CHEESE_COLBY_JACK',
      'PICKLES',
      'MULTIGRAIN_BUN',
      'CHEESE_AMERICAN',
      'CHEESE_PEPPER_JACK',
      'FRUIT_CUP_MEDIUM',
      'SUPERFOOD_SIDE_SMALL',
    ];
  }

  static get SAUCES() {
    return [
      '8OZ_BARBEQUE_SAUCE',
      '8OZ_POLYNESIAN',
      '8OZ_BUFFALO_SAUCE',
      '8OZ_RANCH',
      '8OZ_HONEY_MUSTARD',
      '8OZ_CFA_SAUCE',
      '8OZ_SRIRACHA',
    ];
  }

  static get SIDES() {
    return ['FRUIT_CUP_MEDIUM', 'SM_KALE_CRUNCH', 'LG_KALE_CRUNCH'];
  }

  static get DESSERTS() {
    return ['COOKIE_CHOCOLATE_CHUNK_INDIVIDUAL', 'BROWNIE'];
  }

  static get DEVELOPER() {
    return 'developer';
  }

  static get DEVELOPMENT() {
    return 'development';
  }

  static get PRODUCTION() {
    return 'production';
  }

  static get DEV() {
    return 'dev';
  }

  static get QA() {
    return 'qa';
  }

  static get PROD() {
    return 'prod';
  }

  static get IS_IN_CYPRESS_TEST() {
    return !!window.Cypress;
  }

  static get CREDIT() {
    return 'Stored Payment';
  }

  static get DEFER() {
    return 'Pay Later';
  }

  static get REQUEST_PAYMENT() {
    return 'New Payment';
  }

  static get PAYMENT_PENDING() {
    return 'PaymentPending';
  }

  static get ERROR() {
    return 'Error';
  }

  static get FINALIZED() {
    return 'Finalized';
  }

  static get CANCELLED() {
    return ['Cancelled', 'Cancelled-Web'];
  }

  static get UTC_TIME() {
    return 'utcTime';
  }

  static get ZERO_DOLLARS() {
    return '$0.00';
  }

  static get DEFAULT_ENVIRONMENT() {
    return 'qa';
  }

  static get POS_ORDER_WARNING_TEXT() {
    return 'If this order was recently entered on CMT, there may be a delay in the order appearing. Please allow the system to correct itself on the following business day.';
  }

  static get ENVIRONMENT_DATA() {
    const { hostname } = window.location;
    let environment = this.DEFAULT_ENVIRONMENT;
    let pilotFlag = '';
    let pilot = false;

    const rx = /(qa|dev|prod)?(-pilot)?\.?(my|order-management|crndev)\.?(chick-fil-a|cfahome)\.com.*/g;
    const getEnv = rx.exec(hostname);

    if (hostname === 'localhost') {
      environment = 'qa';
    }
    if (getEnv) {
      [, environment, pilotFlag] = getEnv;
      pilot = pilotFlag === '-pilot';
      environment = environment || 'prod';
    }
    return { ENV: environment, IS_PILOT: pilot };
  }

  static get ENV() {
    return this.ENVIRONMENT_DATA.ENV;
  }

  static get LOGOUT_LINK() {
    return '/';
  }

  static get OKTA_INVALID_ERROR() {
    return 'access_denied';
  }

  static get EARLIEST_AVAILABLE_BREAKFAST() {
    return 'earliestAvailableBreakfast';
  }

  static get EARLIEST_AVAILABLE() {
    return 'earliestAvailable';
  }

  static get DEFAULT_STORES() {
    return ['01668', '00070', '02345', '03662', '03544', '01639', '03700', '90204'];
  }

  static get PROD_PILOT_LAB() {
    return '90204';
  }

  static get OKTA_CONFIG() {
    const qa = {
      url: 'https://cfahome.oktapreview.com',
      clientId: '0oae1cb28rKPvxpzc0h7',
      redirectUri: `${window.location.origin}`,
      issuer: 'https://cfahome.oktapreview.com/oauth2/ausc6e4sj2fnPnQ670h7',
      authorizeUrl: 'https://cfahome.oktapreview.com/oauth2/ausc6e4sj2fnPnQ670h7/v1/authorize',
    };

    const prod = {
      url: 'https://cfahome.com',
      clientId: '0oa622ol2eB8e5PBv1t7',
      redirectUri: `${window.location.origin}`,
      issuer: 'https://cfahome.okta.com/oauth2/aus4i6zex3F52d4rn1t7',
      authorizeUrl: 'https://cfahome.okta.com/oauth2/aus4i6zex3F52d4rn1t7/v1/authorize',
    };

    if (this.ENV === this.PROD) {
      return prod;
    }
    return qa;
  }

  static get DXE_SERVICES_BASE() {
    if (this.ENV === this.PROD) {
      return 'api.my.chick-fil-a.com';
    }
    return `api.${this.QA}.crndev.chick-fil-a.com`;
  }

  static get D2C_SERVICES_BASE() {
    if (this.ENV === this.PROD) {
      return 'api.my.chick-fil-a.com';
    }
    return `api.${this.QA}.crndev.chick-fil-a.com`;
  }

  static get URL() {
    let pilot = '';
    if (this.ENVIRONMENT_DATA.IS_PILOT) {
      pilot = '-pilot';
    }

    return {
      ORDERS: `https://order${pilot}.${this.DXE_SERVICES_BASE}/orders`,
      LOCATION_ORDERS: `https://order${pilot}.${this.DXE_SERVICES_BASE}/locations`,
      D2C: `https://d2c${pilot}.${this.D2C_SERVICES_BASE}`,
      PROFILE: `https://profile${pilot}.${this.DXE_SERVICES_BASE}`,
      LOCATIONS: `https://location${pilot}.${this.DXE_SERVICES_BASE}`,
      ANALYTICS: `https://analytics${pilot}.${this.DXE_SERVICES_BASE}/orders`,
      CMT_CONFIG: `https://order${pilot}.${this.DXE_SERVICES_BASE}/cmt/config`,
      PAYMENT: `https://gc${pilot}.${this.DXE_SERVICES_BASE}`,
    };
  }

  static get DXE_DELAY_TIME() {
    return 1000;
  }

  static get DXE_DASHBOARD_DELAY_TIME() {
    return 2000;
  }

  static get GET_TITLE() {
    return {
      '/': 'Guest',
      '/menu': 'Menu',
      '/event': 'Event Details',
      '/payment': 'Payment',
      '/more': 'More',
      '/more/dashboard': 'Customer Dashboard',
    };
  }

  static get GET_STATUS() {
    return {
      PaymentPending: 'Payment Pending',
      Submit: 'Payment Received',
      Cancelled: 'Cancelled',
      'Cancelled-Web': 'Cancelled',
      PayLater: 'Pay Later',
      Stored: 'Stored (POS)',
      Finalized: 'Tendered (POS)',
      Cleared: 'Cleared',
      Error: 'Error',
      Refunded: 'Refunded',
      'Cleared - Manual': 'Cleared',
    };
  }

  static GET_EVENT_MESSAGE(order: { displayClient?: Nullable<string>; email?: Nullable<string> }) {
    return {
      create: `Order placed via ${order.displayClient}`,
      paymentPending: `Payment request email sent to ${order.email}`,
      submit: 'Order submitted successfully - in deferred order queue',
      cancelled: 'Order cancelled',
      cancel: 'Order cancelled',
      myPayment: 'Payment details entered by customer - in deferred order queue ',
      error: 'An error occurred during the submission of this order.',
    };
  }

  static get GET_LOYALTY() {
    return {
      'Chick-fil-a One':
        'Earn 10 points for every dollar spent. Earn 1000 points to reach Chick-fil-A One Silver status.',
      Red: 'Earn 12 points for every dollar spent. Receive exclusive benefits including two free tickets to the College Football Hall of Fame in Atlanta, GA.',
      Silver: 'Earn 11 points for every dollar spent. Earn 5000 points to reach Chick-fil-A One Red status.',
    };
  }

  static get GET_CLIENT() {
    return {
      ooe_masquerade: 'Catering Management Tool',
      MyPayments: 'Catering Management Tool',
      CfaDotCom: 'Chick-fil-a.com',
      GuestLogin: 'Testing Automation',
      POS: 'Restaurant Point of Sale',
      PWO: 'PWO',
      PWOCA: 'PWO',
      PWOGuest: 'PWO',
      MobileAppV2: 'Mobile App',
    };
  }

  static get GET_DASHBOARD_CANCEL_MESSAGES() {
    return {
      message: 'Are you sure you want to cancel this order? This cannot be undone.',
      confirmText: 'Yes, cancel this order',
      declineText: "Nevermind, don't cancel",
      cancelled: 'This order has been cancelled.',
    };
  }

  static get GET_DASHBOARD_RESEND_MESSAGES() {
    return {
      message: 'Are you sure you want to resend payment confirmation email to',
      confirmText: 'Yes, resend email',
      declineText: "Nevermind, don't resend email",
      emailSent: 'Success! Request payment email was sent to',
    };
  }

  static get GET_PAYMENT_TYPES() {
    return {
      ACCOUNT: 'ACCOUNT',
      TO_BE_COLLECTED: 'TO_BE_COLLECTED',
    };
  }

  static get GET_FORM_TYPES() {
    return {
      GUEST: 'guest',
      DETAILS: 'details',
      PAYMENT_METHOD: 'paymentMethod',
      SECONDARY_CONTACT: 'secondaryContact',
    };
  }

  static get NAME_ABBREVIATIONS() {
    return {
      small: 'Sm',
      medium: 'Md',
      large: 'Lg',
      Grilled: 'Grl',
      Gallon: 'Gal',
      Lemonade: 'Lmnade',
      Southwest: 'SW',
      Packaged: 'Box',
      Premium: '4 Item',
      Dressing: '',
      Vinaigrette: '',
      Zesty: '',
      Cheese: '',
      'OS - ': '',
      'OS ': '',
      '<sup>™</sup>': '',
      '<sup>®</sup>': '',
      '™': '',
      '®': '',
      '8oz': '',
      'Freshly-Brewed': '',
      'Fresh-squeezed': '',
      'Sweet and Spicy': '',
      'Garlic & Herb': '',
      'Garlic and Herb': '',
      'Chick-n-': '',
      'Honey Mustard': 'Honey M',
      'Greek Yogurt': '',
      'Chocolate Chunk': '',
      'Hot Chick-fil-A': '',
      'Chick-fil-A Chicken Biscuit': 'Chicken Biscuit',
      'Chilled Chick-fil-A Nuggets Tray': 'Nuggets Tray (Cold)',
      'Hot Strips Tray': 'Strips Tray',
      'Chilled Strips \\(Cooked & Chilled for Later\\) Tray': 'Strips Tray (Cold)',
      'Chilled Strips\\(Cooked & Chilled for Later\\) Tray': 'Strips Tray (Cold)',
      'Chilled Grl Chicken Sub Sandwich Tray': 'Chill Grl Tray',
      'Chilled Grl Chicken Sub Sandwich': 'Chilled Grilled Sand',
      'Chilled Grl Sand': 'Chilled Grilled Sand',
      'Grl Chicken': '',
      'Chick-fil-A Nuggets': 'Nuggets',
      'Chick-fil-A Chicken': 'Chicken',
      'Chilled  Sub Box Meal': 'Chilled Grilled Sand Box Meal',
      'sandwich halves': 'Sandwich Halves',
      'wrap halves': 'Wrap Halves',
    };
  }

  static MAILTO_STRING(locationNumber: string) {
    return `mailto:DigitalCateringFeedback@chick-fil-a.com?subject=Feedback from ${locationNumber} on Catering Management Tool`;
  }

  static get HELPFUL_LINKS() {
    return {
      ABOUT_CMT:
        'https://portal.cfahome.com/en/food-and-delivery/beyond-the-restaurant/catering/catering-management-tool/',
      USER_GUIDE:
        'https://portal.cfahome.com/globalassets/documents/general/staff-operator-team-member/catering-management-tool-user-guide.pdf',
      FAQ: 'https://portal.cfahome.com/en/food-and-delivery/beyond-the-restaurant/catering/catering-management-tool/#TopicConten-Frequently-Asked-Questions',
      NEED_HELP: 'https://cfaprod.service-now.com/cfahelp',
    };
  }

  static get TOKEN_TYPES() {
    return {
      GUEST_TYPE: 'GUEST_TYPE',
      MASQUERADE_TYPE: 'MASQUERADE_TYPE',
    };
  }

  static get VCA_INKTEL() {
    return 'VCA_INKTEL';
  }

  static get allowedCardTypes() {
    return ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'JCB', 'DINERS'];
  }

  static get paypal() {
    return 'PayPal';
  }

  static get DATE_TIME_FORMAT() {
    return {
      longDate: 'EEEE, MMMM d, h:mm aaa',
      shortDate: 'MM/d/yyyy h:mm aaa',
      eventSummaryDate: 'EEEE, MMM do',
      eventSummaryTime: 'h:mm aaa',
      readableDate: 'EEEE, MMM d h:mm aaa',
      timeAmPm: 'hh:mm aa',
      dateTime: "yyyy-MM-dd'T'HH:mm:ss",
      time: 'HH:mm',
      date: 'yyyy-MM-dd',
    };
  }

  static get PERMISSIONS() {
    return {
      ADMIN: 'ADMIN',
      VCA: 'VCA',
      VCA_BYPASS: 'VCA_BYPASS',
    };
  }

  static get ROUTE_TO_REDIRECT_TO() {
    return 'ROUTE_TO_REDIRECT_TO';
  }

  static get CONTRACTOR_AUDIENCE() {
    return 'CONTRACTOR_AUDIENCE';
  }

  static get STAFF_AUDIENCE() {
    return 'STAFF_AUDIENCE';
  }

  static get FIND_NUMBER_REGEX() {
    return RegExp('(\\d{1,10})', 'g');
  }

  static get POS_INVALID_CHARS_REGEX() {
    // escape *is* necessary in this case
    // eslint-disable-next-line no-useless-escape
    return /[^\+\-\*\.,?! a-zA-Z0-9]/g;
  }

  static get EULA(): 'cmtEula' {
    return 'cmtEula';
  }

  static get LOCAL_STORAGE_EULA() {
    return 'localStorageCmtEula';
  }

  static get EULA_MESSAGE() {
    return `<p>The Catering Management Tool is a Chick-fil-A, Inc. platform made available to support Operators' catering orders from order entry to fulfillment. 
    Chick-fil-A, Inc. has collected from customers certain customer information (that includes personal information under certain privacy and data security laws) 
    available to you in the Catering Management Tool.</p>\n \n<p>The Catering Management Tool and the customer information stored by Chick-fil-A, Inc. and available 
    through this platform are proprietary to and the confidential information of Chick-fil-A, Inc. <strong>You are solely responsible for your Chick-fil-A Restaurant(s) 
    and your Team Members' access to and use of the Catering Management Tool and any related customer information. Access should be limited to Operators and employees 
    designated as Catering Leaders due to customer privacy and data security concerns.</strong></p> \n \n<p>As a user of the Catering Management Tool, it is your duty and 
    responsibility to keep this information confidential. You must only use the Catering Management Tool in connection with the sale of Chick-fil-A products within the 
    Chick-fil-A franchise system, and in accordance with all applicable federal and state laws, rules and regulations.</p>\n \n<p><strong>As a reminder, it is your responsibility 
    to keep your Chick-fil-A password secure. Do not share your personal Chick-fil-A password with anyone, even other Team Members. If you think someone has gained access to your 
    password, change it. If you need help changing your password, please call the IT Helpdesk.</strong></p>`;
  }

  static get NUM_ADDTL_PAST_ORDERS_TO_LOAD() {
    return 3;
  }

  static get NUM_ADDTL_DASHBOARD_PAST_ORDERS_TO_LOAD() {
    return 1;
  }

  static get NUM_OF_PAST_ORDERS_FOR_DELIVERY_ADDRESSES() {
    return 20;
  }

  static get NO_PICKUP_LEAD_TIME() {
    return 'No pickup lead time defined for this location';
  }

  static get NO_DELIVERY_LEAD_TIME() {
    return 'No delivery lead time defined for this location';
  }

  static get LOCAL_STORAGE_CFA_KEY() {
    return 'cfa_token_key';
  }

  static get LOCAL_STORAGE_OKTA_KEY() {
    return 'okta_token_key';
  }

  static get LOCAL_STORAGE_DEFAULT_LOCATION() {
    return 'storedLocationNumber';
  }

  static get BUGSNAG_ERRORCLASS_ANALYTICS() {
    return 'Analytics API';
  }

  static get BUGSNAG_ERRORCLASS_PAYMENTS() {
    return 'Payments API';
  }

  static get BUGSNAG_ERRORCLASS_ORDERS() {
    return 'Orders API';
  }

  static get BUGSNAG_ERRORCLASS_PROFILE() {
    return 'Profile API';
  }

  static get BUGSNAG_ERRORCLASS_D2C() {
    return 'D2C API';
  }

  static get BUGSNAG_ERRORCLASS_LOCATIONS() {
    return 'Locations API';
  }

  static get BUGSNAG_ERRORCLASS_CMT_CONFIG() {
    return 'CMT Config API';
  }

  static get BUGSNAG_ERRORCLASS_OKTA() {
    return 'Okta';
  }

  static get AMPLITUDE_EVENT_NAMES() {
    return {
      SHOW_STORE_INFO: '[Show Store Info]',
    };
  }

  static get EMPTY_PHONE_FIELD_WARNING_HEADING() {
    return 'Please add a Phone Number for the Delivery Point of Contact';
  }

  static get EMPTY_PHONE_FIELD_WARNING_MESSAGE() {
    return 'CFA One Account phone number field is blank. Please enter phone number in Special Intructions Field';
  }

  static get PAPER_GOODS_MESSAGES() {
    return [
      'Please include plates, cutlery, napkins and cups.',
      'Please do not include plates, cutlery, napkins or cups.',
      'Please include plates',
      'Please include cutlery',
      'Please include napkins',
      'Please include cups',
    ];
  }

  static get MAX_PROMO_FREE_ITEM_QTY() {
    return 75;
  }

  static get PROMO_FREE_ITEM_PRICE() {
    return 0.0;
  }

  static get AVAILABLE_DELIVERY_TIP_LOCATIONS() {
    //this is for test purpose
    return ['testLocation'];
  }

  static get ALL_DAY_CHICKEN_MINI_LOCATIONS() {
    return [
      '02281',
      '02979',
      '02479',
      '03688',
      '03382',
      '02092',
      '04149',
      '03126',
      '03466',
      '03116',
      '04404',
      '04051',
      '01939',
      '02730',
      '03791',
      '03617',
      '02805',
      '02043',
      '03071',
      '03098',
      '01244',
      '04042',
      '03544',
      '00791',
      '04182',
      '02681',
      '02817',
      '01648',
      '04343',
      '03717',
      '03644',
      '01424',
      '04082',
      '00498',
      '70045',
      '03032',
      '03516',
      '03336',
      '02891',
      '04046',
      '03318',
      '01018',
      '02971',
      '01999',
      '04386',
      '02811',
    ];
  }

  static get PAY_LATER_NOT_ALLOWED_LOCATIONS() {
    return [
      '00524',
      '02869',
      '01438',
      '03913',
      '02013',
      '04489',
      '01142',
      '01882',
      '01161',
      '00609',
      '01986',
      '03003',
      '03035',
      '01977',
      '03181',
      '03413',
      '03559',
      '02926',
      '03697',
      '03901',
      '01846',
      '03700',
      '02603',
      '03797',
      '03875',
      '03466',
      '04198',
      '00366',
      '02968',
      '00793',
      '03447',
      '04269',
      '03678',
      '04404',
      '01653',
      '04323',
      '03855',
      '02756',
      '03723',
      '03719',
      '02730',
      '03392',
      '03031',
      '04255',
      '03977',
      '03801',
      '00789',
      '02805',
      '04160',
      '03739',
      '03227',
      '00632',
      '02043',
      '03071',
      '01018',
      '01939',
      '01527',
      '01093',
      '01082',
      '03794',
      '00685',
      '04534',
      '04436',
      '03098',
      '03544',
      '02226',
      '03335',
      '04182',
      '01116',
      '02244',
      '01648',
      '01223',
      '01867',
      '04065',
      '04343',
      '04769',
      '03410',
      '02634',
      '00376',
      '04734',
      '03243',
      '01959',
      '03124',
      '00009',
      '00457',
      '00730',
      '04941',
      '70049',
      '01535',
      '03870',
      '03981',
      '04311',
      '03266',
      '04038',
      '00551',
      '03174',
      '00528',
      '04646',
      '04046',
      '03318',
      '02971',
      '04483',
      '04708',
      '01248',
      '02335',
      '02859',
      '04251',
      '03980',
    ];
  }

  static get SPECIAL_LOCATION_STORE_INFO_MESSAGES() {
    return {
      '02891': 'Transfer all catering questions to Catering Leaders at (304) 982-8382',
      '04302': 'Transfer all catering questions to Catering Leaders at (304) 932-0742',
    };
  }

  static get HARDCODED_PHONE_FOR_03606() {
    return '402-885-8908';
  }

  static get HARDCODED_PHONE_FOR_02891() {
    return '304-982-8382';
  }

  static get HARDCODED_PHONE_FOR_04302() {
    return '304-932-0742';
  }

  static get HARDCODED_PHONE_FOR_03724() {
    return '301-509-3605';
  }

  static get MAX_NUM_OF_DISPLAYED_LOCATIONS() {
    return 5;
  }

  static get PHONE_REGEX() {
    return /^(\+[1-9][0-9]*\s?)?([ 0-9()-]+(\s|$)){3}/i;
  }
}
