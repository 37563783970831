import { createSelector } from '@reduxjs/toolkit';

import ooeConstants from '../constants';
import { selectCfaOneGuestOriginalPhone, selectIsCfaOneGuestSelected } from '../reducers/guest';
import { selectEditMode, selectReorder } from '../reducers/order';

export const selectDisableGuestFields = createSelector(
  selectEditMode,
  selectIsCfaOneGuestSelected,
  (editMode, cfaOne) => editMode || cfaOne,
);

export const selectDisableGuestPhone = createSelector(
  selectEditMode,
  selectCfaOneGuestOriginalPhone,
  selectReorder,
  (editMode, phone, reorder) =>
    editMode || reorder || (phone !== '+1 111 111 1111' && ooeConstants.PHONE_REGEX.test(phone)),
);
