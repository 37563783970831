import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'ramda';

import { selectMenu } from '../reducers/menu';
import { selectDestination } from '../reducers/order';
import { getCombinedMenu } from './utils';

export const selectMenuWithMethods = createSelector(selectDestination, selectMenu, (method, menuState) => {
  return getCombinedMenu(method, pick(['Delivery', 'Pickup'], menuState || {}));
});
