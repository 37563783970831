import { contains, isEmpty, pathOr } from 'ramda';
import ooeConstants from 'src/constants';
import {
  DashboardOrder,
  DashboardOrderDisplay,
  DashboardOrderLineItem,
  DashboardOrderModifier,
} from 'src/types/dashboard';
import { formatLongDate, formatPrice } from 'src/util/format';

// Selector helper functions
export function getOrderStatus(status: keyof typeof ooeConstants.GET_STATUS, paymentType: string) {
  let statusName = ooeConstants.GET_STATUS[status];
  let statusIcon = 'success';
  if (statusName === ooeConstants.GET_STATUS.PaymentPending) {
    statusIcon = 'warning';
  } else if (contains(status, ooeConstants.CANCELLED) || status === ooeConstants.ERROR) {
    statusIcon = 'error';
  } else if (status === 'Submit' && paymentType === 'TO_BE_COLLECTED') {
    statusName = ooeConstants.GET_STATUS.PayLater;
  } else if (
    statusName === ooeConstants.GET_STATUS.Finalized ||
    statusName === ooeConstants.GET_STATUS.Stored
  ) {
    statusIcon = 'restaurant';
  }
  return {
    statusName,
    statusIcon,
  };
}

export function formatDisplayFields(order: DashboardOrder): DashboardOrder & DashboardOrderDisplay {
  return {
    ...order,
    customerName: `${order.givenName} ${order.familyName}`,
    total: order.totalAmount === undefined ? order.subTotalAmount + order.taxAmount : order.totalAmount,
    displayStatus: getOrderStatus(
      order.status as keyof typeof ooeConstants.GET_STATUS,
      pathOr('', ['payment', 'paymentType'], order),
    ),
    displayLongDate: formatLongDate(order.promiseDateTime),
    displaySubtotal: formatPrice(order.subTotalAmount),
    displayPrice:
      order.totalAmount === undefined
        ? formatPrice(order.subTotalAmount + order.taxAmount)
        : formatPrice(order.totalAmount),
    displayClient:
      ooeConstants.GET_CLIENT[order.clientId as keyof typeof ooeConstants.GET_CLIENT] || 'Unknown',
    displayTaxAmount: formatPrice(order.taxAmount),
  };
}

export function calculateModifierQuantity(mod: DashboardOrderModifier, lineItemQuantity: number) {
  const { quantity, itemTag } = mod;
  const toggleable = contains(itemTag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
  // Use line item quantity for toggleable modifiers
  if (toggleable) {
    return lineItemQuantity;
  }
  // Use item quantity for all other modifiers
  return quantity;
}

function calculateModifierPrice(mod: DashboardOrderModifier, lineItemQuantity: number) {
  const { retailPrice, priceAdjustment, itemTag } = mod;
  const isSauce = contains(itemTag, ooeConstants.SAUCES);
  // Use sauce price for sauce modifiers
  if (isSauce) {
    return priceAdjustment;
  }
  // Multiply price by line item quantity for all other items
  return retailPrice * lineItemQuantity;
}

export function findComboItem(lineItem: DashboardOrderLineItem, itemTags: string[]) {
  const { quantity } = lineItem;
  if (lineItem.comboItems) {
    // Check if combo items contains a side
    const comboItem: (typeof lineItem.comboItems)[number] = pathOr(
      {},
      [0],
      lineItem.comboItems.filter((item) => contains(item.itemTag, itemTags)),
    );
    // If it does, return the side item
    if (!isEmpty(comboItem)) {
      return {
        ...comboItem,
        price: comboItem.priceAdjustment * quantity,
        displayQuantity: quantity,
      };
    }
  }
  return undefined;
}

export function findSide(lineItem: DashboardOrderLineItem) {
  return findComboItem(lineItem, ooeConstants.SIDES);
}

export function findDessert(lineItem: DashboardOrderLineItem) {
  return findComboItem(lineItem, ooeConstants.DESSERTS);
}

export function findModifiers(lineItem: DashboardOrderLineItem) {
  const { quantity } = lineItem;
  if (lineItem.modifiers) {
    return lineItem.modifiers.map((mod) => ({
      ...mod,
      tag: mod.itemTag,
      price: calculateModifierPrice(mod, quantity),
      displayQuantity: calculateModifierQuantity(mod, quantity),
    }));
  }
  return [];
}

export function findComboModifiers(lineItem: DashboardOrderLineItem) {
  const { quantity } = lineItem;
  const comboMods: Array<DashboardOrderLineItem & { tag: string; price: number; displayQuantity: number }> =
    [];
  if (lineItem.comboItems) {
    lineItem.comboItems.forEach((comboItem) => {
      if (comboItem.modifiers) {
        comboItem.modifiers.forEach((comboMod) => {
          comboMods.push({
            ...comboMod,
            tag: comboMod.itemTag,
            price: comboMod.priceAdjustment * quantity,
            displayQuantity: calculateModifierQuantity(comboMod, quantity),
          });
        });
      }
    });
  }
  return comboMods;
}

export function findPOSModifiers(lineItem: DashboardOrderLineItem) {
  if (lineItem.subItems) {
    return lineItem.subItems.map((subItem) => ({
      ...subItem,
      tag: subItem.itemTag,
      price: subItem.retailPrice,
      displayQuantity: subItem.quantity,
    }));
  }
  return [];
}

export function mapOrderDetails(orderDetails: DashboardOrder) {
  let mappedLineItems;
  if (orderDetails.lineItems) {
    mappedLineItems = orderDetails.lineItems.map((lineItem, idx) => ({
      ...lineItem,
      tag: lineItem.itemTag,
      key: `${lineItem.itemTag}-${idx}`,
      price: lineItem.retailPrice * lineItem.quantity,
      selectedSide: findSide(lineItem),
      selectedDessert: findDessert(lineItem),
      modifiers: findPOSModifiers(lineItem)
        .concat(findComboModifiers(lineItem))
        .concat(findModifiers(lineItem)),
    }));
  }
  const additionalFields = formatDisplayFields(orderDetails);
  return {
    ...orderDetails,
    ...additionalFields,
    lineItems: mappedLineItems,
  };
}
