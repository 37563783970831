import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@cfa/react-components';
import Section from '../../../components/Section';

import { selectLocationContactDetails } from '../../../reducers/user';
import OrderInformation from '../OrderInformation';

const CreateInvoicePage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const restaurantContactDetails = useSelector(selectLocationContactDetails);

  const { restaurantAddress1, restaurantAddress2, restaurantPhone } = restaurantContactDetails;

  const fullAddress = `${restaurantAddress1}, ${restaurantAddress2}`;

  return (
    <div ref={ref}>
      <Section padding="2rem 1rem 0 1rem">
        <Typography variant="h3">Chick-fil-A catering Order Summary</Typography>
      </Section>
      <Section padding="0 1rem">
        <p data-testid="full-address">{fullAddress}</p>
        <p data-testid="restaurant-phone">{restaurantPhone}</p>
      </Section>
      <OrderInformation isInvoicePage />
    </div>
  );
});

export default CreateInvoicePage;
