import styled from 'styled-components';

import Icon from '../../../components/Icon';

interface DashboardErrorProps {
  detailViewErrors?: Nullable<string>[];
}

const DashboardError: React.FC<DashboardErrorProps> = ({ detailViewErrors }) => {
  return detailViewErrors?.map(
    (errorMessage) =>
      errorMessage && (
        <StyledDashboardError key={errorMessage}>
          <Icon className="icn">error</Icon>
          <div data-cy="dashboard-error">{errorMessage}</div>
        </StyledDashboardError>
      ),
  );
};

const StyledDashboardError = styled.div`
  font: ${(props) => props.theme.smallBoldFont};
  margin: 10px;
  padding: 0 7px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.error};
  border: 1px solid ${(props) => props.theme.colors.error};
  background-color: #ffe8e6;

  & .icn {
    min-width: 17px;
  }

  & svg {
    width: 17px;
    margin: 0 5px 0 0;
  }
`;

export default DashboardError;
