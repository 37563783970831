import PropTypes from 'prop-types';
import styled from 'styled-components';

/* eslint-disable*/
function Radio(props) {
  const { label, id, input, checked, className } = props;
  const { onChange } = input;
  return (
    <StyledRadio className={className}>
      <input className="radio" type="radio" id={id} value={id} checked={checked} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </StyledRadio>
  );
}
/* eslint-enable */

const StyledRadio = styled.div`
  float: left;
  margin: 0;
  height: 22px;
  text-align: center;

  & label {
    padding-top: 2px;
    color: ${(props) => props.theme.colors.text} !important;
  }

  & .radio:checked,
  .radio:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  & .radio:checked + label,
  .radio:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: ${(props) => props.theme.colors.accent};
  }

  & .radio:checked + label::before,
  .radio:not(:checked) + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  & .radio:checked + label::after,
  .radio:not(:checked) + label::after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.colors.accent};
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  & .radio:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
  }

  & .radio:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }
`;

Radio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  checked: PropTypes.bool,
};

Radio.defaultProps = {
  id: '',
  label: '',
  checked: false,
};

export default Radio;
