import { from, Observable, throwError } from 'rxjs';

import { leaveBreadcrumb } from '../services/bugsnag';
import { userErrorMessages } from './customerErrorMessages';
import handleErrors, { handleFetchFail } from './handleErrors';

type Auth = {
  type: string;
  apiKey?: string | null;
  accessToken?: string | null;
};

type JsonMapper<T> = (json: any) => T;

type Props<T> = {
  url: string;
  method?: string;
  auth?: Auth;
  mapper?: JsonMapper<T>;
  bugsnag?: JSONObject & { breadcrumb?: string; context?: string; errorClass: string };
  body?: JSONArray | JSONObject | FormData;
  form?: boolean;
};

export class ApiCall<T> {
  method: string;
  headers: HeadersInit;
  body?: string | FormData;
  url: string;
  auth?: Auth;
  bugsnag?: JSONObject & { breadcrumb?: string; context?: string; errorClass: string };
  mapper?: JsonMapper<T>;

  constructor(props: Props<T>) {
    const { method, url, auth, mapper, bugsnag, body, form } = props;
    this.headers = {};
    this.body = body as FormData;
    if (!form) {
      this.headers.Accept = 'application/json';
      this.headers['Content-Type'] = 'application/json';
      try {
        this.body =
          method === 'PUT' || method === 'POST' || method === 'PATCH' ? JSON.stringify(body) : undefined;
      } catch (e) {
        console.warn(e, body);
      }
    }
    this.auth = auth;
    if (this.auth) {
      this.headers.Authorization = `${this.auth.type} ${this.auth.apiKey}`;
    }
    this.method = method || 'GET';
    this.url = url;
    this.mapper = mapper;
    this.appendNewToken = this.appendNewToken.bind(this);
    this.execute = this.execute.bind(this);
    this.retry = this.retry.bind(this);
    this.bugsnag = bugsnag;
  }

  fetchCall() {
    const fetchOptions = {
      method: this.method,
      headers: this.headers,
      body: this.body,
    };
    return fetch(this.url, fetchOptions);
  }

  appendNewToken(tokens: Omit<Auth, 'type'>) {
    if (!this.auth) {
      return;
    }
    if (this.auth.type === 'Bearer') {
      this.auth.apiKey = tokens.apiKey;
    } else if (this.auth.type === 'JWTBearer') {
      this.auth.apiKey = tokens.accessToken;
    }
    this.headers['Authorization' as keyof typeof this.headers] = `${this.auth.type} ${this.auth.apiKey}`;
  }

  execute(): Observable<T> {
    leaveBreadcrumb(this.bugsnag?.breadcrumb || this.bugsnag?.context || 'ApiCall Request', {
      url: this.url,
      method: this.method,
      body: this.body,
      context: this.bugsnag?.context,
      info: this.bugsnag?.info,
    });

    if (this.auth && this.auth.type && !this.auth.apiKey) {
      // can happen if local storage were to get corrupted
      // clear local storage and do clean login / refresh
      // otherwise eternal spinner is the result and user would have to manually clear
      localStorage.removeItem('cfa_token_key');
      localStorage.removeItem('okta_token_key');
      window.location.assign(window.location.origin);
      return throwError(userErrorMessages.NO_TOKEN);
    }
    if (this.mapper) {
      return from(
        this.fetchCall()
          .catch(handleFetchFail)
          .then((response) => handleErrors(response as Response, this.bugsnag))
          .then(this.mapper),
      );
    }
    return from(
      this.fetchCall()
        .catch(handleFetchFail)
        .then((response) => handleErrors(response as Response, this.bugsnag)),
    );
  }

  retry(tokens: Omit<Auth, 'type'>) {
    this.appendNewToken(tokens);
    return this.execute();
  }
}

function request<T>(props: Props<T>) {
  return new ApiCall(props);
}

export default request;
