import { Button } from '@cfa/react-components';
import Section from '../Section';
import SectionHeader from '../SectionHeader';

const setLocationToOrigin = () => {
  window.location.href = window.location.origin;
};

const FullScreenError = () => (
  <Section>
    <SectionHeader>Oops!</SectionHeader>
    <p>There was an issue retrieving your Chick-fil-a account. Please try again or contact support.</p>
    <Button onClick={setLocationToOrigin} variant="secondary">
      Try Again
    </Button>
  </Section>
);

export default FullScreenError;
