import { FormAction, actionTypes as formActionTypes } from 'redux-form';
import { Epic, ofType } from 'redux-observable';
import { filter, ignoreElements, tap } from 'rxjs/operators';

import { UnknownAction } from '@reduxjs/toolkit';
import { actions as cartActions, types as cartTypes } from '../cart/reducer';
import type { State } from '../reducers';
import { actions as dashboardActions, types as dashboardTypes } from '../reducers/dashboard';
import { selectForm } from '../reducers/form';
import { actions as guestActions, types as guestTypes } from '../reducers/guest';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import { selectLocationNumber, actions as userActions, types as userTypes } from '../reducers/user';
import { createSubmitOrderAnalytics, logEvent, setUser, setUserLocation } from '../services/analytics';
import { leaveBreadcrumb, notifyBugsnag } from '../services/bugsnag';
import { roundNumber } from '../util/utils';

export const GenerateBugsnagTestError: Epic<
  ReturnType<(typeof orderActions)['guestCountChanged']>,
  UnknownAction,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.GUEST_COUNT_CHANGED),
    tap(() => {
      const state = store.value;
      const formData = selectForm(state);
      if (formData?.details?.values?.specialInstructions === 'CREATE-BUGSNAG-TEST-ERROR') {
        const testErrorInfo = {
          cfa_perms: state?.user?.cfa_perms,
          cfa_aud: state?.user?.cfa_aud,
        };

        leaveBreadcrumb('Generate Test Error 1', {
          info: testErrorInfo,
        });

        notifyBugsnag('Test Error 1', {
          context: 'Generate Test Error',
          info: testErrorInfo,
        });
      }
    }),
    ignoreElements(),
  );

export const LogCartEvents: Epic<
  | ReturnType<(typeof cartActions)['addToCart']>
  | ReturnType<(typeof cartActions)['updateQuantity']>
  | ReturnType<(typeof cartActions)['deleteItem']>
  | ReturnType<(typeof cartActions)['addModifier']>
  | ReturnType<(typeof cartActions)['updateModifierQuantity']>
  | ReturnType<(typeof cartActions)['updateSpecialInstructions']>
  | ReturnType<(typeof cartActions)['updateSideItem']>
  | ReturnType<(typeof cartActions)['updateDessertItem']>
  | ReturnType<(typeof cartActions)['makePromoFree']>
  | ReturnType<(typeof cartActions)['removePromoFree']>
> = (action$) =>
  action$.pipe(
    ofType(
      cartTypes.ADD_TO_CART,
      cartTypes.UPDATE_QUANTITY,
      cartTypes.DELETE_ITEM,
      cartTypes.ADD_MODIFIER,
      cartTypes.UPDATE_MODIFIER_QUANTITY,
      cartTypes.UPDATE_SPECIAL_INSTRUCTIONS,
      cartTypes.UPDATE_SIDE_ITEM,
      cartTypes.UPDATE_DESSERT_ITEM,
      cartTypes.MAKE_PROMO_FREE,
      cartTypes.REMOVE_PROMO_FREE,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      logEvent(type, payload);
    }),
    ignoreElements(),
  );

export const LogGuestEvents: Epic<
  | ReturnType<(typeof guestActions)['guestSearchEmailSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchEmailSuccess']>
  | ReturnType<(typeof guestActions)['guestSearchPhoneSuccess']>
  | ReturnType<(typeof guestActions)['guestSelected']>
  | ReturnType<(typeof guestActions)['validateZipSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeGuestUnselected']>
  | ReturnType<(typeof guestActions)['addToFavorites']>
  | ReturnType<(typeof guestActions)['removeFromFavorites']>
  | ReturnType<(typeof guestActions)['updateFavoriteName']>
  | ReturnType<(typeof guestActions)['getPastDeliveryAddresses']>
> = (action$) =>
  action$.pipe(
    ofType(
      guestTypes.GUEST_SEARCH_SUCCESS,
      guestTypes.GUEST_SEARCH_EMAIL_SUCCESS,
      guestTypes.GUEST_SEARCH_PHONE_SUCCESS,
      guestTypes.GUEST_SELECTED,
      guestTypes.GUEST_VALIDATE_ZIP_SUCCESS,
      guestTypes.MASQUERADE_GUEST_UNSELECTED,
      guestTypes.ADD_TO_FAVORITES,
      guestTypes.REMOVE_FROM_FAVORITES,
      guestTypes.UPDATE_FAVORITE_NAME,
      guestTypes.GET_PAST_DELIVERY_ADDRESSES,
    ),
    tap((action) => {
      // we don't send the payload for guest events so as to protect sensitive data
      const { type } = action;
      logEvent(type);
    }),
    ignoreElements(),
  );

export const LogDashboardEvents: Epic<
  | ReturnType<(typeof dashboardActions)['getOrders']>
  | ReturnType<(typeof dashboardActions)['getOrdersForSpecificDays']>
  | ReturnType<(typeof dashboardActions)['cancelOrder']>
  | ReturnType<(typeof dashboardActions)['lookupOrderDetails']>
  | ReturnType<(typeof dashboardActions)['resendPaymentEmail']>
  | ReturnType<(typeof dashboardActions)['loadMorePastOrders']>
> = (action$) =>
  action$.pipe(
    ofType(
      dashboardTypes.GET_ORDERS,
      dashboardTypes.GET_ORDERS_FOR_SPECIFIC_DAYS,
      dashboardTypes.CANCEL_ORDER,
      dashboardTypes.LOOKUP_ORDER_DETAILS,
      dashboardTypes.RESEND_PAYMENT_EMAIL,
      dashboardTypes.LOAD_MORE_PAST_ORDERS,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      logEvent(type, payload);
    }),
    ignoreElements(),
  );

export const LogOrderEvents: Epic<
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof orderActions)['dateChanged']>
  | ReturnType<(typeof orderActions)['timeChanged']>
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof orderActions)['addDeliveryTip']>
> = (action$) =>
  action$.pipe(
    ofType(
      orderTypes.CHANGE_DESTINATION,
      orderTypes.DATE_CHANGED,
      orderTypes.TIME_CHANGED,
      orderTypes.INITIATE_EDIT_ORDER,
      orderTypes.EXIT_EDIT_ORDER,
      orderTypes.ADD_DELIVERY_TIP,
    ),
    tap((action) => {
      const { type, ...payload } = action;
      logEvent(type, { ...payload, guest: undefined });
    }),
    ignoreElements(),
  );

export const LogSubmitOrderEvents: Epic<
  | ReturnType<(typeof orderActions)['submitOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderFailure']>,
  | ReturnType<(typeof orderActions)['submitOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.SUBMIT_ORDER, orderTypes.SUBMIT_ORDER_SUCCESS, orderTypes.SUBMIT_ORDER_FAILURE),
    tap((action) => {
      const state = store.value;
      const { type } = action;
      let analytics: object = createSubmitOrderAnalytics(state);
      if ((analytics as { error?: string }).error) {
        notifyBugsnag('Create Submit Order Analytics Failed', {
          context: 'Analytics Error',
          info: analytics,
        });
        return;
      }

      // post process the events to incorporate differences in
      // supplied data vs what is in redux at this point and
      // what ends up becoming invalid
      switch (type) {
        default:
        case orderTypes.SUBMIT_ORDER:
          analytics = { ...analytics, pickupMinNotMet: undefined }; // doesn't seem to be accurate at this point
          break;
        case orderTypes.SUBMIT_ORDER_SUCCESS:
          const payload = action as ReturnType<(typeof orderActions)['submitOrderSuccess']>;
          analytics = {
            ...analytics,
            orderId: payload.order.id,
            taxAmount: payload.order.taxAmount,
            subTotalAmount: payload.order.subTotalAmount,
            reorder: payload.order.reorder,
            totalAmount: roundNumber(
              (payload?.order?.taxAmount || 0) +
                (payload?.order?.subTotalAmount || 0) +
                (payload?.order?.deliveryTip.tipAmount || 0),
            ),
            clientId: payload.response.clientId,
            isEditingOrder: undefined,
            destination: undefined,
            promoFreeItemCount: undefined,
            promoFreeItemsValue: undefined,
          };
          break;
        case orderTypes.SUBMIT_ORDER_FAILURE:
          analytics = {
            ...analytics,
            taxAmount: undefined,
            subTotalAmount: undefined,
            totalAmount: undefined,
            reorder: undefined,
            pickupMinNotMet: undefined,
            isEditingOrder: undefined,
            promoFreeItemCount: undefined,
            promoFreeItemsValue: undefined,
          };
          break;
      }
      logEvent(type, analytics);
    }),
    ignoreElements(),
  );

export const LogFormEvents: Epic<FormAction> = (action$) =>
  action$.pipe(
    ofType(formActionTypes.BLUR),
    filter(({ meta: { field } }) => field !== 'email' && field !== 'phone'),
    tap((action) => {
      const { meta, payload } = action;
      const type = `[${meta.form}] ${meta.field} change`;
      const payloadToPass = { [meta.field]: payload };
      logEvent(type, payloadToPass);
    }),
    ignoreElements(),
  );

export const LogUserAttributes: Epic<
  | ReturnType<(typeof userActions)['getOktaToken']>
  | ReturnType<(typeof userActions)['getOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['getOktaTokenFailure']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenFailure']>
  | ReturnType<(typeof userActions)['refreshOktaToken']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenFailure']>
  | ReturnType<(typeof userActions)['toggleBypass']>
  | ReturnType<(typeof userActions)['logoutUser']>
> = (action$) =>
  action$.pipe(
    ofType(
      userTypes.GET_OKTA_TOKEN,
      userTypes.GET_OKTA_TOKEN_SUCCESS,
      userTypes.GET_OKTA_TOKEN_FAILURE,
      userTypes.PROCESS_OKTA_TOKEN_SUCCESS,
      userTypes.PROCESS_OKTA_TOKEN_FAILURE,
      userTypes.REFRESH_TOKEN,
      userTypes.REFRESH_TOKEN_SUCCESS,
      userTypes.REFRESH_TOKEN_FAILURE,
      userTypes.TOGGLE_BYPASS_BUSINESS_RULES,
      userTypes.LOGOUT_USER,
    ),
    tap((action) => {
      const { type } = action;

      switch (type) {
        case userTypes.PROCESS_OKTA_TOKEN_SUCCESS:
        case userTypes.REFRESH_TOKEN_SUCCESS: {
          const { user } = action as
            | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
            | ReturnType<(typeof userActions)['refreshTokenSuccess']>;
          if (user?.cfa_guid) {
            setUser(user.cfa_guid);
          }
          break;
        }
      }

      logEvent(type);
    }),
    ignoreElements(),
  );

export const SetUserLocation: Epic<
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>,
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.UPDATE_USER_LOCATION, userTypes.GET_USER_LOCATIONS_SUCCESS),
    tap(() => {
      const state = store.value;
      setUserLocation(selectLocationNumber(state));
    }),
    ignoreElements(),
  );

export default [
  LogCartEvents,
  LogGuestEvents,
  LogDashboardEvents,
  LogOrderEvents,
  LogSubmitOrderEvents,
  LogFormEvents,
  LogUserAttributes,
  SetUserLocation,
  GenerateBugsnagTestError,
];
