import { Button, LoadingIndicator } from '@cfa/react-components';
import { Box, Flex } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroy, Field, initialize, reduxForm } from 'redux-form';
import styled from 'styled-components';
import SectionHeader from '../../../components/SectionHeader';

import Icon from '../../../components/Icon';
import Radio from '../../../components/Radio/Radio';
import ooeConstants from '../../../constants';
import {
  actions as guestActions,
  selectCfaOneGuestCFAId,
  selectLoadingMore,
  selectNoMorePastOrdersToLoad,
  selectPastAndFavoriteOrders,
  selectPastAndFavoritesLoading,
  selectPastOrFavoriteError,
} from '../../../reducers/guest';
import PastOrders from '../PastOrders';

const { GUEST } = ooeConstants.GET_FORM_TYPES;

interface Props {
  customer?: GuestSearchResult;
}

interface Values {
  cfaId: string;
}

export const SuggestedCustomer: React.FC<Props> = ({ customer = {} as GuestSearchResult }) => {
  const dispatch = useDispatch();
  const cfaOneGuest = useSelector(selectCfaOneGuestCFAId);
  const pastAndFavoritesLoading = useSelector(selectPastAndFavoritesLoading);
  const pastAndFavoriteOrders = useSelector(selectPastAndFavoriteOrders);
  const pastOrFavoriteError = useSelector(selectPastOrFavoriteError);
  const loadingMore = useSelector(selectLoadingMore);
  const noMorePastOrdersToLoad = useSelector(selectNoMorePastOrdersToLoad);

  const { phone, firstName, lastName, email, cfaId } = customer;
  const guestMatch = cfaOneGuest === cfaId;
  const hasPastOrFavoriteOrders = pastAndFavoriteOrders.length > 0;

  const updateGuestInfo = () => {
    dispatch(
      initialize(GUEST, {
        ...customer,
        first: customer.firstName,
        last: customer.lastName,
      }),
    );
    dispatch(
      guestActions.guestSelected({
        cfaId,
        email,
        phone: phone || '',
        first: firstName,
        last: lastName,
      }),
    );
  };

  const unselect = () => {
    dispatch(destroy(GUEST));
    dispatch(guestActions.masqueradeGuestUnselected());
  };

  const renderUnselectButton = () => (
    <>
      <Button color="secondary" onClick={unselect} size="sm" variant="filled">
        Unselect
      </Button>
      {!pastAndFavoritesLoading && !hasPastOrFavoriteOrders && (
        <div className="no-orders-disclaimer">*No Past Catering Orders</div>
      )}
    </>
  );

  const renderLoadMore = () => {
    let loadMore = null;
    if (loadingMore) {
      loadMore = <LoadingIndicator data-testid="spinner" size="md" variant="inline" />;
    } else if (noMorePastOrdersToLoad) {
      loadMore = <div className="no-more">No more past orders to load</div>;
    } else if (!noMorePastOrdersToLoad && hasPastOrFavoriteOrders) {
      loadMore = (
        <button className="load-more" onClick={() => dispatch(guestActions.getMorePastOrders())}>
          Load More
        </button>
      );
    }
    return loadMore;
  };

  const renderOrders = () => {
    if (pastAndFavoritesLoading) {
      return (
        <>
          <div className="past-orders-title">{`Loading ${firstName} ${lastName}'s Past Catering Orders`}</div>
          <LoadingIndicator
            className="loading-past-orders"
            data-testid="spinner"
            size="md"
            variant="inline"
          />
        </>
      );
    }
    if (!pastAndFavoritesLoading && pastOrFavoriteError) {
      return (
        <div className="error-wrapper">
          <Icon>error</Icon>
          <div className="error-disclaimer">
            Uh oh! There was a problem retrieving Past and Favorite Orders. Please un-select the guest and try
            again, or contact support.
          </div>
        </div>
      );
    }
    return (
      <>
        <PastOrders customer={customer} />
        <Flex justifyContent="center">{renderLoadMore()}</Flex>
        {hasPastOrFavoriteOrders && (
          <div className="past-orders-disclaimer">*Showing favorite and most recent orders</div>
        )}
      </>
    );
  };

  return (
    <div className="suggested-customers">
      <StyledSuggestedCustomers>
        <label htmlFor={cfaId} style={{ position: 'relative' }}>
          <div className="results-group">
            <Field
              checked={guestMatch}
              className="radio"
              component={Radio}
              id={cfaId}
              label=""
              name="cfaId"
              onChange={updateGuestInfo}
            />
            <div className="results">
              <div
                className="name"
                data-cy={`suggested-${firstName?.toLowerCase()}-${lastName?.toLowerCase()}-${customer.cfaId}`}
              >{`${firstName} ${lastName}`}</div>
              <div className="email">{email}</div>
              <div className="phone">{phone}</div>
            </div>
          </div>
          <div className="unselect">{guestMatch && renderUnselectButton()}</div>
        </label>
        {guestMatch && renderOrders()}
        <SectionHeader />
      </StyledSuggestedCustomers>
    </div>
  );
};
const StyledSuggestedCustomers = styled(Box)`
  & label {
    display: flex;
    align-items: center;
    height: 75px;
  }

  & .results-group {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  & .radio {
    flex: 1;
    text-align: center;
  }

  & .results {
    flex: 6;
    display: flex;
  }

  & .name,
  .phone {
    flex: 2;
  }

  & .email {
    flex: 3;
    padding: 0 15px;
  }

  & label:hover {
    cursor: pointer;
  }

  & .unselect {
    width: 110px;

    button {
      min-width: unset;
    }
  }

  & .past-orders-title {
    text-align: center;
    font: ${(props) => props.theme.regularBoldFont};
    margin: 20px;
  }

  & .past-orders-disclaimer {
    margin: 15px;
    text-align: right;
    font: ${(props) => props.theme.smallTextFont};
  }

  & .load-more {
    color: ${(props) => props.theme.colors.primary};
    font: ${(props) => props.theme.regularBoldFont};
    background: none;
    border: none;
  }

  & .loading-past-orders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
  }

  & .no-more {
    font: ${(props) => props.theme.regularBoldFont};
  }

  & .load-more:hover {
    cursor: pointer;
    filter: brightness(150%);
  }

  & .error-disclaimer {
    font: ${(props) => props.theme.smallTextFont};
  }

  & .error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  & .error-wrapper svg {
    margin: 0 5px 3px 0;
    max-height: 18px;
    max-width: 18px;
  }

  & .no-orders-disclaimer {
    font: ${(props) => props.theme.smallTextFont};
    width: 140px;
    text-align: left;
    margin-left: -40px;
  }

  @media (width <= 650px) {
    & label {
      height: 85px;
    }

    & .radio {
      margin: 0 20px;
    }

    & .results {
      display: block;
    }

    & .email {
      padding: 0;
    }
  }
`;

export default reduxForm<Values, Props>({
  form: GUEST,
  destroyOnUnmount: false,
})(SuggestedCustomer);
