import { Button } from '@cfa/react-components';
import React, { PropsWithChildren } from 'react';

interface Props {
  onPrint: () => void;
}

const CreateInvoiceButton: React.FC<PropsWithChildren<Props>> = ({ onPrint, children }) => (
  <Button color="secondary" fullWidth onClick={onPrint} variant="outlined">
    {children}
  </Button>
);

export default CreateInvoiceButton;
