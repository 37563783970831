import React from 'react';
import styled from 'styled-components';
import { Typography, Divider } from '@cfa/react-components';

interface SectionHeaderProps {
  children?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ children, ...props }) => (
  <StyledHeader {...props}>
    <Typography color="default" gutterBottom={false} variant="h4">
      {children}
    </Typography>
    <StyledDivider orientation="horizontal" variant="fullLength" />
  </StyledHeader>
);

const StyledHeader = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0.8rem;
  margin-bottom: 1rem;
`;

export default SectionHeader;
