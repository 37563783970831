import styled from 'styled-components';

const Disclaimer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <StyledDisclaimer data-cy="disclaimer" {...props}>
    {children}
  </StyledDisclaimer>
);

const StyledDisclaimer = styled.div`
  color: ${(props) => props.theme.colors.text};
  font: ${(props) => props.theme.smallTextFont};
  margin-left: 10px;
`;

export default Disclaimer;
