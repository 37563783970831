import { Box, Flex, Input } from '@cfacorp/cowponents';
import { curry } from 'ramda';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPastOrders } from '../../../reducers/guest';
import { actions as orderActions, selectDeliveryAddress } from '../../../reducers/order';
import { ValidatedDeliveryAddress } from '../../../types/order';
import { stripNonPOSSpecialCharacters } from '../../../util/utils';
import AddressWarningModal from '../AddressWarningModal';
import DeliveryDestinationFormHeader from '../DeliveryDestinationFormHeader';
import PastDeliveryAddresses from '../PastDeliveryAddresses';

const DeliveryDestinationForm: React.FC = () => {
  const dispatch = useDispatch();
  const pastOrders = useSelector(selectPastOrders);
  const { companyName, streetNumber, route, subpremise, locality, administrativeAreaLevelOne, postalCode } =
    useSelector(selectDeliveryAddress);

  const autocompleteAddresshandler = () => {
    const addressToValidate = `${streetNumber} ${route} ${subpremise} ${locality} ${administrativeAreaLevelOne} ${postalCode}`;
    if (streetNumber !== '' && route !== '' && postalCode !== '') {
      dispatch(orderActions.autocompleteAddress(addressToValidate));
    }
  };

  const handleDeliveryAddressChange = curry(
    (field: keyof ValidatedDeliveryAddress, e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(orderActions.updateUserAddress(e.target.value, field));
    },
  );

  const handleCompanyNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(
      orderActions.updateUserAddress(stripNonPOSSpecialCharacters(e.target.value) || '', 'companyName'),
    );
  };

  return (
    <Flex flexDirection="column" flexWrap="wrap">
      <AddressWarningModal />
      <DeliveryDestinationFormHeader />
      <Flex flexWrap="wrap">
        <Box width={[1]}>
          <Input
            className="company-name"
            onChange={handleCompanyNameChange}
            placeholder="Company / Building Name"
            type="text"
            value={companyName == null ? '' : companyName}
            width="calc(100% - 14px)"
          />
        </Box>
        <Box width={[1, 1 / 6]}>
          <Input
            data-cy="address"
            maxLength="5"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('streetNumber')}
            placeholder="Str Num (Req)"
            type="text"
            value={streetNumber == null ? '' : streetNumber}
            width="calc(100% - 14px)"
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Input
            data-cy="address"
            maxLength="40"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('route')}
            placeholder="Street Address (Required)"
            type="text"
            value={route == null ? '' : route}
            width="calc(100% - 14px)"
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Input
            data-cy="aptNo"
            maxLength="40"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('subpremise')}
            placeholder="Apartment / Suite"
            type="text"
            value={subpremise == null ? '' : subpremise}
            width="calc(100% - 14px)"
          />
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Input
            data-cy="city"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('locality')}
            placeholder="City"
            type="text"
            value={locality == null ? '' : locality}
            width="calc(100% - 14px)"
          />
        </Box>
        <Box width={[1, 1 / 4]}>
          <Input
            data-cy="state"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('administrativeAreaLevelOne')}
            placeholder="State"
            type="text"
            value={administrativeAreaLevelOne == null ? '' : administrativeAreaLevelOne}
            width="calc(100% - 14px)"
          />
        </Box>
        <Box width={[1, 1 / 4]}>
          <Input
            data-cy="zip"
            onBlur={() => autocompleteAddresshandler()}
            onChange={handleDeliveryAddressChange('postalCode')}
            placeholder="Zip (Required)"
            type="text"
            value={postalCode == null ? '' : postalCode}
            width="calc(100% - 14px)"
          />
        </Box>
      </Flex>
      {pastOrders.length > 0 && <PastDeliveryAddresses />}
    </Flex>
  );
};

export default memo(DeliveryDestinationForm);
