import { createSelector } from '@reduxjs/toolkit';
import ooeConstants from '../constants';

export const selectCart = (state: { cart: CartState }) => state.cart;

export const selectCartItemsLength = createSelector(selectCart, (cart) =>
  cart.reduce((acc, val) => acc + val.quantity, 0),
);

export const selectPromoFreeActive = createSelector(selectCart, (cart) =>
  cart.some((c) => c.promoFree === true),
);

export const selectPromoFreeItemCount = createSelector(selectCart, (cart) => {
  let itemCount = 0;
  cart.forEach((item) => {
    itemCount += item?.promoFree ? item.quantity : 0;
  });
  return itemCount;
});

export const selectShowMaxPromoFreeItemCountWarning = createSelector(selectPromoFreeItemCount, (count) => {
  return count > ooeConstants.MAX_PROMO_FREE_ITEM_QTY;
});

export const selectMaxPromoFreeQtySubmitWarning = createSelector(
  selectShowMaxPromoFreeItemCountWarning,
  (qtyInvalid) => {
    if (qtyInvalid) {
      return {
        type: 'error',
        message: `Total quantity of promo free items must be less than ${ooeConstants.MAX_PROMO_FREE_ITEM_QTY}`,
      };
    }
    return {};
  },
);
