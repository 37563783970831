import styled from 'styled-components';
import { selectOrderHistory } from '../../selectors';

interface OrderHistoryProps {
  orderHistory: ReturnType<typeof selectOrderHistory>;
}

const OrderHistory: React.FC<OrderHistoryProps> = ({ orderHistory }) => {
  return (
    <StyledOrderHistory>
      <h3 data-cy="order-history-label">Order History</h3>
      <div className="table-row header">
        <div className="cell" data-cy="timestamp-label">
          Timestamp
        </div>
        <div className="cell" data-cy="event-label">
          Event
        </div>
      </div>
      {orderHistory.map((history, idx) => (
        <div
          className="table-row wrapper"
          data-cy={`${history.sortableValue}`}
          key={`${history.sortableValue}${idx}`}
        >
          <div className="data cell" data-cy={`timestamp-${idx}`}>
            {history.timestamp}
          </div>
          <div className="data cell" data-cy={`event-${idx}`}>
            {history.event}
          </div>
        </div>
      ))}
    </StyledOrderHistory>
  );
};

const StyledOrderHistory = styled.div`
  margin-top: 40px;
  font: ${(props) => props.theme.regularTextFont};

  & .table-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    min-height: 35px;
    padding-bottom: 0;
  }

  & .header {
    font-weight: bold;
    align-items: center;
    border-bottom: none;
    justify-content: space-around;
  }

  & .table-row:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.background};
  }

  & .cell {
    padding: 10px;
    width: 40%;
  }

  & .cell:nth-child(2) {
    width: 60%;
  }
`;

export default OrderHistory;
