import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectPaperGoodsOptions } from '../../../reducers/order';
import { PaperGoodsOptions } from '../../../types/order';
import PaperGoodsBadge from '../PaperGoodsBadge';

const PaperGoodsBadges: React.FC = () => {
  const paperGoodsOptions = useSelector(selectPaperGoodsOptions);

  const paperGoods = Object.fromEntries(
    Object.entries(paperGoodsOptions).filter(([, option]) => option === true),
  );

  return (
    <StyledPaperGoodsBadges>
      {Object.getOwnPropertyNames(paperGoods).map((option) => (
        <PaperGoodsBadge key={option} name={option as keyof PaperGoodsOptions} />
      ))}
    </StyledPaperGoodsBadges>
  );
};

const StyledPaperGoodsBadges = styled.div`
  display: flex;
  margin-left: 10px;

  @media (width <= 600px) {
    margin: 20px 0 0;
  }
`;

export default PaperGoodsBadges;
