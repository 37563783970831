import { TextField } from '@cfa/react-components';
import { Box, Flex, Select } from '@cfacorp/cowponents';
import { format } from 'date-fns';
import React, { KeyboardEvent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import styled from 'styled-components';

import DatePicker from 'src/eventDetails/components/DatePicker';
import Disclaimer from '../../../components/Disclaimer';
import ValidatedField from '../../../components/ValidatedField/ValidatedField';
import ooeConstants from '../../../constants';
import { selectDateIsToday } from '../../../reducers';
import { actions as formActions, selectDate } from '../../../reducers/form';
import { actions as orderActions, selectDestination } from '../../../reducers/order';
import { selectBypassBusinessRules, selectLocationTimezone } from '../../../reducers/user';
import type { HoursOfOperation } from '../../../types/location';
import { formatTimePickerTime, generateTimeSlots } from '../../../util/format';
import { selectHoursOfOperation, selectSpecialEvents, selectTimeShouldClear } from '../../selectors';
import {
  getServiceChannelHours,
  normalizeGuestCountField,
  validateDetails,
  wrapComponentFormField,
} from '../../utils';
import LeadTimeWarning from '../LeadTimeWarning';
import SelectCateringOccasion from '../SelectCateringOccasion';

interface Values {
  date: Date;
  time: string;
  guestCount: number;
  specialInstructions: string;
}

const DetailsForm: React.FC = () => {
  /* Start with why:
     Because redux form is dumb, and dispatches the onChange event before
     the change action creator, we were getting unexpected behavior
     when trying to predict state when the dateChanged or timeChanged
     actions had been dispatched. This makes it much more predictable
     as the state is updated before the action.

     TODO: remove redux-form
   */
  const dispatch = useDispatch();
  const bypassBusinessRules = useSelector(selectBypassBusinessRules);
  const selectedDate = useSelector(selectDate);
  const dateIsToday = useSelector(selectDateIsToday);
  const timeZone = useSelector(selectLocationTimezone);
  const hoursOfOperation = useSelector(selectHoursOfOperation) || ({} as HoursOfOperation);
  const isDelivery = useSelector(selectDestination) === ooeConstants.DELIVERY;
  const specialEvents = useSelector(selectSpecialEvents);
  const timeShouldClear = useSelector(selectTimeShouldClear);

  const { DETAILS } = ooeConstants.GET_FORM_TYPES;

  let timeSlots: Array<{
    available: boolean;
    time: string;
  }>;

  if (bypassBusinessRules) {
    timeSlots = generateTimeSlots(dateIsToday, timeZone);
  } else if (selectedDate) {
    timeSlots = getServiceChannelHours(hoursOfOperation, specialEvents, selectedDate);
  } else {
    timeSlots = [];
  }

  useEffect(() => {
    if (timeShouldClear) {
      dispatch(formActions.resetTimeFormValues());
    }
  }, [timeShouldClear]);

  const handleDateChange: React.ChangeEventHandler = (e) => {
    const date = e as unknown as Date;
    dispatch(change(DETAILS, 'date', date));
    dispatch(orderActions.dateChanged(format(date, ooeConstants.DATE_TIME_FORMAT.date)));
  };

  const handleTimeChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    event.preventDefault();
    const { value } = event.target;
    dispatch(change(DETAILS, 'time', value));
    dispatch(orderActions.timeChanged());
  };

  const wrappedTextField = useMemo(
    () => wrapComponentFormField(TextField),
    [wrapComponentFormField, TextField],
  );

  const timesPlaceholder = () => {
    if (timeSlots.length === 0) {
      return 'There are no available times for this order date.';
    }

    return 'Select Time';
  };

  function renderAvailableTimes() {
    //Added the slice to the timeSlots so that delivery does not extend past the delivery hours when the 15min range is displayed
    return timeSlots?.slice(0, isDelivery ? -1 : undefined).map((timeSlot) => {
      let formattedTime = formatTimePickerTime(timeSlot.time, isDelivery);
      const timeUnavailable = !timeSlot.available;
      if (timeUnavailable) {
        formattedTime += ' (Time is currently unavailable)';
      }
      return (
        <option className="time-option" disabled={timeUnavailable} key={formattedTime} value={timeSlot.time}>
          {formattedTime}
        </option>
      );
    });
  }

  return (
    <StyledDetailsForm>
      {!bypassBusinessRules && (
        <Disclaimer>Unavailable dates and times are blocked due to settings in DOP</Disclaimer>
      )}
      <Flex display="flex" flexWrap="wrap" m="0 0 20px 0">
        <Box width={[1, 1 / 2]}>
          <Field className="date" component={DatePicker} name="date" onChange={handleDateChange} />
        </Box>
        <Box width={[1, 1 / 2]}>
          {(selectedDate || bypassBusinessRules) && (
            <Field
              className="time"
              component={wrapComponentFormField(Select)}
              disabled={bypassBusinessRules ? false : timeSlots.length === 0}
              m="10px 10px 0 10px"
              name="time"
              onBlur={(e: React.FocusEvent) => e.preventDefault()}
              onChange={handleTimeChange}
              placeholder={timesPlaceholder()}
              type="time"
            >
              {renderAvailableTimes() || (
                <option disabled>There are no available times for this order date.</option>
              )}
            </Field>
          )}
          <div className="lead-time-wrapper">
            <LeadTimeWarning />
          </div>
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <Field
            className="guestCount"
            component={ValidatedField}
            label="Guest Count"
            m="0"
            name="guestCount"
            normalize={normalizeGuestCountField}
            onChange={() => dispatch(orderActions.guestCountChanged())}
            onKeyDown={(e: KeyboardEvent) => ['.', 'e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} // https://stackoverflow.com/a/70084470
            placeholder="Guest Count"
            type="number"
          />
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <SelectCateringOccasion />
        </Box>
      </Flex>
      <Flex fontSize="12px" m="10px 10px 0 10px" width="calc(100% - 14px)">
        Any special instructions written here will show on the email receipt the customer receives upon order
        submission.
      </Flex>
      <Field
        className="specials"
        component={wrappedTextField}
        maxLength={144}
        multiline
        name="specialInstructions"
        placeholder="Special Instructions"
        type="notes"
      />
    </StyledDetailsForm>
  );
};

const StyledDetailsForm = styled(Box)`
  & .specials {
    width: calc(100% - 14px);
    max-width: unset;
    margin-left: 10px;
  }

  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.outline};
    height: 50px;
    box-sizing: border-box;
    margin: 10px;
    width: calc(100% - 14px);
    appearance: none;
    padding-left: 10px;
  }

  & input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    opacity: 0;
  }

  & input::placeholder {
    color: ${(props) => props.theme.colors.outline};
  }

  & input[type='number']:hover::-webkit-inner-spin-button,
  input[type='number']:hover::-webkit-outer-spin-button,
  input[type='number']:focus::-webkit-inner-spin-button,
  input[type='number']:focus::-webkit-outer-spin-button {
    opacity: 1;
  }

  & .lead-time-wrapper {
    position: absolute;
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.phone}) {
      position: relative;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

export default reduxForm<Values>({
  form: ooeConstants.GET_FORM_TYPES.DETAILS,
  destroyOnUnmount: false,
  validate: validateDetails,
})(DetailsForm);
