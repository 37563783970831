import { Box, Heading, Text } from '@cfacorp/cowponents';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Icon from '../../../components/Icon';
import OverlayModal from '../../../components/OverlayModal';
import ooeConstants from '../../../constants';
import { actions as guestActions, selectEmptyCfaOneGuestPhone } from '../../../reducers/guest';

const EmptyCfaPhoneFieldWarningModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectEmptyCfaOneGuestPhone);
  const onClose = () => dispatch(guestActions.closeEmptyCfaPhoneFieldWarning());

  return (
    <StyledEmptyCfaPhoneFiledWarningModal close={onClose} isOpen={isOpen}>
      <Close className="close" onClick={onClose}>
        <Icon>close</Icon>
      </Close>
      <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" m="3em">
        <Icon>warning</Icon>
        <Heading as="h1" fontSize="5" m="1em">
          {ooeConstants.EMPTY_PHONE_FIELD_WARNING_HEADING}
        </Heading>
        <Text color="textColor">{ooeConstants.EMPTY_PHONE_FIELD_WARNING_MESSAGE}</Text>
      </Box>
    </StyledEmptyCfaPhoneFiledWarningModal>
  );
};

export const StyledEmptyCfaPhoneFiledWarningModal = styled(OverlayModal)`
  text-align: center;
  padding: 20px;
`;

export const Close = styled('div')`
  position: absolute;
  top: 0;
  right: 5px;

  & svg {
    margin: 0;
    width: 20px;
    fill: ${(props) => props.theme.colors.outline};
  }

  :hover {
    cursor: pointer;
  }
`;

export default EmptyCfaPhoneFieldWarningModal;
