import { Button } from '@cfa/react-components';
import React, { memo } from 'react';
import styled from 'styled-components';

interface Props {
  message: string;
  confirm: () => void;
  confirmText: string;
  decline: () => void;
  declineText: string;
}

const AreYouSure: React.FC<Props> = ({ message, confirm, confirmText, decline, declineText }) => {
  return (
    <StyledAreYouSure>
      <div className="text">{message}</div>
      <div>
        <Button color="secondary" onClick={decline} variant="outlined">
          {declineText}
        </Button>
        <Button onClick={confirm} variant="destructive">
          {confirmText}
        </Button>
      </div>
    </StyledAreYouSure>
  );
};

const StyledAreYouSure = styled.div`
  width: calc(100% - 20px);
  padding: 10px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & .text {
    font: ${(props) => props.theme.regularBoldFont};
  }

  & button {
    margin: 10px;
  }
`;

export default memo(AreYouSure);
