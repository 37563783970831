import { Box, Flex } from '@cfacorp/cowponents';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';

import Radio from '../../../components/Radio/Radio';
import ooe from '../../../constants';
import { selectCardsLoading, selectVaultedCards } from '../../../reducers/guest';
import { selectHideNewPaymentOption } from '../../../reducers/order';
import { selectAllItemsPromoFree, selectPayLaterNotAllowed } from '../../selectors';
import Defer from '../Defer';
import NewPayment from '../NewPayment';
import VaultedCards from '../VaultedCards';

interface Values {
  selectedMethod: string;
}

const PaymentOptions: React.FC<InjectedFormProps<Values>> = ({ change }) => {
  const vaultedCards = useSelector(selectVaultedCards);
  const cardsLoading = useSelector(selectCardsLoading);
  const hideNewPaymentOption = useSelector(selectHideNewPaymentOption);
  const hidePayLater = useSelector(selectPayLaterNotAllowed);
  const allItemsPromoFree = useSelector(selectAllItemsPromoFree);
  const paymentMethod = useSelector(getFormValues(ooe.GET_FORM_TYPES.PAYMENT_METHOD)) as {
    selectedMethod: string;
  };

  let { selectedMethod } = paymentMethod;

  useEffect(() => {
    if (!cardsLoading && !selectedMethod) {
      selectedMethod = vaultedCards.length > 0 ? ooe.CREDIT : ooe.DEFER;
      change('selectedMethod', selectedMethod);
    } else if (!selectedMethod && !allItemsPromoFree && vaultedCards.length > 0) {
      selectedMethod = ooe.CREDIT;
      change('selectedMethod', selectedMethod);
    } else if (allItemsPromoFree) {
      selectedMethod = ooe.DEFER;
      change('selectedMethod', selectedMethod);
    }
  }, [allItemsPromoFree, cardsLoading]);

  return (
    <StyledPaymentOptions>
      <Flex flexDirection="column">
        {vaultedCards.length > 0 && !allItemsPromoFree && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.CREDIT}
              component={Radio}
              id={ooe.CREDIT}
              label={ooe.CREDIT}
              name="selectedMethod"
            />
            {selectedMethod === ooe.CREDIT && <VaultedCards />}
          </Box>
        )}
        {!hideNewPaymentOption && !allItemsPromoFree && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.REQUEST_PAYMENT}
              className="new-payment"
              component={Radio}
              id={ooe.REQUEST_PAYMENT}
              label={ooe.REQUEST_PAYMENT}
              name="selectedMethod"
            />
            {selectedMethod === ooe.REQUEST_PAYMENT && <NewPayment />}
          </Box>
        )}
        {(!hidePayLater || allItemsPromoFree) && (
          <Box className="radio-option" xs="12">
            <Field
              checked={selectedMethod === ooe.DEFER}
              className="pay-later"
              component={Radio}
              id={ooe.DEFER}
              label={ooe.DEFER}
              name="selectedMethod"
            />
            {selectedMethod === ooe.DEFER && <Defer className="defer" />}
          </Box>
        )}
      </Flex>
    </StyledPaymentOptions>
  );
};

const StyledPaymentOptions = styled.div`
  clear: both;

  & label {
    display: flex;
    width: 100%;
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }

  & .radio-option {
    margin: 5px;
    float: left;
    text-align: center;
    width: 100%;
  }
`;

export default reduxForm<Values>({
  form: ooe.GET_FORM_TYPES.PAYMENT_METHOD,
  destroyOnUnmount: false,
  initialValues: { selectedMethod: '' },
})(PaymentOptions);
