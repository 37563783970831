import { combineEpics, Epic } from 'redux-observable';

import cartEpics from '../cart/epics';
import analyticsEpics from './analytics';
import dashboardEpics from './dashboard';
import deviceEpics from './device';
import formEpics from './form';
import guestEpics from './guest';
import menuEpics from './menu';
import orderEpics from './order';
import userEpics from './user';

const epics: Epic[] = [
  ...menuEpics,
  ...orderEpics,
  ...userEpics,
  ...guestEpics,
  ...formEpics,
  ...dashboardEpics,
  ...cartEpics,
  ...deviceEpics,
];

if (!window.Cypress) {
  epics.push(...analyticsEpics);
}

const combinedEpics = combineEpics(...epics);

export default combinedEpics;
