import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Icon from '../components/Icon';
import ooeConstants from '../constants';
import { logEvent } from '../services/analytics';
import StoreInformation from './components/StoreInformation';

const FloatingMenu: React.FC = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleStoreInfoHandler: React.MouseEventHandler = (e) => {
    e.preventDefault();
    if (isActive === true) {
      setIsActive(false);
    } else {
      logEvent(ooeConstants.AMPLITUDE_EVENT_NAMES.SHOW_STORE_INFO);
      setIsActive(true);
    }
  };

  return (
    <StyledFloatingMenu className="styled-floating">
      <StyledFloatingMenuContent className={!isActive ? '' : 'expanded'}>
        <StoreInformation />
      </StyledFloatingMenuContent>
      {isActive && <div className="under-mask" onClick={toggleStoreInfoHandler} role="presentation" />}
      <button
        className={!isActive ? 'store-info-btn' : 'store-info-btn active'}
        onClick={toggleStoreInfoHandler}
      >
        <Icon height="12" margin="0 5px -1px 0" width="12">
          {isActive ? 'info' : 'info-white'}
        </Icon>
        Store Info
      </button>
    </StyledFloatingMenu>
  );
};

const slideUp = keyframes`
   0% {  max-height: 0; padding: 0 10px; visibility: hidden; }
  100% { max-height: calc(100vh - 184px); visibility: visible; }
`;
const slideDown = keyframes`
   0% {  max-height: calc(100vh - 184px); visibility: hidden; }
  100% { max-height: 0px; padding: 0 10px; visibility: visible; }
`;

export const StyledFloatingMenuContent = styled('div')`
  font: ${(props) => props.theme.regularTextFont};
  position: absolute;
  bottom: 38px;
  right: 0;
  width: 330px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0;
  background-color: ${(props) => props.theme.colors.backgroundBlur};
  border-radius: 10px;
  border-bottom-right-radius: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: 250ms;
  animation-name: ${slideDown};
  overflow: hidden;

  &.expanded {
    animation-name: ${slideUp};
    box-shadow: 0 0 10px 3px #00000026;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    background-color: ${(props) => props.theme.colors.background};
    width: 300px;
    font-size: 14px;
  }
`;

export const StyledFloatingMenu = styled('div')`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 202;

  & .store-info-btn {
    padding: 10px 20px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 30px;
    color: #fff;
    font: ${(props) => props.theme.regularTextFont};
    border: none;
    transition:
      border,
      color,
      0.2s ease-in-out;
    transition-delay: 0.25s;
    position: relative;
    cursor: pointer;
    box-shadow: 0 0 10px #737373;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .store-info-btn.active {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.primary};
    border-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transition:
      border,
      color,
      0.2s ease-in-out;
    box-shadow: 0 7px 9px 1px #00000026;
  }

  & .under-mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  & .badge {
    font: ${(props) => props.theme.regularTextFont};
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 30px;
    position: absolute;
    top: -15px;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    @media (max-width: ${(props) => props.theme.small}) {
      right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    bottom: 100px;
    right: 20px;
  }

  @media (max-width: ${(props) => props.theme.small}) {
    bottom: 80px;
    right: 0;
  }
`;

export default FloatingMenu;
