import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';

import PaperGoodsToggle from '../PaperGoodsToggle';

const PaperGoodsOptions: React.FC = () => {
  return (
    <StyledPaperGoodsOptions>
      <Box>
        <Flex flexDirection="column">
          <PaperGoodsToggle id="plates" />
          <PaperGoodsToggle id="cutlery" />
          <PaperGoodsToggle id="napkins" />
          <PaperGoodsToggle id="cups" />
        </Flex>
      </Box>
    </StyledPaperGoodsOptions>
  );
};

export const StyledPaperGoodsOptions = styled.div`
  text-align: center;
  padding: 20px;
  max-width: 400px;
`;

export default PaperGoodsOptions;
