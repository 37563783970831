import { format, parseISO } from 'date-fns';

import ooeConstants from '../constants';

export function formatReadableDate(dateTime: string) {
  return format(parseISO(dateTime), ooeConstants.DATE_TIME_FORMAT.readableDate);
}

export function validateGuest(values: { first?: string; last?: string; email?: string; phone?: string }) {
  const errors: {
    first?: string;
    last?: string;
    email?: string;
    phone?: string;
  } = {};
  if (!values.first) {
    errors.first = 'Required Field';
  }
  if (!values.last) {
    errors.last = 'Required Field';
  }
  if (!values.email) {
    errors.email = 'Required Field';
  } else if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.phone) {
    errors.phone = 'Required Field';
  } else if (values.phone && !ooeConstants.PHONE_REGEX.test(values.phone)) {
    errors.phone = 'Please enter valid phone number';
  }
  return errors;
}
