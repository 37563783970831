import { Input } from '@cfacorp/cowponents';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectSelectedCustomTipValue } from '../../../reducers/order';

interface Props {
  onBlurHandler: React.FocusEventHandler<HTMLInputElement>;
  isSelect?: boolean;
}

export const DeliveryTipsCustomAmount: React.FC<Props> = ({ isSelect = false, onBlurHandler }) => {
  const selectedCustomTipValue = useSelector(selectSelectedCustomTipValue);

  return (
    <StyledCustomTipAmount isSelect={isSelect} selectedCustomTipValue={selectedCustomTipValue}>
      <Input
        bg={isSelect || selectedCustomTipValue !== 0 ? '#004F71' : '#fff'}
        borderColor="#004F71"
        className="custom-amount-input"
        color={isSelect || selectedCustomTipValue !== 0 ? '#fff' : '#004F71'}
        defaultValue={selectedCustomTipValue !== 0 ? selectedCustomTipValue : ''}
        height="35px"
        id="customTip"
        m="5px"
        onBlur={onBlurHandler}
        p="1px 1px 1px 20px"
        placeholder="Custom Tip"
        type="number"
        width="calc(100% - 10px)"
      />
    </StyledCustomTipAmount>
  );
};

export const StyledCustomTipAmount = styled.div<{ isSelect: boolean; selectedCustomTipValue: number }>`
  position: relative;

  & input {
    text-align: center;
  }

  &::after {
    content: '$';
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    color: ${(props) =>
      props.isSelect || props.selectedCustomTipValue !== 0 ? '#ffff' : props.theme.colors.primary};
  }
`;

export default DeliveryTipsCustomAmount;
