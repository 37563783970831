import styled from 'styled-components';

import { Location, LocationSummary } from 'src/types/location';
import { useDispatch } from 'react-redux';
import { actions as userActions } from 'src/reducers/user';
import CartLink from './CartLink';
import UserLocation from './UserLocation';

interface RightDesktopProps {
  icon: string;
  cartItemCount: number;
  total: string;
  loading: boolean;
  userName: string;
  locations: Location[];
  selectedLocation: string;
  shouldBypassBusinessRules?: boolean;
  isAdminUser?: boolean;
  locationDropdownDisabled?: boolean;
  allStoreNamesAndNumbers: LocationSummary[];
}

const RightDesktop: React.FC<RightDesktopProps> = ({
  icon,
  cartItemCount,
  total,
  loading,
  userName,
  locations,
  selectedLocation,
  shouldBypassBusinessRules,
  isAdminUser,
  locationDropdownDisabled = false,
  allStoreNamesAndNumbers,
}) => {
  const dispatch = useDispatch();

  return (
    <StyledRightDesktop>
      <div className="user-location">
        <div className="user">
          {`${userName} `}(
          <button className="sign-out" onClick={() => dispatch(userActions.logoutUser())}>
            sign out
          </button>
          )
        </div>
        {isAdminUser && (
          <div className="bbr">
            Bypass Business Rules:{' '}
            <button onClick={() => dispatch(userActions.toggleBypass())}>
              {shouldBypassBusinessRules ? 'On' : 'Off'}
            </button>
          </div>
        )}
        <UserLocation
          allStoreNamesAndNumbers={allStoreNamesAndNumbers}
          locationDropdownDisabled={locationDropdownDisabled}
          locations={locations}
          selectedLocation={selectedLocation}
        />
      </div>
      <CartLink
        cartItemCount={cartItemCount}
        dataCy="cart-badge"
        icon={icon}
        loading={loading}
        total={total}
      />
    </StyledRightDesktop>
  );
};

const StyledRightDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;

  & .user-location {
    font: ${(props) => props.theme.smallTextFont};
    color: ${(props) => props.theme.colors.text};
  }

  & .user {
    padding: 0 7px;
  }

  & .bbr {
    padding: 0 7px;

    button {
      cursor: pointer;
      font: ${(props) => props.theme.regularBoldFont};
      color: ${(props) => props.theme.colors.primary};
    }
  }

  & .sign-out {
    color: ${(props) => props.theme.colors.primary};
    border: none;
    background-color: ${(props) => props.theme.colors.background};
    padding: 2px;
  }

  & .sign-out:hover {
    cursor: pointer;
  }
`;

export default RightDesktop;
