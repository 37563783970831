import styled from 'styled-components';
import Icon from '../Icon';
import Section from '../Section';

interface Props {
  message: string;
}

const EmptyCart: React.FC<Props> = ({ message }) => {
  return (
    <StyledEmptyCart className="empty-cart">
      <Icon>bag</Icon>
      <div className="message">{message}</div>
    </StyledEmptyCart>
  );
};

const StyledEmptyCart = styled(Section)`
  fill: ${(props) => props.theme.colors.text};
  margin: 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .message {
    font: ${(props) => props.theme.smallTextFont};
    margin: 0;
  }
`;

export default EmptyCart;
