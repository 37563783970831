import { Button, LoadingIndicator } from '@cfa/react-components';
import { constructNow, getUnixTime, parseISO, startOfDay } from 'date-fns';
import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Section from 'src/components/Section';
import SectionHeader from 'src/components/SectionHeader';
import { DashboardOrder } from 'src/types/dashboard';
import EmptyCart from '../components/EmptyCart';
import { actions as dashboardActions } from '../reducers/dashboard';
import DashboardHeader from './components/DashboardHeader/DashboardHeader';
import DashboardTable from './components/DashboardTable/DashboardTable';
import {
  selectExtractOrdersDataLoading,
  selectOrders,
  selectOrdersAreLoading,
  selectOrdersError,
} from './selectors';

const capitalize = ([first, ...rest]: string) => (first ? first.toUpperCase() + rest.join('') : '');

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const orders = useSelector(selectOrders);
  const ordersAreLoading = useSelector(selectOrdersAreLoading);
  const ordersError = useSelector(selectOrdersError);
  const exportExcelOrdersLoading = useSelector(selectExtractOrdersDataLoading);
  const [orderType, setOrderType] = useState('upcoming');
  const isUpcoming = orderType === 'upcoming';

  const onChangeHandler = (value: string) => {
    setOrderType(value.toLowerCase());
  };

  const onCalendarOrdersType = (type: string) => {
    setOrderType(type);
  };

  const handleTryAgain = () => {
    dispatch(dashboardActions.getOrders());
  };

  const renderOrders = (type: string) => {
    const renderableOrders = orders.filter((order: DashboardOrder) => {
      const diff =
        getUnixTime(parseISO(order.promiseDateTime || '')) -
        getUnixTime(startOfDay(constructNow(new Date())));
      if (type === 'upcoming') {
        return diff >= 0;
      }
      return diff < 0;
    });
    const noOrders = orders.length === 0;

    if (ordersAreLoading) {
      return (
        <StyledSpinnerWrapper>
          <LoadingIndicator data-testid="spinner" size="md" variant="page" />
        </StyledSpinnerWrapper>
      );
    }
    if (ordersError) {
      return (
        <>
          <SectionHeader>Oops!</SectionHeader>
          <p>
            There was a problem retrieving
            {` ${type} `}
            orders. Please try again or contact support.
          </p>
          <Button color="secondary" onClick={handleTryAgain} size="md">
            Try Again
          </Button>
        </>
      );
    }
    if (noOrders) {
      return <EmptyCart message={`No ${capitalize(type)} Orders`} />;
    }
    return (
      <>
        <DashboardTable orders={renderableOrders} type={type} />
      </>
    );
  };

  return (
    <Section>
      <DashboardHeader
        exportExcelOrdersLoading={exportExcelOrdersLoading}
        onCalendarOrdersType={onCalendarOrdersType}
        onSwitchTabs={onChangeHandler}
      />
      <div className="upcoming-table" style={isUpcoming ? {} : { display: 'none' }}>
        {renderOrders('upcoming')}
      </div>
      <div className="past-table" style={isUpcoming ? { display: 'none' } : {}}>
        {renderOrders('past')}
      </div>
    </Section>
  );
};

const StyledSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20vh auto;
`;

export default memo(Dashboard);
