import { Button } from '@cfa/react-components';
import styled from 'styled-components';

import Icon from 'src/components/Icon';

interface DashboardActionButtonProps {
  icon: string;
  onClick?: () => void;
  buttonText: string;
  disabled?: boolean;
}

const DashboardActionButton: React.FC<DashboardActionButtonProps> = ({
  icon,
  onClick,
  buttonText,
  disabled = false,
}) => {
  return (
    <StyledDashboardActionButton>
      <Button
        className="action-button"
        color="secondary"
        disabled={disabled}
        onClick={onClick}
        size="md"
        variant="text"
      >
        <Icon height="35px" width="35px">
          {icon}
        </Icon>
        <div>{buttonText}</div>
      </Button>
    </StyledDashboardActionButton>
  );
};

const StyledDashboardActionButton = styled.div`
  & .action-button {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    font: ${(props) => props.theme.smallBoldFont};
    margin: 20px;
    padding: 1px 7px 2px;
    background: ${(props) => props.theme.colors.background};
    min-width: unset;
  }

  & .action-button svg {
    margin: 0;
    min-height: 35px;
    fill: ${(props) => props.theme.colors.primary};
  }

  & .action-button:hover {
    cursor: pointer;
  }

  & .action-button:focus {
    outline: none;
  }

  :hover {
    background: #0003;

    .action-button {
      background: fixed;
    }
  }

  & .disabled,
  .disabled svg {
    color: ${(props) => props.theme.colors.outline} !important;
    fill: ${(props) => props.theme.colors.outline};
    border: none;
    cursor: not-allowed;
  }
`;

export default DashboardActionButton;
