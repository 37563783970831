import { Button, LoadingIndicator } from '@cfa/react-components';
import { Box, Flex, Input } from '@cfacorp/cowponents';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Error from '../../../assets/error.svg';
import Success from '../../../assets/success.svg';
import Icon from '../../../components/Icon';
import ooeConstants from '../../../constants';
import {
  actions as guestActions,
  selectCardSelected,
  selectCardsLoading,
  selectVaultedCards,
  selectZipLoading,
} from '../../../reducers/guest';
import { isExpired } from '../../utils';

const VaultedCards: React.FC = () => {
  const dispatch = useDispatch();

  const vaultedCards = useSelector(selectVaultedCards);
  const cardsLoading = useSelector(selectCardsLoading);
  const cardSelected = useSelector(selectCardSelected);
  const zipLoading = useSelector(selectZipLoading);

  const [cardToValidate, setCardToValidate] = useState<string | null | undefined>(null);
  const [zip, setZip] = useState('');

  useEffect(() => {
    setCardToValidate(cardSelected !== 'error' ? cardSelected.generatedId : null);
  }, [cardSelected]);

  const toggleValidateMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, card: Card) => {
    e.preventDefault();
    setCardToValidate(card.generatedId);
    setZip('');
  };

  const callValidateZip = (event: React.ChangeEvent<HTMLInputElement>, card: Card) => {
    const enteredZip = event.target.value;
    setZip(enteredZip);
    if (enteredZip.length >= 5) {
      dispatch(guestActions.validateZip(enteredZip, card));
    }
  };

  const renderZipValidation = (card: Card) => {
    if (
      cardToValidate === card.generatedId ||
      (cardSelected !== 'error' && cardSelected.generatedId === card.generatedId)
    ) {
      if (zipLoading) {
        return <LoadingIndicator className="spinner" size="sm" variant="inline" />;
      }
      if (card.validated) {
        return <img alt="Success" className="success" src={Success} />;
      }
      return (
        <>
          <Input
            autoFocus
            data-cy="card-zip"
            height="37px"
            m="0.5em"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => callValidateZip(e, card)}
            placeholder="Zip"
            value={zip}
            width="75px"
          />
          {cardSelected === 'error' && <img alt="Error" className="error" src={Error} />}
        </>
      );
    }
    if (isExpired(card)) {
      return <div>Card Expired</div>;
    }
    return (
      <Button color="secondary" onClick={(e) => toggleValidateMode(e, card)} style={{ minWidth: 'unset' }}>
        Select
      </Button>
    );
  };

  return (
    <StyledVaultedCards>
      {cardsLoading ? (
        <Flex>
          <LoadingIndicator className="card-spinner" size="sm" variant="inline" />
        </Flex>
      ) : (
        vaultedCards !== 'error' &&
        vaultedCards?.map?.((card) => (
          <Flex alignItems="center" className={isExpired(card) && 'expired'} key={card.generatedId}>
            <Box width={1 / 6}>
              <Icon>{card.cardType?.toLowerCase?.()}</Icon>
            </Box>
            <Flex alignItems="center" width={1 / 3}>
              {card.cardType !== ooeConstants.paypal && (
                <>
                  <span className="card-number">
                    &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679;&nbsp;
                  </span>
                  &#9679;&#9679;&#9679;&#9679;
                </>
              )}
              {card.accountDisplay}
            </Flex>
            <Box className="zip-container" width={5 / 12}>
              {renderZipValidation(card)}
            </Box>
          </Flex>
        ))
      )}
    </StyledVaultedCards>
  );
};

const StyledVaultedCards = styled.div`
  clear: both;

  & .zip-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }

  & .spinner {
    text-align: right !important;
  }

  & .success {
    width: 28px;
    margin-right: 30px;
  }

  & .error {
    width: 23px;
    position: absolute;
    right: -25px;
    top: 14px;
  }

  & .expired {
    opacity: 0.3;
    pointer-events: none;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .card-number {
      display: none;
    }
  }
`;

export default VaultedCards;
