import { equals, isNil } from 'ramda';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, ignoreElements, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  autocompleteAddressFromApi,
  editExistingOrder,
  getOrderDates,
  initiateOrder,
  profileUpdateApi,
  requestPaymentApi,
  updateOrder,
  validateAddressFromApi,
  validateDeliveryRange,
} from '../apis';
import { actions as cartActions, types as cartTypes } from '../cart/reducer';
import { selectCart } from '../cart/selectors';
import ooeConstants from '../constants';
import {
  selectDestinationNotValidForLocation,
  selectOrderForAPI,
  selectOrderWithPayment,
  State,
} from '../reducers';
import {
  actions as formActions,
  types as formTypes,
  selectFullName,
  selectGuestForProfileApi,
  selectNewPaymentMethod,
} from '../reducers/form';
import {
  actions as guestActions,
  types as guestTypes,
  selectApiKey,
  selectIsCfaOneGuestSelected,
} from '../reducers/guest';
import {
  keys,
  actions as orderActions,
  types as orderTypes,
  selectApartmentSuite,
  selectDeliveryAddress,
  selectDeliveryAddressValid,
  selectEditOrderId,
  selectLastAddressSentForValidation,
  selectOrder,
} from '../reducers/order';
import {
  selectBypassBusinessRules,
  selectLocationNumber,
  actions as userActions,
  types as userTypes,
} from '../reducers/user';
import { leaveBreadcrumb } from '../services/bugsnag';
import type Order from '../types/order';
import { DOPErrorMessages } from '../util/customerErrorMessages';
import history from '../util/history';
import epicHelper, { epicOptions } from '../util/myEpicHelper';

export const GoToCart: Epic<
  ReturnType<(typeof cartActions)['addToCart']>,
  ReturnType<(typeof cartActions)['addToCart']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(cartTypes.ADD_TO_CART),
    tap(() => {
      const state = store.value;
      const cart = selectCart(state);
      const itemsLength = cart.length;
      const idx = itemsLength - 1;
      const { id } = cart[idx];
      const isCartRoute = history.location.state && history.location.state.modal;
      const locationState = {
        state: { modal: true, cartItemId: id },
      };
      // Navigate to cart and add tag to state so it will scroll to that section of the cart
      // Do not navigate to cart via push if already on cart page
      if (isCartRoute) {
        return history.replace(locationState);
      }
      return history.push(locationState);
    }),
    ignoreElements(),
  );

export const GoToSubmitPage: Epic<
  ReturnType<(typeof orderActions)['submitOrderSuccess']>,
  ReturnType<(typeof orderActions)['submitOrderSuccess']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.SUBMIT_ORDER_SUCCESS),
    // Navigate to submit page and name and order to state so we have it on the submit page
    tap((action) => {
      const { order } = action;
      const state = store.value;
      const guestName = selectFullName(state);
      const isCfaOneGuest = selectIsCfaOneGuestSelected(state);
      return history.push({
        pathname: '/submit',
        state: {
          order,
          guestName,
          isCfaOneGuest,
        },
      });
    }),
    ignoreElements(),
  );

export const UpdatePricing: Epic<
  | ReturnType<(typeof cartActions)['addToCart']>
  | ReturnType<(typeof cartActions)['addModifier']>
  | ReturnType<(typeof cartActions)['updateSideItem']>
  | ReturnType<(typeof cartActions)['updateDessertItem']>
  | ReturnType<(typeof cartActions)['updateQuantity']>
  | ReturnType<(typeof cartActions)['updateModifierQuantity']>
  | ReturnType<(typeof cartActions)['deleteItem']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof orderActions)['timeChanged']>
  | ReturnType<(typeof orderActions)['dateChanged']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeGuestUnselected']>
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof orderActions)['guestCountChanged']>
  | ReturnType<(typeof cartActions)['removeNonExistentItems']>
  | ReturnType<(typeof orderActions)['addDeliveryTip']>
  | ReturnType<(typeof orderActions)['updatePricing']>
  | ReturnType<(typeof cartActions)['makePromoFree']>
  | ReturnType<(typeof cartActions)['removePromoFree']>
  | ReturnType<(typeof formActions)['addSecondaryContact']>
  | ReturnType<(typeof orderActions)['updateOrderFailure']>
  | ReturnType<(typeof orderActions)['updateOrderSuccess']>,
  | ReturnType<(typeof cartActions)['addToCart']>
  | ReturnType<(typeof cartActions)['addModifier']>
  | ReturnType<(typeof cartActions)['updateSideItem']>
  | ReturnType<(typeof cartActions)['updateDessertItem']>
  | ReturnType<(typeof cartActions)['updateQuantity']>
  | ReturnType<(typeof cartActions)['updateModifierQuantity']>
  | ReturnType<(typeof cartActions)['deleteItem']>
  | ReturnType<(typeof guestActions)['guestSessionSuccess']>
  | ReturnType<(typeof orderActions)['timeChanged']>
  | ReturnType<(typeof orderActions)['dateChanged']>
  | ReturnType<(typeof guestActions)['masqueradeSessionSuccess']>
  | ReturnType<(typeof guestActions)['masqueradeGuestUnselected']>
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof orderActions)['guestCountChanged']>
  | ReturnType<(typeof cartActions)['removeNonExistentItems']>
  | ReturnType<(typeof orderActions)['addDeliveryTip']>
  | ReturnType<(typeof orderActions)['updatePricing']>
  | ReturnType<(typeof cartActions)['makePromoFree']>
  | ReturnType<(typeof cartActions)['removePromoFree']>
  | ReturnType<(typeof formActions)['addSecondaryContact']>
  | ReturnType<(typeof orderActions)['updateOrderFailure']>
  | ReturnType<(typeof orderActions)['updateOrderSuccess']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(
      cartTypes.ADD_TO_CART,
      cartTypes.ADD_MODIFIER,
      cartTypes.UPDATE_SIDE_ITEM,
      cartTypes.UPDATE_DESSERT_ITEM,
      cartTypes.UPDATE_QUANTITY,
      cartTypes.UPDATE_MODIFIER_QUANTITY,
      cartTypes.DELETE_ITEM,
      guestTypes.GUEST_SESSION_SUCCESS,
      orderTypes.TIME_CHANGED,
      orderTypes.DATE_CHANGED,
      guestTypes.GUEST_MASQUERADE_SESSION_SUCCESS,
      guestTypes.MASQUERADE_GUEST_UNSELECTED,
      orderTypes.VALIDATE_ADDRESS_SUCCESS,
      orderTypes.GUEST_COUNT_CHANGED,
      cartTypes.REMOVE_NON_EXISTENT_ITEMS,
      orderTypes.ADD_DELIVERY_TIP,
      orderTypes.UPDATE_PRICING,
      cartTypes.MAKE_PROMO_FREE,
      cartTypes.REMOVE_PROMO_FREE,
      formTypes.ADD_SECONDARY_CONTACT,
    ),
    debounceTime(ooeConstants.DXE_DELAY_TIME),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const order = selectOrderForAPI(state);

      // Check DOP, update order failure if location does not accept orders for selected destination
      const destinationNotValidForLocation = selectDestinationNotValidForLocation(state);
      if (destinationNotValidForLocation) {
        const error = DOPErrorMessages.DESTINATION_INVALID;
        return of(orderActions.updateOrderFailure(error));
      }

      let orderToSend = order;

      // TODO: talk to DXE about ability pass previous order ID so we can chain all order updates
      // Always set order id to undefined
      orderToSend = { ...orderToSend, id: undefined };

      leaveBreadcrumb('Update Pricing', {
        message: 'Update pricing via initiateOrder',
        orderToSend,
      });

      return epicHelper(
        initiateOrder(apiKey, orderToSend),
        orderActions.updateOrderSuccess,
        orderActions.updateOrderFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const SubmitOrder: Epic<
  | ReturnType<(typeof orderActions)['submitOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderFailure']>,
  | ReturnType<(typeof orderActions)['submitOrder']>
  | ReturnType<(typeof orderActions)['submitOrderSuccess']>
  | ReturnType<(typeof orderActions)['submitOrderFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.SUBMIT_ORDER),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const newPaymentMethod = selectNewPaymentMethod(state);
      const cfaOneGuestSelected = selectIsCfaOneGuestSelected(state);
      const orderWithPayment = selectOrderWithPayment(state);
      const editOrderId = selectEditOrderId(state);

      leaveBreadcrumb('Submit Order', {
        message: 'In SubmitOrder',
        newPaymentMethod,
        cfaOneGuestSelected,
        orderWithPayment,
        editOrderId,
      });

      let orderSubmit$: Observable<any> = of({});

      // If checking out as a guest make a call to the profileUpdateApi before submitting the order
      if (!cfaOneGuestSelected) {
        const guestProfile = selectGuestForProfileApi(state);
        orderSubmit$ = profileUpdateApi(apiKey, guestProfile).execute();
      }

      // Submit the order and subsequently assign the response to the variable order so that
      // it can be used in the requestPayment call if needed
      orderSubmit$ = orderSubmit$.pipe(
        switchMap(() => {
          if (editOrderId && !newPaymentMethod) {
            return editExistingOrder(apiKey, orderWithPayment).execute();
          }
          return updateOrder(apiKey, orderWithPayment).execute();
        }),
      );

      if (newPaymentMethod) {
        orderSubmit$ = orderSubmit$.pipe(
          switchMap((order: Order) => requestPaymentApi(apiKey, order.id).execute()),
        );
      }

      // Display order is the order that will be displayed on the
      // success page. It is passed into submitOrderSuccess.
      const displaySuccessfulOrder = selectOrder(state);

      return epicHelper(
        orderSubmit$,
        orderActions.submitOrderSuccess(displaySuccessfulOrder),
        orderActions.submitOrderFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GetOrderDates: Epic<
  | ReturnType<(typeof orderActions)['updateOrderSuccess']>
  | ReturnType<(typeof userActions)['toggleBypass']>
  | ReturnType<(typeof orderActions)['getDatesSuccess']>
  | ReturnType<(typeof orderActions)['getDatesFailure']>,
  | ReturnType<(typeof orderActions)['updateOrderSuccess']>
  | ReturnType<(typeof userActions)['toggleBypass']>
  | ReturnType<(typeof orderActions)['getDatesSuccess']>
  | ReturnType<(typeof orderActions)['getDatesFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.UPDATE_ORDER_SUCCESS, userTypes.TOGGLE_BYPASS_BUSINESS_RULES),
    // Do not get date slots if user has bypassBusinessRules
    filter(() => {
      const state = store.value;
      return !selectBypassBusinessRules(state);
    }),
    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const order = selectOrder(state) as Order;

      leaveBreadcrumb('Get Order Dates', {
        message: 'Calling getOrderDates',
        order,
      });

      return epicHelper(
        getOrderDates(apiKey, order.id),
        orderActions.getDatesSuccess,
        orderActions.getDatesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const AutocompleteAddress: Epic<
  | ReturnType<(typeof orderActions)['autocompleteAddress']>
  | ReturnType<(typeof orderActions)['autocompleteAddressSuccess']>
  | ReturnType<(typeof orderActions)['autocompleteAddressFailure']>,
  | ReturnType<(typeof orderActions)['autocompleteAddress']>
  | ReturnType<(typeof orderActions)['autocompleteAddressSuccess']>
  | ReturnType<(typeof orderActions)['autocompleteAddressFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.AUTOCOMPLETE_ADDRESS),
    filter((action) => {
      const { addressEnteredInForm } = action as ReturnType<(typeof orderActions)['autocompleteAddress']>;
      const state = store.value;
      const lastAddressSentForValidation = selectLastAddressSentForValidation(state);
      return !equals(addressEnteredInForm, lastAddressSentForValidation);
    }),
    switchMap((action) => {
      const { addressEnteredInForm } = action as ReturnType<(typeof orderActions)['autocompleteAddress']>;
      const state = store.value;
      const apiKey = selectApiKey(state) as string;

      leaveBreadcrumb('Autocomplete Address', {
        message: 'Calling autocompleteAddressFromApi',
        addressEnteredInForm,
      });

      return epicHelper(
        autocompleteAddressFromApi(addressEnteredInForm, apiKey),
        orderActions.autocompleteAddressSuccess(addressEnteredInForm),
        orderActions.autocompleteAddressFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ValidateAddress: Epic<
  | ReturnType<(typeof orderActions)['autocompleteAddressSuccess']>
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof orderActions)['validateAddressFailure']>,
  | ReturnType<(typeof orderActions)['autocompleteAddressSuccess']>
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof orderActions)['validateAddressFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.AUTOCOMPLETE_ADDRESS_SUCCESS),
    filter((action) => {
      const { response } = action as ReturnType<(typeof orderActions)['autocompleteAddressSuccess']>;
      return response.length > 0;
    }),
    switchMap((action) => {
      const { addressEnteredInForm, response } = action as ReturnType<
        (typeof orderActions)['autocompleteAddressSuccess']
      >;
      const state = store.value;
      let placeIndex = 0;
      if (response.length > 1) {
        placeIndex = response.findIndex((element) => {
          const arr = element.structuredFormatting.secondaryText.toLowerCase().split(', ');
          return arr.some(
            (e) =>
              e ===
              `${state.order?.validatedDeliveryAddress.administrativeAreaLevelOne?.toLowerCase()} ${state.order?.validatedDeliveryAddress.postalCode?.toLowerCase()}`,
          );
        });
      }
      const autocompletedAddress = response[placeIndex === -1 ? 0 : placeIndex];
      const apiKey = selectApiKey(state) as string;
      const { structuredFormatting } = autocompletedAddress;
      const { mainText } = structuredFormatting;
      const apartment = selectApartmentSuite(state);
      const mainTextWithApartment = `${mainText} ${apartment}`;
      const addressForValidation = {
        autocompleteResult: {
          ...autocompletedAddress,
          structuredFormatting: {
            ...structuredFormatting,
            mainText: mainTextWithApartment,
          },
        },
      };

      leaveBreadcrumb('Validate Address', {
        message: 'Calling validateAddressFromApi',
        addressEnteredInForm,
        addressForValidation,
      });

      return epicHelper(
        validateAddressFromApi(addressForValidation, apiKey),
        orderActions.validateAddressSuccess(addressEnteredInForm),
        orderActions.validateAddressFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const ValidateDeliveryRange: Epic<
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof orderActions)['validateDeliveryRangeSuccess']>
  | ReturnType<(typeof orderActions)['validateDeliveryRangeFailure']>,
  | ReturnType<(typeof orderActions)['validateAddressSuccess']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof orderActions)['validateDeliveryRangeSuccess']>
  | ReturnType<(typeof orderActions)['validateDeliveryRangeFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.VALIDATE_ADDRESS_SUCCESS, userTypes.UPDATE_USER_LOCATION),
    filter((action) => {
      const { response } = action as ReturnType<(typeof orderActions)['validateAddressSuccess']>;
      const state = store.value;
      const cfaError = response?.cfaError;
      const isValid = selectDeliveryAddressValid(state);
      return isNil(cfaError) && isValid;
    }),

    switchMap(() => {
      const state = store.value;
      const apiKey = selectApiKey(state) as string;
      const deliveryRangeAddress = selectDeliveryAddress(state);
      const selectedLocation = selectLocationNumber(state);

      leaveBreadcrumb('Validate Delivery Range', {
        message: 'Calling validateDeliveryRange',
        deliveryRangeAddress,
        selectedLocation,
      });

      return epicHelper(
        validateDeliveryRange(deliveryRangeAddress, apiKey),
        orderActions.validateDeliveryRangeSuccess(selectedLocation),
        orderActions.validateDeliveryRangeFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const GoToMenuAndUpdatePricing: Epic<
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof orderActions)['updatePricing']>,
  | ReturnType<(typeof orderActions)['initiateEditOrder']>
  | ReturnType<(typeof orderActions)['exitEditOrder']>
  | ReturnType<(typeof orderActions)['updatePricing']>
> = (action$) =>
  action$.pipe(
    ofType(orderTypes.INITIATE_EDIT_ORDER, orderTypes.EXIT_EDIT_ORDER),
    mergeMap((action) => {
      const { reorder } = action as ReturnType<(typeof orderActions)['initiateEditOrder']>;
      history.push({ pathname: '/event' });
      const actions = [];
      if (reorder) {
        actions.push(orderActions.updatePricing());
      }
      return actions;
    }),
  );

export const ClearDeliveryAddress: Epic<
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof orderActions)['clearUserAddressFields']>,
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof orderActions)['clearUserAddressFields']>
> = (action$) =>
  action$.pipe(ofType(userTypes.UPDATE_USER_LOCATION), map(orderActions.clearUserAddressFields));

export const TriggerClear: Epic<
  ReturnType<(typeof orderActions)['dismissError']> | ReturnType<(typeof orderActions)['exitEditOrder']>,
  ReturnType<(typeof orderActions)['dismissError']> | ReturnType<(typeof orderActions)['exitEditOrder']>
> = (action$) =>
  action$.pipe(
    ofType(orderTypes.DISMISS_ERROR),
    filter((action) => {
      const { key } = action as ReturnType<(typeof orderActions)['dismissError']>;
      return key === keys.EDIT_ORDER;
    }),
    map(orderActions.exitEditOrder),
  );

export default [
  GoToCart,
  GoToSubmitPage,
  UpdatePricing,
  SubmitOrder,
  GetOrderDates,
  AutocompleteAddress,
  ValidateAddress,
  GoToMenuAndUpdatePricing,
  TriggerClear,
  ValidateDeliveryRange,
  ClearDeliveryAddress,
];
