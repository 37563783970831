import { Button } from '@cfa/react-components';
import { ErrorInfo } from 'react';

import Section from 'src/components/Section';
import SectionHeader from 'src/components/SectionHeader';

interface Props {
  error: Error;
  info: ErrorInfo;
  clearError: () => void;
  location?: Location;
}

const UnrecoverableError: React.FC<Props> = ({ location = window.location }) => {
  return (
    <Section>
      <SectionHeader>Oops!</SectionHeader>
      <p>There was an unrecoverable error. Please try again or contact support.</p>
      <Button color="secondary" onClick={() => location.assign(location.origin)}>
        Try Again
      </Button>
    </Section>
  );
};

export default UnrecoverableError;
