import { flatten, map } from 'ramda';

import { Category, ItemGroups, Menu, MenuItem } from '../types/menu';
import { formatItemName } from '../util/format';

const lookUpItemGroup = (itemGroupId: number = 0, itemGroups: ItemGroups) => {
  return itemGroups[itemGroupId]?.items || [];
};

function mapMenuItems(items: MenuItem[], itemGroups: ItemGroups): MenuItem[] {
  return flatten<MenuItem>(
    map<MenuItem, MenuItem[]>((item) => {
      let foundItems = lookUpItemGroup(item.itemGroupId, itemGroups || { items: [] });
      if (item.disabled && item.objectType === 'MODIFIER') {
        return [];
      }
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item?.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
      } else if (item.leadTime) {
        foundItems = foundItems.map((m) => ({
          ...m,
          leadTime: item.leadTime,
        }));
      }

      let menuItems = mapMenuItems(foundItems, itemGroups || {});
      if (
        ((item.itemPrice >= 0.1 ||
          item.objectType === 'ITEM' ||
          item.objectType === 'MODIFIER' ||
          item.objectType === 'MODIFIER_GROUPING') &&
          (item.meal || item.objectType !== 'ITEM_GROUPING')) ||
        item.tag === 'PREMIUM_SIDES' ||
        item.tag === 'DESSERTS_PKGMEALS'
      ) {
        let comboItems: MenuItem[] | undefined;
        let sideItems: MenuItem[] | undefined;
        let dessertItems: MenuItem[] | undefined;
        let selectedSide: MenuItem | undefined;
        let selectedDessert: MenuItem | undefined;
        if (item.meal) {
          comboItems = [...menuItems];

          // TODO this should be resolved from the Menu side
          comboItems.splice(2, 0, comboItems.splice(1, 1)[0]);

          sideItems = [];
          dessertItems = [];

          menuItems = menuItems.reduce((acc, comboItem) => {
            if (comboItem.itemGroupType === 'Side' && comboItem.items && comboItem.items.length) {
              selectedSide = comboItem.items.find((sideItem) => sideItem.default) || comboItem.items[0];
            }

            if (comboItem.itemType === 'DESSERTS_GROUP' && comboItem.items && comboItem.items.length) {
              selectedDessert = comboItem.items[0];
            }

            const comboModifiers = comboItem.items
              ? comboItem.items
                  .map((comboModifier) => ({
                    ...comboModifier,
                    comboTag: comboItem.tag,
                  }))
                  .filter((comboMod) => {
                    if (comboMod.comboTag === 'PREMIUM_SIDES') {
                      sideItems?.push(comboMod);
                      return false;
                    }
                    if (comboMod.comboTag === 'DESSERTS_PKGMEALS') {
                      dessertItems?.push(comboMod);
                      return false;
                    }
                    return true;
                  })
              : [];
            return [...acc, ...comboModifiers];
          }, [] as MenuItem[]);
        }
        return [
          {
            ...item, // top level menu item (ex. chicken sandwich, premium box meal)
            name: formatItemName(item.name),
            items: menuItems, // available modifiers (ex. sauces, dressing, multigrain bun)
            selectedSide, // default premium box meal side (ex. superfood side)
            selectedDessert, // default premium box meal dessert (ex. cookie)
            comboItems, // items that make up a combo meal (ex. sandwich, cookie, chips)
            sideItems, // available sides for a premium box meal (ex. superfood side, fruit cup)
            dessertItems, // available desserts for a premium box meal (ex. cookie, brownie)
          },
        ];
      }

      return menuItems;
    })(items || []),
  );
}

const mapMenuCategories = (categories: Category[] = [], itemGroups: ItemGroups) =>
  map<Category, Category & { items: MenuItem[] }>((cat) => ({
    ...cat,
    items: mapMenuItems(cat.items, itemGroups),
  }))(categories);

export const getCombinedMenu = (method = '', menu: { [method: string]: Menu } = {}) => {
  const selectedMenu = menu[method] || {};
  return mapMenuCategories(selectedMenu.categories, selectedMenu.itemGroups);
};
