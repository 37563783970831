import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { ascend, forEachObjIndexed, path, pathOr, prop, sort, values } from 'ramda';
import ooeConstants from 'src/constants';
import { State } from 'src/reducers/dashboard';
import { notifyBugsnag } from 'src/services/bugsnag';
import { DashboardOrder } from 'src/types/dashboard';
import { dashboardErrorMessages } from 'src/util/customerErrorMessages';
import { formatShortDate } from 'src/util/format';
import { formatDisplayFields, mapOrderDetails } from './utils';

// Selectors
export const selectDashboard = (state: { dashboard: State }) => state.dashboard;

// Dashboard Table selectors
export const selectOrders = createSelector(selectDashboard, (dashboard) =>
  values(dashboard.orders).map((order) => formatDisplayFields(order)),
);

// Detail View Selectors
export const selectOrderToView = createSelector(selectDashboard, (dashboard) => {
  if (dashboard.orders[dashboard.orderToViewId]) {
    return mapOrderDetails(dashboard.orders[dashboard.orderToViewId]);
  }

  return {} as ReturnType<typeof mapOrderDetails>;
});
export const selectIsPOSOrder = createSelector(
  selectOrderToView,
  (order) => pathOr('', ['clientId'], order) === 'POS',
);
export const selectIsErrorStatus = createSelector(
  selectOrderToView,
  (order) => order.status === ooeConstants.ERROR,
);

// Detail View action selectors
export const selectPaymentEmailResent = createSelector(selectDashboard, (dashboard) => dashboard.emailSent);

// Order history selectors
export const selectOrderHistory = createSelector(selectOrderToView, (order) => {
  const orderHistory: Array<{ timestamp: string; event: string; sortableValue: number }> = [];
  let timestamps: DashboardOrder['timestamps'] = pathOr({}, ['timestamps'], order);
  if (order.fullError) {
    const errorTimestamp = { error: path(['fullError', 'createdAt'], order) };
    timestamps = { ...timestamps, ...errorTimestamp };
  }

  const buildOrderHistory = (value: string, key: keyof ReturnType<typeof ooeConstants.GET_EVENT_MESSAGE>) =>
    orderHistory.push({
      timestamp: formatShortDate(value),
      event: ooeConstants.GET_EVENT_MESSAGE(order)[key],
      sortableValue: parseISO(value).valueOf(),
    });

  try {
    forEachObjIndexed(buildOrderHistory, timestamps);
  } catch (error) {
    notifyBugsnag('selectOrderHistory', {
      context: 'buildOrderHistory Failed',
      info: {
        error,
        timestamps,
      },
    });
    return [
      {
        timestamp: 'Unknown',
        event: 'Unknown',
        sortableValue: 0,
      },
    ];
  }
  const byTime = ascend(prop('sortableValue'));
  return sort(byTime, orderHistory);
});

export const selectLastEndDate = createSelector(selectDashboard, (dashboard) => dashboard?.endDate);

// Loading selectors
export const selectOrdersAreLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.orders,
);
export const selectLookupOrderIsLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.orderDetails || dashboard.loading.customerDetails,
);
export const selectDashboardActionLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.cancelOrder || dashboard.loading.resendEmail,
);

export const selectDashboardPastOrderLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.loading?.loadMorePastOrders,
);

// Error selectors
export const selectDashboardErrors = createSelector(selectDashboard, (dashboard) => dashboard.error);
export const selectOrdersError = createSelector(selectDashboardErrors, (errors) => errors.orders);
export const selectExcelExtractionError = createSelector(
  selectDashboardErrors,
  (errors) => errors.excelExtraction,
);

export const selectExtractOrdersDataLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.loadingExcelAction,
);

export const selectExtractOrdersData = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.exportExcelOrders,
);

export const selectAllowExcelDownload = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.allowExcelDownload,
);

export const selectExportEntireDaysExcelError = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.errorOccured && dashboardErrorMessages.GET_EXCEL_DATA_ERROR,
);

export const selectSpecificDays = createSelector(selectDashboard, (dashboard) => ({
  ...dashboard?.specificDays,
}));
