import styled from 'styled-components';

import logo from '../Icon/icons/CFA_Symbol.svg';

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <div className={className}>
      <img alt="Chick-fil-A Logo" src={logo} />
      <div className="title">
        Catering
        <br />
        Management
        <br />
        Tool
      </div>
    </div>
  );
};

const StyledLogo = styled(Logo)`
  display: flex;
  flex-direction: row;
  margin: 0 20px;

  & .title {
    font: ${(props) => props.theme.smallTextFont};
    text-align: left;
    color: ${(props) => props.theme.colors.text};
    margin: 10px;

    @media (max-width: ${(props) => props.theme.medium}) {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.tablet}) {
    margin: 0 10px;
  }
`;

export default StyledLogo;
