import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import svg from './icon-sprite.svg';

interface Props {
  onClick?: () => void;
  height?: string;
  width?: string;
  margin?: string;
  className?: string;
}

const Icon: React.FC<PropsWithChildren<Props>> = ({
  children,
  height = '35',
  width = '35',
  margin = '10px',
  onClick = () => {},
  className = '',
}) => {
  const childrenContent = typeof children === 'string' ? children : '';
  const iconText = `this does not exist - ${childrenContent}`;
  return (
    <StyledIcon
      className={[`icon-${childrenContent.toLowerCase().replace(' ', '_')}`, className].join(' ')}
      height={height}
      margin={margin}
      onClick={onClick}
      width={width}
    >
      <use xlinkHref={`${svg}#${childrenContent}`}>{iconText}</use>
    </StyledIcon>
  );
};

const StyledIcon = styled.svg<Props>`
  max-width: ${(props) => `${props.width}px`};
  max-height: ${(props) => `${props.height}px`};
  margin: ${(props) => props.margin};
`;

export default Icon;
