import { filter, pluck, prop } from 'ramda';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';

import { selectIndexedSellableItems, State } from '../reducers';
import { types as menuTypes } from '../reducers/menu';
import { actions as orderActions, types as orderTypes } from '../reducers/order';
import { actions as userActions, types as userTypes } from '../reducers/user';
import { actions as cartActions } from './reducer';
import { selectCart } from './selectors';

export const RemoveNonExistentCartItems: Epic<
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof cartActions)['removeNonExistentItems']>,
  | ReturnType<(typeof orderActions)['changeDestination']>
  | ReturnType<(typeof userActions)['updateUserLocation']>
  | ReturnType<(typeof cartActions)['removeNonExistentItems']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(orderTypes.CHANGE_DESTINATION, userTypes.UPDATE_USER_LOCATION),
    switchMap((action) => {
      if (action.type === userTypes.UPDATE_USER_LOCATION) {
        return action$.pipe(
          ofType(menuTypes.MENU_SUCCESS),
          take(1),
          switchMap(() => action$.pipe(ofType(menuTypes.MENU_SUCCESS))),
          mapTo(action),
        );
      }
      return of(action);
    }),
    map(() => {
      const state = store.value;
      const cart = selectCart(state);
      const menu = selectIndexedSellableItems(state);
      return filter(({ tag }) => prop(tag, menu) === undefined, cart);
    }),
    map(pluck('tag')),
    map(cartActions.removeNonExistentItems),
  );

export default [RemoveNonExistentCartItems];
