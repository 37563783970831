import { notifyBugsnag } from '../services/bugsnag';
import { statusCodes } from './customerErrorMessages';

export class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class NeedsRefreshError extends ExtendableError {
  needsRefresh;
  constructor(message: string) {
    super(message); // (1)
    this.name = 'NeedsRefreshError';
    this.needsRefresh = true;
  }
}

export class MappedError extends ExtendableError {
  constructor(message: string, type: string) {
    super(message); // (1)
    this.name = type;
  }
}

export default function handleErrors(
  response: Response,
  bugsnagData: JSONObject & { errorClass: string } = {
    breadcrumb: 'API Call Error',
    errorClass: response.url,
    context: 'API Call Error',
    info: '',
  },
) {
  if (!response.ok) {
    try {
      notifyBugsnag(bugsnagData.errorClass, {
        response,
        context: bugsnagData.context,
        breadcrumb: bugsnagData.breadcrumb,
        info: bugsnagData.info,
      });

      if (!isJsonResponse(response)) {
        return Promise.resolve({});
      }

      return response.json().then((e) => {
        let errString;
        let customerErr;
        let errorCode;
        const statusText = response.statusText || e.error;
        if (e.message || e.statusMessage || e.statusCode) {
          customerErr = e.statusMessage;
          errString = `${response.status} - ${statusText} (${e.message})`;
          errorCode = e.statusCode;
        } else {
          errString = `${response.status} - ${statusText}`;
        }
        console.error(errString);
        if (response.status === 401) {
          throw new NeedsRefreshError(customerErr);
        }
        if (statusCodes[errorCode as keyof typeof statusCodes]) {
          customerErr = statusCodes[errorCode as keyof typeof statusCodes];
          const { message, type } = customerErr;
          throw new MappedError(message, type);
        }
        throw new Error(customerErr);
      });
    } catch (e: any) {
      notifyBugsnag(e, {
        response,
        context: `API Call Error Parsing JSON - ${bugsnagData.context}`,
        breadcrumb: bugsnagData.breadcrumb,
        info: bugsnagData.info,
      });

      return Promise.resolve({});
    }
  }

  return response.text().then((text) => {
    if (text.length) {
      try {
        const parsedText = JSON.parse(text);
        return parsedText;
      } catch (e: any) {
        notifyBugsnag(e, {
          response,
          context: `API Call Error parsing JSON text - ${bugsnagData.context}`,
          breadcrumb: bugsnagData.breadcrumb,
          info: bugsnagData.info,
        });
        console.info(text);
        throw e;
      }
    }
    return {};
  });
}

export function handleFetchFail(response: Response) {
  return new Promise(() => {
    const { message, type } = statusCodes[1301];

    console.error('handleFetchFail hit , response=', response);
    console.info('message=', message);
    console.info('type=', type);

    notifyBugsnag('Fetch Failure', {
      message,
      type,
      fetchFailResponse: response,
    });

    throw new MappedError(message, type);
  });
}

function isJsonResponse(response: Response) {
  return response.headers.has('content-type') && response.headers.get('content-type') === 'application/json';
}
