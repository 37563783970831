import { Input } from '@cfacorp/cowponents';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';
import { Category, MenuItem as MenuItemType } from '../types/menu';
import MenuItem from './components/MenuItem';
import PaperGoods from './components/PaperGoods';
import { selectMenuWithMethods } from './selectors';

const Menu: React.FC = () => {
  const categories = useSelector(selectMenuWithMethods);

  const [filteredText, setFilteredText] = useState('');

  const filterItemsByText = (item: MenuItemType) =>
    item.name && item.name.toLowerCase().includes(filteredText.toLowerCase());

  const filterCategoriesByText = (cat: Category) => cat.items.filter(filterItemsByText).length;

  const onFilterChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilteredText(e.target.value);
  };

  return (
    <StyledMenu>
      <Section>
        <SectionHeader>Paper Goods?</SectionHeader>
        <PaperGoods />
      </Section>
      <FilterInput
        autoFocus
        className="filter-input"
        onChange={onFilterChange}
        placeholder="Filter by…"
        type="search"
        value={filteredText}
      />
      {categories.filter(filterCategoriesByText).map((cat) => (
        <Section key={cat.tag}>
          <SectionHeader>{cat.name}</SectionHeader>
          <Items data-cy={`${cat.name}-menu-category`}>
            {cat.items.filter(filterItemsByText).map((item) => (
              <MenuItem item={item} key={item.tag} />
            ))}
          </Items>
        </Section>
      ))}
    </StyledMenu>
  );
};

const StyledMenu = styled.div`
  max-width: 1010px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const FilterInput = styled(Input)`
  @media (width >= 500px) {
    position: absolute;
    right: 22px;
  }

  float: right;
  height: 40px;
  width: 150px;
  padding: 7px;
  margin-top: 12px;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export default memo(Menu);
