import { constructNow, isBefore } from 'date-fns';
import { pathOr } from 'ramda';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, ignoreElements, switchMap, tap } from 'rxjs/operators';

import type { IDToken } from '@okta/okta-auth-js';
import {
  addUserToMessageAcknowledgement,
  checkMessagesOnLoad,
  fetchVcaLocations,
  getMessageText,
  getOktaToken,
  getStoreNamesAndNumbersFromApi,
  logoutUser,
  lookupLocationsByNumber,
  refreshOktaToken,
} from '../apis/userApi';
import ooeConstants from '../constants';
import { types as orderTypes, actions as orderActions } from '../reducers/order';
import {
  actions as userActions,
  doesLocationNumberExist,
  isDeveloperAudience,
  selectAccessToken,
  selectAccessTokenExpirationDate,
  selectDefaultLocation,
  selectRefreshToken,
  selectUserLocationNumbers,
  types as userTypes,
} from '../reducers/user';
import { processOktaToken } from '../util/authTokens';
import history from '../util/history';
import epicHelper, { epicOptions } from '../util/myEpicHelper';
import type { State } from '../reducers';

export const GetTokenFromStorage: Epic<
  | ReturnType<(typeof userActions)['getTokenFromStorage']>
  | ReturnType<(typeof userActions)['getTokenFromStorageSuccess']>
  | ReturnType<(typeof userActions)['getOktaToken']>,
  | ReturnType<(typeof userActions)['getTokenFromStorage']>
  | ReturnType<(typeof userActions)['getTokenFromStorageSuccess']>
  | ReturnType<(typeof userActions)['getOktaToken']>
> = (action$) =>
  action$.pipe(
    ofType(userTypes.GET_TOKEN_FROM_STORAGE),
    switchMap(() => {
      const stringifiedToken = localStorage.getItem(ooeConstants.LOCAL_STORAGE_CFA_KEY);
      const token = stringifiedToken ? JSON.parse(stringifiedToken) : null;
      return token ? of(userActions.getTokenFromStorageSuccess(token)) : of(userActions.getOktaToken());
    }),
  );

export const GetTokenFromStorageSuccess: Epic<
  | ReturnType<(typeof userActions)['getTokenFromStorageSuccess']>
  | ReturnType<(typeof userActions)['refreshOktaToken']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>,
  | ReturnType<(typeof userActions)['getTokenFromStorageSuccess']>
  | ReturnType<(typeof userActions)['refreshOktaToken']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.GET_TOKEN_FROM_STORAGE_SUCCESS),
    switchMap(() => {
      const exp = selectAccessTokenExpirationDate(store.value);
      const isExpired =
        typeof exp === 'number' ? isBefore(new Date(exp), constructNow(new Date())) : undefined;

      if (isExpired) {
        return of(userActions.refreshOktaToken());
      }
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
      return of(userActions.processOktaTokenSuccess(store.value.user));
    }),
  );

export const AuthUser: Epic<
  | ReturnType<(typeof userActions)['refreshTokenFailure']>
  | ReturnType<(typeof userActions)['getOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['getOktaToken']>
  | ReturnType<(typeof userActions)['getOktaTokenFailure']>,
  | ReturnType<(typeof userActions)['refreshTokenFailure']>
  | ReturnType<(typeof userActions)['getOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['getOktaToken']>
  | ReturnType<(typeof userActions)['getOktaTokenFailure']>
> = (action$) =>
  action$.pipe(
    ofType(userTypes.GET_OKTA_TOKEN, userTypes.REFRESH_TOKEN_FAILURE),
    switchMap(async () => {
      try {
        const token = await getOktaToken();
        return userActions.getOktaTokenSuccess(token as IDToken);
      } catch (e) {
        const error = e as Error;
        return userActions.getOktaTokenFailure(error);
      }
    }),
  );

export const ProcessOktaToken: Epic<
  | ReturnType<(typeof userActions)['getOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenFailure']>,
  | ReturnType<(typeof userActions)['getOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['processOktaTokenFailure']>
> = (action$) =>
  action$.pipe(
    ofType(userTypes.GET_OKTA_TOKEN_SUCCESS),
    switchMap(async () => {
      try {
        const token = await processOktaToken();
        return userActions.processOktaTokenSuccess(token);
      } catch (e) {
        const error = e as Error;
        return userActions.processOktaTokenFailure(error);
      }
    }),
  );

export const RefreshToken: Epic<
  | ReturnType<(typeof userActions)['refreshOktaToken']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenFailure']>,
  | ReturnType<(typeof userActions)['refreshOktaToken']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.REFRESH_TOKEN),
    switchMap(async () => {
      const state = store.value;
      const refreshTokenToUse = selectRefreshToken(state);
      try {
        const token = await refreshOktaToken(refreshTokenToUse);
        return userActions.refreshTokenSuccess(token);
      } catch (e) {
        const error = e as Error;
        return userActions.refreshTokenFailure(error);
      }
    }),
  );

export const CheckMessagesOnLoad: Epic<
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccessStorage']>
  | ReturnType<(typeof userActions)['messageAcknowledgedSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>,
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccessStorage']>
  | ReturnType<(typeof userActions)['messageAcknowledgedSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.PROCESS_OKTA_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    filter(() => !window.Cypress),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      if (localStorage.getItem(ooeConstants.EULA)) {
        return of(userActions.addUserToAcknowledgementSuccessStorage(ooeConstants.EULA));
      }
      return epicHelper(
        checkMessagesOnLoad(accessToken, ooeConstants.EULA),
        userActions.messageAcknowledgedSuccess(ooeConstants.EULA),
        userActions.messageAcknowledgedFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const GetMessageText: Epic<
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>
  | ReturnType<(typeof userActions)['messageTextSuccess']>
  | ReturnType<(typeof userActions)['messageTextFailure']>,
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>
  | ReturnType<(typeof userActions)['messageTextSuccess']>
  | ReturnType<(typeof userActions)['messageTextFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.MESSAGE_ACKNOWLEDGED_FAILURE),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getMessageText(accessToken, ooeConstants.EULA),
        userActions.messageTextSuccess(ooeConstants.EULA),
        userActions.messageTextFailure(ooeConstants.EULA),
        epicOptions(store, action$),
      );
    }),
  );

export const AddUserToMessageAcknowledgement: Epic<
  | ReturnType<(typeof userActions)['addUserToAcknowledgement']>
  | ReturnType<(typeof orderActions)['dismissError']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>,
  | ReturnType<(typeof userActions)['addUserToAcknowledgement']>
  | ReturnType<(typeof orderActions)['dismissError']>
  | ReturnType<(typeof userActions)['addUserToAcknowledgementSuccess']>
  | ReturnType<(typeof userActions)['messageAcknowledgedFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.ADD_USER_TO_ACKNOWLEDGEMENT, orderTypes.DISMISS_ERROR),
    filter(({ key }) => key === ooeConstants.EULA),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        addUserToMessageAcknowledgement(accessToken, ooeConstants.EULA),
        () => userActions.addUserToAcknowledgementSuccess(ooeConstants.EULA),
        (error) => userActions.messageAcknowledgedFailure(ooeConstants.EULA, error),
        epicOptions(store, action$),
      );
    }),
  );

export const ShowHardcodedMessageAcknowledgement: Epic<
  | ReturnType<(typeof userActions)['messageTextFailure']>
  | ReturnType<(typeof userActions)['showHardcodedEulaText']>,
  | ReturnType<(typeof userActions)['messageTextFailure']>
  | ReturnType<(typeof userActions)['showHardcodedEulaText']>
> = (action$) =>
  action$.pipe(
    ofType(userTypes.MESSAGE_TEXT_FAILURE),
    filter(() => {
      const cmtEula = localStorage.getItem(ooeConstants.EULA);
      return !cmtEula;
    }),
    switchMap(() => of(userActions.showHardcodedEulaText())),
  );

export const LookupSingleLocation: Epic<
  | ReturnType<(typeof userActions)['lookupLocation']>
  | ReturnType<(typeof userActions)['lookupLocationSuccess']>
  | ReturnType<(typeof userActions)['lookupLocationFailure']>,
  | ReturnType<(typeof userActions)['lookupLocation']>
  | ReturnType<(typeof userActions)['lookupLocationSuccess']>
  | ReturnType<(typeof userActions)['lookupLocationFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.LOOKUP_LOCATION),
    filter((action) => {
      const { location } = action as ReturnType<(typeof userActions)['lookupLocation']>;
      const state = store.value;
      return !doesLocationNumberExist(state, location);
    }),
    switchMap((action) => {
      const { location } = action as ReturnType<(typeof userActions)['lookupLocation']>;
      const locations = [location];
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locations, accessToken),
        userActions.lookupLocationSuccess,
        userActions.lookupLocationFailure,
        epicOptions(store, action$),
      );
    }),
  );

export const LookupUserLocations: Epic<
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['getUserLocationsFailure']>
  | ReturnType<(typeof userActions)['throwFullScreenError']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>,
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['getUserLocationsFailure']>
  | ReturnType<(typeof userActions)['throwFullScreenError']>
  | ReturnType<(typeof userActions)['getUserLocationsSuccess']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.PROCESS_OKTA_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    switchMap((action) => {
      const state = store.value;
      const CMT = pathOr({}, ['user', 'cfa_perms', 'CMT'], action);
      const { user } = action as
        | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
        | ReturnType<(typeof userActions)['refreshTokenSuccess']>;
      const cfaAud = user.cfa_aud;
      let locationNumbers = selectUserLocationNumbers(state) || [];

      const { ADMIN, LOGIN, VCA, VCA_BYPASS } = CMT;

      if ((ADMIN || VCA || VCA_BYPASS) && locationNumbers && locationNumbers[0] === '00000') {
        return fetchVcaLocations().pipe(
          catchError((err) => of(userActions.getUserLocationsFailure(err))),
          switchMap((locations) => {
            const storedLoc = selectDefaultLocation(state);
            if (!Array.isArray(locations)) {
              return of(userActions.throwFullScreenError());
            }
            if (storedLoc) {
              locations.unshift(storedLoc);
              locations = [...new Set(locations)];
            }

            const accessToken = selectAccessToken(state);
            return epicHelper(
              lookupLocationsByNumber(locations, accessToken),
              userActions.getUserLocationsSuccess,
              userActions.getUserLocationsFailure,
              epicOptions(store, action$),
            );
          }),
        );
      }

      if (LOGIN && locationNumbers && locationNumbers[0] === '00000') {
        if (isDeveloperAudience(cfaAud)) {
          locationNumbers = ooeConstants.DEFAULT_STORES; // let devs with LOGIN permission access multiple labs :)
        } else {
          locationNumbers = [ooeConstants.PROD_PILOT_LAB];
        }
      }

      const accessToken = selectAccessToken(state);
      return epicHelper(
        lookupLocationsByNumber(locationNumbers, accessToken),
        userActions.getUserLocationsSuccess,
        userActions.throwFullScreenError,
        epicOptions(store, action$),
      );
    }),
  );

export const SetBBR: Epic<
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['setBBRForVCA']>,
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['setBBRForVCA']>
> = (action$) =>
  action$.pipe(
    ofType(userTypes.PROCESS_OKTA_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    switchMap((action) => {
      const CMT = pathOr([], ['user', 'cfa_perms', 'CMT'], action);
      const permissions = Object.keys(CMT);
      const isVca = [ooeConstants.PERMISSIONS.VCA, ooeConstants.PERMISSIONS.VCA_BYPASS].some((item) =>
        permissions.includes(item),
      );
      return of(userActions.setBBRForVCA(isVca));
    }),
  );

export const LogoutUser: Epic<ReturnType<(typeof userActions)['logoutUser']>> = (action$) =>
  action$.pipe(
    ofType(userTypes.LOGOUT_USER),
    tap(() => {
      logoutUser();
    }),
    ignoreElements(),
  );

export const fullScreenError: Epic<ReturnType<(typeof userActions)['throwFullScreenError']>> = (action$) =>
  action$.pipe(
    ofType(userTypes.FULL_SCREEN_ERROR),
    tap(() => history.push({ pathname: '/error' })),
    ignoreElements(),
  );

export const GetStoreNumbersAndNames: Epic<
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['getStoreNumbersAndNamesSuccess']>
  | ReturnType<(typeof userActions)['getStoreNumbersAndNamesFailure']>,
  | ReturnType<(typeof userActions)['processOktaTokenSuccess']>
  | ReturnType<(typeof userActions)['refreshTokenSuccess']>
  | ReturnType<(typeof userActions)['getStoreNumbersAndNamesSuccess']>
  | ReturnType<(typeof userActions)['getStoreNumbersAndNamesFailure']>,
  State
> = (action$, store) =>
  action$.pipe(
    ofType(userTypes.PROCESS_OKTA_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    switchMap(() => {
      const state = store.value;
      const accessToken = selectAccessToken(state);
      return epicHelper(
        getStoreNamesAndNumbersFromApi(accessToken),
        userActions.getStoreNumbersAndNamesSuccess,
        userActions.getStoreNumbersAndNamesFailure,
        epicOptions(store, action$),
      );
    }),
  );

export default [
  AddUserToMessageAcknowledgement,
  AuthUser,
  CheckMessagesOnLoad,
  ProcessOktaToken,
  fullScreenError,
  GetMessageText,
  GetTokenFromStorage,
  GetTokenFromStorageSuccess,
  LogoutUser,
  LookupSingleLocation,
  LookupUserLocations,
  RefreshToken,
  SetBBR,
  GetStoreNumbersAndNames,
  ShowHardcodedMessageAcknowledgement,
];
