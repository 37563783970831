import 'react-datepicker/dist/react-datepicker.css';

import { ToggleButton, ToggleButtonGroup } from '@cfa/react-components';
import { constructNow } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ExportExcelEntireDays from 'src/components/ExportExcel/ExportExcelEntireDays';
import Icon from 'src/components/Icon';
import Section from 'src/components/Section';
import SectionHeader from 'src/components/SectionHeader';
import { selectOrders, selectSpecificDays } from 'src/dashboard/selectors';
import { selectLocationNumber } from 'src/reducers/user';
import { capitalizeFirstLetter } from 'src/util/format';
import { isSmallScreen } from 'src/util/utils';
import { actions as dashboardActions } from '../../../reducers/dashboard';

export const types = ['upcoming', 'past'];

interface DashboardHeaderProps {
  onCalendarOrdersType: (type: string) => void;
  onSwitchTabs: (value: string) => void;
  exportExcelOrdersLoading?: boolean;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  onCalendarOrdersType,
  onSwitchTabs,
  exportExcelOrdersLoading = false,
}) => {
  const dispatch = useDispatch();

  const orders = useSelector(selectOrders);
  const specificDays = useSelector(selectSpecificDays);
  const selectedLocation = useSelector(selectLocationNumber);

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [ordersType, setOrdersType] = useState('upcoming');
  const [disableSegmentButtons, setDisableSegmentButtons] = useState(false);
  const [datePickerIsShown, setDatePickerIsShown] = useState(false);

  const orientation = window.matchMedia('(max-width: 600px)').matches ? 'vertical' : 'horizontal';
  const excelIcon = datePickerIsShown ? 'download-excel' : 'download-excel-disabled';

  const handleDatesChange = (event: { startDate: Nullable<Date>; endDate: Nullable<Date> }) => {
    setStartDate(event.startDate ?? undefined);
    setEndDate(event.endDate ?? undefined);

    if (event.startDate && event.endDate) {
      const start = event.startDate;
      const end = event.endDate;
      const currentDate = constructNow(new Date());

      dispatch(dashboardActions.getOrdersForSpecificDays(start, end));
      setDatePickerIsShown(true);

      if (start >= currentDate) {
        setOrdersType('upcoming');
        onCalendarOrdersType('upcoming');
        setDisableSegmentButtons(true);
      } else if (end < currentDate) {
        setOrdersType('past');
        onCalendarOrdersType('past');
        setDisableSegmentButtons(true);
      } else {
        setDisableSegmentButtons(false);
      }
    }
  };

  const onRefreshHandler = () => {
    dispatch(dashboardActions.getOrders());
    setDisableSegmentButtons(false);
    setStartDate(undefined);
    setEndDate(undefined);
    setDatePickerIsShown(false);
  };

  const onChangeHandler = (_: React.MouseEvent<HTMLButtonElement>, value: string | null) => {
    if (value === null) {
      return;
    }

    setOrdersType(value.toLowerCase());
    onSwitchTabs(value);
  };

  const onCancelDate = () => {
    onRefreshHandler();
    onCalendarOrdersType('upcoming');
    setOrdersType('upcoming');
  };

  // map through orders and call reducer action for getting data for excel extraction. this can't be run if date range is not chosen.
  const onOrdersExtractionHandler = () =>
    orders.map((order) => dispatch(dashboardActions.getDataForExcelExtraction(order)));

  useEffect(() => {
    const { startDate: specificStartDate, endDate: specificEndDate } = specificDays;
    if (specificStartDate) {
      setStartDate(specificStartDate);
    }
    if (specificEndDate) {
      setEndDate(specificEndDate);
    }
  }, [specificDays]);

  useEffect(() => {
    if (ordersType === 'past') {
      onCancelDate();
    }
  }, [selectedLocation]);

  return (
    <StyledSection padding={isSmallScreen() ? '0 0 5rem 0' : '0.5rem'}>
      <SectionHeader>
        <span data-testid="title">Orders</span>
        <DownloadExcelButton
          disabled={!datePickerIsShown}
          onClick={onOrdersExtractionHandler}
          style={exportExcelOrdersLoading ? { transform: 'rotate(-360deg)', transition: '0.5s' } : {}}
          title={
            datePickerIsShown ? 'Extract to Excel' : 'Select a date range to Exctract order data to Excel'
          }
        >
          <Icon className="excel-icon" height="30" margin="0" width="30">
            {exportExcelOrdersLoading ? 'loading' : excelIcon}
          </Icon>
        </DownloadExcelButton>
        <ExportExcelEntireDays dashboardOrders={orders} specificDays={specificDays} />
        <ReloadIconButton onClick={onRefreshHandler} title="Refresh orders">
          <Icon height="30" margin="0" width="30">
            refresh
          </Icon>
        </ReloadIconButton>
      </SectionHeader>
      <StyledControlBox>
        <StyledSegmentGroup
          className="order-type-buttons"
          onChange={onChangeHandler}
          size="sm"
          value={ordersType}
        >
          {types.map((type) => (
            <StyledSegmentButton disabled={disableSegmentButtons} key={type} value={type}>
              {capitalizeFirstLetter(type)}
            </StyledSegmentButton>
          ))}
        </StyledSegmentGroup>
        <StyledDatePicker className={orientation}>
          <ReactDatePicker
            autoComplete="off"
            dropdownMode="select"
            endDate={endDate}
            inline={false}
            monthsShown={2}
            name="Select Date"
            onChange={(dates) => handleDatesChange({ startDate: dates[0], endDate: dates[1] })}
            placeholderText="Select Date Range"
            selectsRange
            startDate={startDate}
          />
          {datePickerIsShown && (
            <Close className="button-clear" onClick={onCancelDate}>
              <Icon>close</Icon>
            </Close>
          )}
        </StyledDatePicker>
      </StyledControlBox>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
`;

export const UnstyledButton = styled('button')`
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

export const ReloadIconButton = styled(UnstyledButton)`
  position: absolute;
  right: 0;
  opacity: 0.6;
  transition: 0.5s all ease-in-out;
  transform-origin: 12px 12px;
  z-index: 1;

  &:focus {
    transform: rotate(-360deg);
  }
`;

export const DownloadExcelButton = styled(UnstyledButton)`
  position: absolute;
  right: 53px;
  z-index: 1;
  width: 35px;
  height: 35px;
  transform-origin: 16px 13.5px;
  fill: ${(props) => (props.disabled ? props.theme.colors.outline : props.theme.colors.primary)};

  &:hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const StyledSegmentGroup = styled(ToggleButtonGroup)`
  margin-right: 10px;
`;

const StyledSegmentButton = styled(ToggleButton)`
  width: 115px;
  margin-right: 0;
`;

const StyledControlBox = styled('div')`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translate(-50%, 0);

  @media (width <= 663px) {
    flex-direction: column;
    bottom: 0;
  }
`;

const StyledDatePicker = styled('div')`
  width: 230px;
  height: 38px;
  position: relative;

  /* stylelint-disable selector-class-pattern */
  & .DateRangePickerInput {
    /* stylelint-enable selector-class-pattern */
    display: flex;
    align-items: center;
  }

  & input {
    font: ${(props) => props.theme.regularTextFont};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.outline};
    border-radius: 4px;
    height: 46px;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    appearance: none;
    padding: 3px;
    text-align: center;
    cursor: pointer;

    &:focus {
      border-bottom: none;
    }
  }

  @media (height <= 900px) {
    /* stylelint-disable selector-class-pattern */
    & .DateRangePicker_picker {
      /* stylelint-enable selector-class-pattern */
      background-color: transparent;
    }

    /* stylelint-disable selector-class-pattern */
    & .DayPicker_transitionContainer__vertical {
      /* stylelint-enable selector-class-pattern */
      height: 605px !important;
      margin-bottom: 130px;
    }
  }

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const Close = styled('div')`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 15px;
  height: 15px;

  & svg {
    margin: 0;
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.colors.outline};
  }

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    width: 38px;
    height: 38px;
    right: -38px;
  }
`;

export default DashboardHeader;
