import { Button, LoadingIndicator } from '@cfa/react-components';
import { Input } from '@cfacorp/cowponents';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  actions as guestActions,
  selectCurrentFavoriteNames,
  selectFavoriteActionSuccessful,
  selectFavoriteOrders,
  selectFavoritesError,
  selectFavoritesLoading,
  selectFavoriteSuggestedName,
} from '../../reducers/guest';
import { exactFavoriteMatches } from '../../util/validate';
import Disclaimer from '../Disclaimer';
import Icon from '../Icon';
import OverlayModal from '../OverlayModal';

interface Props {
  orderId: string;
  isOpen: boolean;
  close: () => void;
  guestName?: string;
}

const FavoriteOrder: React.FC<Props> = ({ orderId, guestName, close, isOpen }) => {
  const dispatch = useDispatch();
  const favoriteOrders = useSelector(selectFavoriteOrders);
  const suggestedFavoriteName = useSelector(selectFavoriteSuggestedName);
  const favoriteActionSuccessful = useSelector(selectFavoriteActionSuccessful);
  const currentFavoriteNames = useSelector(selectCurrentFavoriteNames);
  const favoritesLoading = useSelector(selectFavoritesLoading);
  const favoritesError = useSelector(selectFavoritesError);

  const existingFavOrder = favoriteOrders.find((favorite) => favorite.favoriteOrderId === orderId);

  const [favoriteName, setStateFavoriteName] = useState(
    existingFavOrder?.name || suggestedFavoriteName || (guestName ? `${guestName}'s Catering Order` : ''),
  );
  const [favoriteNameInvalid, setFavoriteNameInvalid] = useState(false);

  const closeModal = () => {
    dispatch(guestActions.resetFavoriteActionErrors());
    close();
  };

  useEffect(() => {
    if (favoriteActionSuccessful) {
      setTimeout(closeModal, 1500);
    }
  }, [favoriteActionSuccessful]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStateFavoriteName(e.target.value);
  };

  const checkForMatch: React.KeyboardEventHandler<HTMLInputElement> = () => {
    const matchFound = exactFavoriteMatches(currentFavoriteNames, favoriteName).length;
    if (matchFound) {
      setFavoriteNameInvalid(true);
    } else {
      setFavoriteNameInvalid(false);
    }
  };

  const renderActions = () => {
    if (favoritesLoading) {
      return <LoadingIndicator data-testid="spinner" size="md" variant="inline" />;
    }
    if (favoritesError || favoriteActionSuccessful) {
      return (
        <div className="icon-message">
          <Icon height="20px" margin="5px" width="20px">
            {favoritesError ? 'error' : 'success'}
          </Icon>
          {favoritesError
            ? 'Uh oh! There was a problem performing this action. Please close this window and try again or contact support.'
            : 'Success!'}
        </div>
      );
    }
    return (
      <>
        {favoriteNameInvalid && (
          <Disclaimer className="warning-message">
            <Icon height="15px" margin="0 4px 2px" width="15px">
              warning
            </Icon>
            Each favorite name must be a unique. Please try a different name.
          </Disclaimer>
        )}
        {existingFavOrder ? (
          <div>
            <Button
              className="remove-favorite"
              onClick={() => dispatch(guestActions.removeFromFavorites(orderId))}
              size="md"
              variant="destructive"
            >
              Remove from Favorites
            </Button>
            <Button
              className="update-favorite"
              color="secondary"
              disabled={favoriteNameInvalid}
              onClick={() => dispatch(guestActions.updateFavoriteName(orderId, favoriteName))}
              size="md"
            >
              Update Favorite Name
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className="save-favorite"
              color="secondary"
              disabled={favoriteNameInvalid}
              onClick={() => dispatch(guestActions.addToFavorites(orderId, favoriteName))}
              size="md"
            >
              Save to Favorites
            </Button>
            <Button color="secondary" onClick={closeModal} size="md" variant="text">
              Cancel
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <StyledFavoriteOrder close={closeModal} isOpen={isOpen}>
      <div className="content">
        <Icon className="favorite-icon">favorite</Icon>
        <h2 className="header">{`${existingFavOrder ? 'Update Favorite Order' : 'Add to Favorites'}`}</h2>
        <div className="message">
          {existingFavOrder
            ? "Update the name for this Favorite Order or remove it from Favorites. This change will be visible to the guest under 'View Account' on Chick-fil-A.com."
            : "This order will be saved to Favorite Orders and will be accessible by the guest under 'View Account' on Chick-fil-A.com."}
        </div>
        <Input
          autoFocus
          className="favorite-input"
          data-testid="favorite-input"
          maxLength="80"
          onChange={handleChange}
          onKeyUp={checkForMatch}
          value={favoriteName}
        />
        <div className="button-wrapper">{renderActions()}</div>
        <div className="close-favorite" data-testid="close-modal" onClick={closeModal}>
          <Icon>close</Icon>
        </div>
      </div>
    </StyledFavoriteOrder>
  );
};

export const StyledFavoriteOrder = styled(OverlayModal)`
  text-align: center;

  .content {
    padding: 20px;
  }

  .favorite-icon {
    height: 25px;
    width: 20px;
    fill: ${(props) => props.theme.colors.disabled};
  }

  .header {
    margin-top: 0;
  }

  .message {
    line-height: 25px;
    margin: 20px auto;
    max-width: 60%;

    @media (max-width: ${(props) => props.theme.phone}) {
      max-width: 90%;
    }
  }

  .favorite-input {
    width: 60%;
    margin: 0 auto 20px;

    @media (max-width: ${(props) => props.theme.phone}) {
      width: 90%;
    }
  }

  .button-wrapper {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & button {
      margin: 4px;
    }

    @media (max-width: ${(props) => props.theme.phone}) {
      height: auto;
    }
  }

  .icon-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 55%;
  }

  .warning-message {
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => props.theme.phone}) {
      margin: 10px;
      flex-direction: column;
    }
  }

  .close-favorite {
    position: absolute;
    top: 0;
    right: 5px;

    & svg {
      margin: 0;
      width: 20px;
      fill: ${(props) => props.theme.colors.outline};
    }

    :hover {
      cursor: pointer;
    }
  }
`;

export default FavoriteOrder;
