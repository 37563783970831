import { LoadingIndicator } from '@cfa/react-components';
import { Box, Flex } from '@cfacorp/cowponents';
import styled from 'styled-components';

import Icon from 'src/components/Icon';
import Loyalty from 'src/components/Loyalty/Loyalty';
import React from 'react';
import { selectOrderToView } from '../../selectors';

interface DetailViewHeaderProps {
  orderDetails: ReturnType<typeof selectOrderToView>;
  close: () => void;
  dashboardActionLoading?: boolean;
}

const DetailViewHeader: React.FC<DetailViewHeaderProps> = ({
  dashboardActionLoading,
  orderDetails,
  close,
}) => {
  const { givenName, familyName, displayPrice, displayStatus, displayLongDate, cfaLoyalty, destination } =
    orderDetails;
  const { statusName, statusIcon } = displayStatus || {};
  return (
    <StyledDetailViewHeader>
      <Box id="display-guest-name" width={[1, 1, 1 / 3]}>
        <div className="guest-name">{`${givenName} ${familyName}`}</div>
        {cfaLoyalty && <Loyalty cfaLoyalty={cfaLoyalty} showText />}
      </Box>
      <Box className="header-details" id="display-date-time" width={[1, 1, 1 / 6]}>
        {displayLongDate}
      </Box>
      <Box className="header-details" id="display-destination" width={[1, 1, 1 / 6]}>
        {destination}
      </Box>
      <Box className="header-details" id="display-price" width={[1, 1, 1 / 6]}>
        {displayPrice}
      </Box>
      <Flex alignItems="center" className="detail-status" justifyContent="center" width={1 / 6}>
        {!dashboardActionLoading ? (
          <Icon>{statusIcon}</Icon>
        ) : (
          <LoadingIndicator className="loading" data-testid="spinner" size="sm" variant="inline" />
        )}
        {statusName}
      </Flex>
      <div className="close" onClick={close} role="presentation">
        <Icon>close</Icon>
      </div>
    </StyledDetailViewHeader>
  );
};

const StyledDetailViewHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 13px;
  position: relative;
  text-align: center;
  width: unset;

  & .guest-name {
    font-weight: bold;
    font-size: 25px;
  }

  & .close svg {
    position: absolute;
    fill: ${(props) => props.theme.colors.outline};
    right: -14px;
    top: -20px;
    width: 21px;
    margin: 0;
  }

  & .close:hover {
    cursor: pointer;
  }

  & .detail-status svg,
  .loyalty svg {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
    max-width: 21px;
  }

  & .loading {
    margin-right: 2rem;
  }

  & ::after {
    content: '';
    clear: both;
    display: table;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    flex-direction: column;

    & div {
      width: unset;
    }

    & .guest-name {
      margin: 13px;
    }

    & .header-details {
      margin: 3px;
    }

    & .detail-status,
    .loyalty {
      height: 24px;
      margin-top: -3px;
    }
  }
`;

export default DetailViewHeader;
