import { Select } from '@cfacorp/cowponents';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import ooeConstants from '../../../constants';
import { selectCateringOccasion } from '../../../reducers/form';

const SelectCateringOccasion: React.FC = () => {
  const dispatch = useDispatch();
  const cateringOccasion = useSelector(selectCateringOccasion);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    dispatch(change(ooeConstants.GET_FORM_TYPES.DETAILS, 'cateringReason', e.target.value));
  };

  return (
    <Select
      className="cateringReason"
      label="Catering Occasion"
      name="cateringReason"
      onChange={handleChange}
      placeholder="Catering Occasion (Optional)"
      value={cateringOccasion}
    >
      <option value="Birthday Party">Birthday Party</option>
      <option value="Family or Friends Gathering">Family or Friends Gathering</option>
      <option value="Office Meeting">Office Meeting</option>
      <option value="Company Event">Company Event</option>
      <option value="School Event">School Event</option>
      <option value="None">None</option>
    </Select>
  );
};

export default SelectCateringOccasion;
