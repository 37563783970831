/* eslint-disable react/forbid-prop-types */
import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatItemName, formatPrice } from '../../util/format';
import EmptyCart from '../EmptyCart';
import OrderSummaryModifiers from '../Modifiers/OrderSummaryModifiers';

function OrderSummary({ className, cartItems, showPrices, showModifiers }) {
  return (
    <StyledOrderSummary className={className}>
      {cartItems.length === 0 ? (
        <EmptyCart message="No items in cart" />
      ) : (
        <>
          <Flex>
            <Box fontWeight="bold" mb="5px" width={7 / 12}>
              Item name
            </Box>
            <Box fontWeight="bold" mb="5px" textAlign="center" width={showPrices ? 3.5 / 12 : 5 / 12}>
              Quantity
            </Box>
            {showPrices && (
              <Box fontWeight="bold" mb="5px" textAlign="right" width={1.5 / 12}>
                Price
              </Box>
            )}
          </Flex>
          {cartItems.map((item) => (
            <Box key={item.id || Math.random() * 5} mb="5px">
              <Flex lineHeight="27px">
                <Box data-cy={`${item.tag}-name`} data-testid="item-name" textAlign="left" width={7 / 12}>
                  {formatItemName(item.name)}
                </Box>
                <Box
                  data-cy={`${item.tag}-quantity`}
                  data-testid="item-quantity"
                  textAlign="center"
                  width={3.5 / 12}
                >
                  {item.quantity}
                </Box>
                {showPrices && (
                  <Box
                    data-cy={`${item.tag}-price`}
                    data-testid="item-price"
                    textAlign="right"
                    width={1.5 / 12}
                  >
                    {item.promoFree ? 'Promo Free' : formatPrice(item.price)}
                  </Box>
                )}
              </Flex>
              {showModifiers && (
                <OrderSummaryModifiers
                  data-cy={`${item.tag}-modifiers`}
                  data-testid="summary-moddifiers"
                  dessert={item.selectedDessert}
                  item={item}
                  promoFree={item.promoFree}
                  showPrices={showPrices}
                  side={item.selectedSide}
                />
              )}
            </Box>
          ))}
        </>
      )}
    </StyledOrderSummary>
  );
}

const StyledOrderSummary = styled.div`
  font: ${(props) => props.theme.regularTextFont};

  & .empty-cart {
    margin: 0;
    padding-bottom: 0;
  }
`;

OrderSummary.propTypes = {
  className: PropTypes.string,
  cartItems: PropTypes.arrayOf(PropTypes.object),
  showPrices: PropTypes.bool,
  showModifiers: PropTypes.bool,
};

OrderSummary.defaultProps = {
  className: '',
  cartItems: [],
  showPrices: true,
  showModifiers: true,
};

export default OrderSummary;
